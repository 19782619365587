import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { CONTIGO_API_URL } from '../BaseURL'
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';


const Login = () => {

    const [login, setLogin] = useState({ email: "", password: "", pwdShow: true })
    const [error, setError] = useState({});
    const nav = useNavigate();
    const [disable, setDisable] = useState(false);

    useEffect(() => {

        if (localStorage.getItem('CONTIGO_TOKEN_PATIENT')) {
            toast.success("Already Logged-In")
            setTimeout(() => {
                nav('/search-result')
            }, 1500);
        }
        // eslint-disable-next-line
    }, [])


    const handleLogin = () => {
        if (validation()) {

            setDisable(true)
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('email', login.email);
            bodyFormData.append('password', login.password);

            axios({
                method: 'post',
                url: `${CONTIGO_API_URL}api/patient-user/signin`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded ' }
            }).then((response) => {
                console.log("@@", response.data);
                if (response.data.success === true) {
                    setTimeout(() => {
                        nav('/search-result')
                    }, 1500)
                    toast.success(response.data.message)
                    localStorage.setItem('CONTIGO_TOKEN_PATIENT', response.data.data.token);
                    setDisable(false)
                }
            }).catch((err) => {
                console.log(err);
                setError({ ...error, password_err: err.response.data.message })
                toast.error(err.response.data.message);
                setDisable(false)
            })
        }
    }

    const handleChange = (e) => {
        setLogin({ ...login, [e.target.name]: e.target.value?.trimStart() })
    }



    const validation = () => {
        var isValid = true;
        let err = {};

        if (!login.email) {
            isValid = false;
            err['email_err'] = "Please enter your email"
        }

        if (!login.password) {
            isValid = false;
            err['password_err'] = "Please enter your password"
        }

        setError(err);
        return isValid;
    }


    return (
        <div className='cu-site'>
            <section className="cu-login-section">
                <div className="cu-login-section-content">
                    <div className="row g-0">
                        <div className="col col-12 col-md-12 col-lg-12 col-xl-6">
                            <div className="cu-login-content">
                                <Link className="cu-login-top-logo" to="/">
                                    <img src="assets/images/white-logo.png" alt="Logo" className="img-fluid" />
                                </Link>
                                <div className="cu-login-content-area">
                                    <h2 className="cu-login-content-title">Patients are Waiting for you Login Now</h2>
                                    <div className="cu-login-logo">
                                        <Link to='/'>
                                            <img src="assets/images/login-logo.png" alt="login-logo" className="img-fluid" />
                                        </Link>
                                    </div>
                                    <div className="cu-login-left-img">
                                        <img src="assets/images/login-old-img.png" alt="login-old-img" className="img-fluid" />
                                    </div>
                                    <div className="cu-login-right-img">
                                        <img src="assets/images/login-boy-img.png" alt="login-boy-img" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-12 col-lg-12 col-xl-6">
                            <div className="cu-login-form-area">
                                <div className="cu-login-form-area__inner">
                                    <div className="cu-login-form-heading">
                                        <h1 className="cu-login-title">Login</h1>
                                        <p className="cu-login-description">Sign into your account</p>
                                    </div>
                                    <div className="cu-login-form">
                                        <form className="row">
                                            <div className="col-12 mb-4">
                                                <label htmlFor="inputUsername" className="form-label" >Email</label>
                                                <input type="text" className="form-control" id="inputUsername" name='email' onChange={handleChange} value={login.email} />
                                                <div className='error'>{error.email_err}</div>
                                            </div>
                                            <div className="col-12">
                                                <label htmlFor="inputPassword4" className="form-label">Password</label>
                                                <div className="cu-input-icon-group mb-2">
                                                    <input type={login.pwdShow ? "password" : "text"} className="form-control" id="inputPassword4" name='password' onChange={handleChange} value={login.password} />
                                                    {login.pwdShow ? <i className='fa fa-eye cu-input-icon' onClick={() => { setLogin({ ...login, pwdShow: false }) }} /> : <i className='fa fa-eye-slash cu-input-icon' onClick={() => setLogin({ ...login, pwdShow: true })} />}

                                                    <div className='error'>{error.password_err}</div>
                                                    {/* <span className="cu-input-icon">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path
                                                                d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </svg>
                                                    </span> */}
                                                </div>
                                                <p className="cu-forgot-pass-text mt-2"><a href="/forget-password"
                                                    className="cu-forgot-pass-class">Forgot
                                                    Password?</a></p>
                                            </div>
                                            <div className="col-12">
                                                <button type="button" className="btn btn-info w-100" onClick={handleLogin} disabled={disable}>{disable ? "Processing..." : "Sign in"}</button>
                                                <p className="cu-form-notes">Don’t Have an Account ? <Link to="/register">Register</Link></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Login