import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Headers from "./Header";
import Sidebar from "./Sidebar";
import { toast } from "react-toastify";
import { CONTIGO_API_URL } from "../BaseURL";
import axios from "axios";

const WriteReview = () => {
  const nav = useNavigate();
  const [reviewData, setReviewData] = useState([]);
  useEffect(() => {
    if (localStorage.getItem("CONTIGO_TOKEN_PATIENT") === null) {
      nav("/login");
    }
    const myurl = `${CONTIGO_API_URL}api/patient-user/review-center`;
    axios({
      method: "get",
      url: myurl,

      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Bearer " + localStorage.getItem("CONTIGO_TOKEN_PATIENT"),
      },
    })
      .then(async (response) => {
        if (response?.data?.status) {
          console.log(response?.data?.data)
          setReviewData(response?.data?.data);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        console?.log("Errors", error);
      });
  }, []);

  return (
    <div className="cu-site cu-dashboard-site">
      <Headers />
      <main className="cu-dashboard-content">
        <Sidebar />
        <div className="cu-dashboard-content-area">
          <div className="cu-browse-home-content">
            <div className="row">
              {reviewData &&
                reviewData.map((obj, key) => {
                  return (
                    <div
                      className="col col-12 col-md-6 col-lg-6 col-xl-4"
                      key={key}
                    >
                      <div className="cu-browse-home-card">
                        <div className="cu-browse-home-img">
                          <img
                            src={obj.center_image && obj.center_image[0]}
                            alt="Home"
                            className="img-fluid"
                          />
                          <p className="cu-browse-home-rate">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.7171 18.8917C14.2754 18.8917 13.7088 18.75 13.0004 18.3333L10.5088 16.8583C10.2504 16.7083 9.75042 16.7083 9.50042 16.8583L7.00042 18.3333C5.52542 19.2083 4.65876 18.8583 4.26709 18.575C3.88376 18.2917 3.28376 17.5667 3.67542 15.9L4.26709 13.3417C4.33376 13.075 4.20042 12.6167 4.00042 12.4167L1.93376 10.35C0.900424 9.31667 0.983757 8.43334 1.12542 8.00001C1.26709 7.56667 1.71709 6.80001 3.15042 6.55834L5.80876 6.11667C6.05876 6.07501 6.41709 5.80834 6.52542 5.58334L8.00042 2.64167C8.66709 1.30001 9.54209 1.10001 10.0004 1.10001C10.4588 1.10001 11.3338 1.30001 12.0004 2.64167L13.4671 5.57501C13.5838 5.80001 13.9421 6.06667 14.1921 6.10834L16.8504 6.55001C18.2921 6.79167 18.7421 7.55834 18.8754 7.99167C19.0088 8.42501 19.0921 9.30834 18.0671 10.3417L16.0004 12.4167C15.8004 12.6167 15.6754 13.0667 15.7338 13.3417L16.3254 15.9C16.7088 17.5667 16.1171 18.2917 15.7338 18.575C15.5254 18.725 15.1921 18.8917 14.7171 18.8917ZM10.0004 15.4917C10.4088 15.4917 10.8171 15.5917 11.1421 15.7833L13.6338 17.2583C14.3588 17.6917 14.8171 17.6917 14.9921 17.5667C15.1671 17.4417 15.2921 17 15.1088 16.1833L14.5171 13.625C14.3588 12.9333 14.6171 12.0417 15.1171 11.5333L17.1838 9.46667C17.5921 9.05834 17.7754 8.65834 17.6921 8.38334C17.6004 8.10834 17.2171 7.88334 16.6504 7.79167L13.9921 7.35001C13.3504 7.24167 12.6504 6.72501 12.3588 6.14167L10.8921 3.20834C10.6254 2.67501 10.2921 2.35834 10.0004 2.35834C9.70876 2.35834 9.37542 2.67501 9.11709 3.20834L7.64209 6.14167C7.35042 6.72501 6.65042 7.24167 6.00876 7.35001L3.35876 7.79167C2.79209 7.88334 2.40876 8.10834 2.31709 8.38334C2.22542 8.65834 2.41709 9.06667 2.82542 9.46667L4.89209 11.5333C5.39209 12.0333 5.65042 12.9333 5.49209 13.625L4.90042 16.1833C4.70876 17.0083 4.84209 17.4417 5.01709 17.5667C5.19209 17.6917 5.64209 17.6833 6.37542 17.2583L8.86709 15.7833C9.18376 15.5917 9.59209 15.4917 10.0004 15.4917Z"
                                fill="white"
                              />
                            </svg>
                            <span>{parseFloat(obj.center_overall_rating.toFixed(1))}/5</span>
                          </p>
                        </div>
                        <div className="cu-browse-home-body">
                          <div className="cu-browse-home-heading">
                            <a href="/details" className="cu-browse-home-title">
                              {obj.center_name}
                            </a>
                            <p className="cu-browse-home-time">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_443_5160)">
                                  <path
                                    d="M18.3337 9.99999C18.3337 14.6 14.6003 18.3333 10.0003 18.3333C5.40033 18.3333 1.66699 14.6 1.66699 9.99999C1.66699 5.39999 5.40033 1.66666 10.0003 1.66666C14.6003 1.66666 18.3337 5.39999 18.3337 9.99999Z"
                                    stroke="#00657B"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M13.0914 12.65L10.5081 11.1083C10.0581 10.8417 9.69141 10.2 9.69141 9.675V6.25833"
                                    stroke="#00657B"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_443_5160">
                                    <rect width="20" height="20" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              <span>
                                {obj.timing} to {obj.end_timing}
                              </span>
                            </p>
                          </div>
                          <p
                            className="cu-browse-home-description"
                            style={{ height: "36px", overflow: "hidden" }}
                          >
                            {obj.description}
                          </p>
                          <p className="cu-browse-home-info">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.0004 11.1917C10.3418 11.1917 10.6799 11.1244 10.9954 10.9938C11.3108 10.8631 11.5974 10.6716 11.8389 10.4301C12.0803 10.1887 12.2718 9.90209 12.4025 9.58665C12.5331 9.2712 12.6004 8.93311 12.6004 8.59167C12.6004 8.25023 12.5331 7.91214 12.4025 7.59669C12.2718 7.28125 12.0803 6.99462 11.8389 6.75319C11.5974 6.51176 11.3108 6.32024 10.9954 6.18958C10.6799 6.05892 10.3418 5.99167 10.0004 5.99167C9.31083 5.99167 8.64951 6.2656 8.16191 6.75319C7.67432 7.24079 7.40039 7.90211 7.40039 8.59167C7.40039 9.28123 7.67432 9.94255 8.16191 10.4301C8.64951 10.9177 9.31083 11.1917 10.0004 11.1917Z"
                                stroke="#B2B2B2"
                                strokeWidth="1.5"
                              />
                              <path
                                d="M3.01675 7.07501C4.65842 -0.14166 15.3501 -0.133326 16.9834 7.08334C17.9418 11.3167 15.3084 14.9 13.0001 17.1167C12.1935 17.8946 11.1165 18.3293 9.99592 18.3293C8.87529 18.3293 7.79835 17.8946 6.99175 17.1167C4.69175 14.9 2.05842 11.3083 3.01675 7.07501Z"
                                stroke="#B2B2B2"
                                strokeWidth="1.5"
                              />
                            </svg>
                            <span
                              style={{
                                height: "17px",
                                overflow: "hidden",
                                width: "calc(100% - 25px)",
                              }}
                            >
                              {obj.address.address}
                            </span>
                          </p>
                          <p className="cu-browse-home-info">
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.09961 16.775V5.22501"
                                stroke="#B2B2B2"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M1.09961 14.575H20.8996"
                                stroke="#B2B2B2"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20.9004 14.575V16.775"
                                stroke="#B2B2B2"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20.7623 14.575C20.8382 14.575 20.8998 14.5134 20.8998 14.4375V12.375C20.8998 9.94494 18.9299 7.97501 16.4998 7.97501H9.3498C9.04604 7.97501 8.7998 8.22124 8.7998 8.52501V14.4375C8.7998 14.5134 8.86138 14.575 8.9373 14.575"
                                stroke="#B2B2B2"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M4.06591 9.62501C4.6554 9.28467 5.38167 9.28467 5.97116 9.62501C6.56065 9.96535 6.92379 10.5943 6.92379 11.275C6.92379 11.9557 6.56065 12.5847 5.97116 12.925C5.38167 13.2653 4.6554 13.2653 4.06591 12.925C3.47642 12.5847 3.11328 11.9557 3.11328 11.275C3.11328 10.5943 3.47642 9.96535 4.06591 9.62501Z"
                                stroke="#B2B2B2"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <span>{obj.No_of_bed}</span>
                          </p>
                        </div>
                        <div className="cu-browse-home-footer">
                          <a
                            // href="/review"
                            // href="javascript:void(0)"
                            style={!obj.rating_user ? { cursor: 'pointer' } : { background: '#ff7800', cursor: 'pointer' }}
                            onClick={() => {
                              nav("/review", { state: obj });
                            }}
                            className="cu-browse-home-detail-link"
                          >
                            {!obj.rating_user ? 'Write a Review' : 'Read a Review'}
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default WriteReview;
