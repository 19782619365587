import React, { useState, useEffect } from "react";
import axios from "axios";
import { CONTIGO_API_URL, CONTIGO_WEB_URL } from "../BaseURL";
import { toast, Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const [profile, setProfile] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('CONTIGO_TOKEN_PATIENT')) {
      getProfile();
    }
  }, []);

  const getProfile = () => {
    axios({
      method: "get",
      url: `${CONTIGO_API_URL}api/patient-user/get-profile`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded ",
        Authorization: `Bearer ${localStorage.getItem(
          "CONTIGO_TOKEN_PATIENT"
        )}`,
      },
    })
      .then((response) => {
        // console.log("@@", response.data);
        if (response?.data?.success === true) {
          setProfile(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          toast.error(err?.response?.data?.message);
          localStorage.removeItem("CONTIGO_TOKEN_PATIENT");
          navigate("/login");
        }
      });
  };

  const handleBack = () => {
    navigate("/search-result");
    localStorage.removeItem("LOCATION_ID");
  };

  return (
    <>
      {/* <div className="cu-site cu-dashboard-site"> */}
      <header className="dashboard-header">
        <div className="dashboard-header-logo">
          <a
            className="navbar-brand"
            onClick={handleBack}
            style={{ cursor: "pointer" }}
          >
            <img
              src={`/assets/images/logo.png`}
              alt="Logo"
              className="img-fluid"
            />
          </a>
        </div>
        {localStorage.getItem('CONTIGO_TOKEN_PATIENT') ? <>
          <h2 className="dashboard-title">
            Hello,
            <span>
              {" "}
              {profile.first_name} {profile.last_name}
            </span>
          </h2>
          <div className="dashboard-right-menu">
            {/* <div className="cu-input-icon-group">
                        <input type="text" className="form-control" placeholder="Search via facility" />
                        <button type="button" className="cu-input-icon btn-icon">
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4"
                                    d="M9.82962 17.5295C11.821 17.5295 13.7307 16.7384 15.1388 15.3303C16.5469 13.9223 17.338 12.0125 17.338 10.0212C17.338 8.02982 16.5469 6.12004 15.1388 4.71196C13.7307 3.30387 11.821 2.51282 9.82962 2.51282C7.83829 2.51282 5.92851 3.30387 4.52043 4.71196C3.11234 6.12004 2.32129 8.02982 2.32129 10.0212C2.32129 12.0125 3.11234 13.9223 4.52043 15.3303C5.92851 16.7384 7.83829 17.5295 9.82962 17.5295Z"
                                    fill="#78797A" />
                                <path
                                    d="M18.1646 16.6378C17.8896 16.1295 17.3062 15.8461 16.5229 15.8461C15.9312 15.8461 15.4229 16.0878 15.1229 16.5045C14.8229 16.9211 14.7562 17.4795 14.9396 18.0378C15.2979 19.1211 15.9229 19.3628 16.2646 19.4045C16.3146 19.4128 16.3646 19.4128 16.4229 19.4128C16.7896 19.4128 17.3562 19.2545 17.9062 18.4295C18.3479 17.7878 18.4312 17.1461 18.1646 16.6378Z"
                                    fill="#78797A" />
                            </svg>
                        </button>
                    </div> */}
            <div className="cu-header-profile">
              {/* <span className="cu-header-profile-name">{profile.first_name} {profile.last_name}</span> */}
              {/* <img src="assets/images/profile-img.png" alt="profile-img" className="img-fluid" /> */}
              <span
                style={{
                  backgroundColor: "#F6F7F9",
                  color: "black",
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  fontSize: "20px",
                  textAlign: "center",
                  padding: "8px",
                }}
              >
                {profile.first_name?.charAt(0).toUpperCase()}
                {profile.last_name?.charAt(0).toUpperCase()}
              </span>
            </div>
            <button className="sidebar-toggler btn-icon">
              <span className="sidebar-toggler-icon"></span>
              <span className="sidebar-toggler-icon"></span>
              <span className="sidebar-toggler-icon"></span>
            </button>
          </div>
        </> :
          <div className="cu-header-btn">
            <Link to="/login" className="btn btn-info">Login</Link>
            <Link to="/register" className="btn btn-primary">Sign Up</Link>
          </div>}
      </header>
      {/* </div> */}
    </>
  );
};

export default Header;
