// import './App.css';
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Details from "./components/Details";
import DetailsFacility from "./components/DetailsFacility";
import DetailsServices from "./components/DetailsServices";
import DetailsTherapy from "./components/DetailsTherapy";
import ForgetPassword from "./components/ForgetPassword";
import Index from "./components/Index"
import InquiryForm from "./components/InquiryForm";
import Login from "./components/Login";
import Otp from "./components/Otp";
import Register from "./components/Register";
import ResetPassword from "./components/ResetPassword";
import Review from "./components/Review";
import SerachResult from "./components/SearchResult";
import WriteReview from "./components/WriteReview";
import PrivacyPolicy from "./components/PrivacyPolicy";
import LandingPageApi from "./layout/LandingPage";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div className="App">
      <Toaster />
      <Routes>

        <Route path='/' exact element={<Index />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />

        <Route path='/login' exact element={<Login />} />
        <Route path='/register' exact element={<Register />} />

        <Route path='/otp-verification' exact element={<Otp />} />
        <Route path='/forget-password' exact element={<ForgetPassword />} />

        <Route path='/reset-password' exact element={<ResetPassword />} />

        <Route path="/details" element={<Details />}>
          <Route index element={<Details />} />
          <Route path=":id" element={<Details />} />
        </Route>
        {/* <Route path='/dashboard' exact element={<Dashboard />} /> */}
        {/* <Route path='/' exact element={<LandingPageApi />} /> */}

        <Route path='/inquiry-form' exact element={<InquiryForm />} />

        <Route path="/service-details" exact element={<DetailsServices />} />
        <Route path="/therapy-details" exact element={<DetailsTherapy />} />
        <Route path="/amenities-details" exact element={<DetailsFacility />} />

        <Route path='/review' exact element={<Review />} />
        <Route path='/write-review' exact element={<WriteReview />} />

        <Route path="/search-result" exact element={<SerachResult />} />

        <Route path="*" exact element={<Navigate to="/" />} />

      </Routes>
    </div>
  );
}

export default App;
