import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { CONTIGO_API_URL } from "../BaseURL";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";

const Review = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [error, setError] = useState({});
  const [disable, setDisable] = useState(false);
  const [show, setShow] = useState(false);
  const imgRef = useRef();

  const [review, setReview] = useState({
    name: "",
    email: "",
    feedback: "",
    image: ""
  });
  const stars = [1, 2, 3, 4, 5];

  const [rating, setRating] = useState({
    overall: 0,
    services: 0,
    cleanliness: 0,
    ambience: 0,
    knowledge: 0,
    communication: 0,
  });
  const handleRating = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    setRating({ ...rating, [name]: value })
  }

  // const [selected, setSelected] = useState([]);
  // const [selectedServices, setSelectedServices] = useState([]);
  // const [selectedClean, setSelectedClean] = useState([]);
  // const [selectedFacilities, setSelectedFacilities] = useState([]);
  // const [selectedCare, setSelectedCare] = useState([]);
  // const [selectedCommunication, setSelectedCommunication] = useState([]);

  // const [allCheckboxes, setAllCheckboxes] = useState([
  //   { id: 1, checked: false },
  //   { id: 2, checked: false },
  //   { id: 3, checked: false },
  //   { id: 4, checked: false },
  //   { id: 5, checked: false },
  // ]);
  // const [serviceCheckboxes, setServiceCheckboxes] = useState([
  //   { id: 1, checked: false },
  //   { id: 2, checked: false },
  //   { id: 3, checked: false },
  //   { id: 4, checked: false },
  //   { id: 5, checked: false },
  // ]);
  // const [cleanCheckboxes, setCleanCheckboxes] = useState([
  //   { id: 1, checked: false },
  //   { id: 2, checked: false },
  //   { id: 3, checked: false },
  //   { id: 4, checked: false },
  //   { id: 5, checked: false },
  // ]);

  // const [facilitiesCheckboxes, setFacilitiesCheckboxes] = useState([
  //   { id: 1, checked: false },
  //   { id: 2, checked: false },
  //   { id: 3, checked: false },
  //   { id: 4, checked: false },
  //   { id: 5, checked: false },
  // ]);

  // const [careCheckboxes, setCareCheckboxes] = useState([
  //   { id: 1, checked: false },
  //   { id: 2, checked: false },
  //   { id: 3, checked: false },
  //   { id: 4, checked: false },
  //   { id: 5, checked: false },
  // ]);

  // const [communicationCheckboxes, setCommunicationCheckboxes] = useState([
  //   { id: 1, checked: false },
  //   { id: 2, checked: false },
  //   { id: 3, checked: false },
  //   { id: 4, checked: false },
  //   { id: 5, checked: false },
  // ]);

  // const handleCheckboxAll = (id) => {
  //   if (selected.includes(id)) {
  //     setSelected(selected.filter((item) => item !== id));
  //   } else {
  //     setSelected([...selected, id]);
  //   }
  // };

  // const handleCheckBoxService = (id) => {
  //   if (selectedServices.includes(id)) {
  //     setSelectedServices(selectedServices.filter((service) => service !== id));
  //   } else {
  //     setSelectedServices([...selectedServices, id]);
  //   }
  // };

  // const handleCheckboxClean = (id) => {
  //   if (selectedClean.includes(id)) {
  //     setSelectedClean(selectedClean.filter((clean) => clean !== id));
  //   } else {
  //     setSelectedClean([...selectedClean, id]);
  //   }
  // };

  // const handleCheckboxFacilities = (id) => {
  //   if (selectedFacilities.includes(id)) {
  //     setSelectedFacilities(
  //       selectedFacilities.filter((facility) => facility !== id)
  //     );
  //   } else {
  //     setSelectedFacilities([...selectedFacilities, id]);
  //   }
  // };

  // const handleCheckboxCare = (id) => {
  //   if (selectedCare.includes(id)) {
  //     setSelectedCare(selectedCare.filter((care) => care !== id));
  //   } else {
  //     setSelectedCare([...selectedCare, id]);
  //   }
  // };

  // const handleCheckboxComminucation = (id) => {
  //   if (selectedCommunication.includes(id)) {
  //     setSelectedCommunication(
  //       selectedCommunication.filter((talk) => talk !== id)
  //     );
  //   } else {
  //     setSelectedCommunication([...selectedCommunication, id]);
  //   }
  // };

  useEffect(() => {
    if (localStorage.getItem("CONTIGO_TOKEN_PATIENT") === null) {
      navigate("/login");
    }
    if (state.rating_user !== null) {
      let values = state.rating_user.rating;
      setRating({
        overall: parseInt(values['Over all Ratings']),
        services: parseInt(values['Did the services meet your expectation?']),
        cleanliness: parseInt(values['Cleanliness']),
        ambience: parseInt(values['Ambience & Facilities']),
        knowledge: parseInt(values['Nurse knowledge and Care']),
        communication: parseInt(values['Communication']),
      });
      setReview({ ...review, feedback: state.rating_user.feedback, image: state.rating_user.image })
    }
  }, []);

  const handleInput = (e) => {
    setReview({ ...review, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    const payload = {
      // rating: {
      //   "Over all Ratings": selected.length,
      //   "Did the services meet your expectation?": selectedServices.length,
      //   Cleanliness: selectedClean.length,
      //   "Ambience & Facilities": selectedFacilities.length,
      //   "Nurse knowledge and Care": selectedCare.length,
      //   Communication: selectedCommunication.length,
      // },
      rating: {
        "Over all Ratings": parseInt(rating.overall),
        "Did the services meet your expectation?": parseInt(rating.services),
        "Cleanliness": parseInt(rating.cleanliness),
        "Ambience & Facilities": parseInt(rating.ambience),
        "Nurse knowledge and Care": parseInt(rating.knowledge),
        "Communication": parseInt(rating.communication),
      },
      nursingId: state._id,
      feedback: review.feedback,
      image: review.image,
    };
    if (validation()) {
      setDisable(true);

      axios({
        method: "post",
        url: `${CONTIGO_API_URL}api/patient-user/add-rating`,
        data: payload,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${localStorage.getItem(
            "CONTIGO_TOKEN_PATIENT"
          )}`,
        },
      })
        .then((response) => {
          if (response.data.status === true) {
            setShow(true);

            setTimeout(() => {
              setShow(false);
              navigate("/write-review");
            }, 2000);
            setDisable(false);
          }
        })
        .catch((error) => {
          console.log(error, "error");
          setDisable(false);
        });
    }
  };

  const validation = () => {
    var isValid = true;
    let err = {};

    // if (!review.name) {
    //   isValid = false;
    //   err["name_err"] = "Please enter name";
    // }

    // if (!review.email) {
    //   isValid = false;
    //   err["email_err"] = "Please enter email";
    // } else if (typeof review.email !== "undefined") {
    //   let lastAtPos = review.email.lastelOf("@");
    //   let lastDotPos = review.email.lastelOf(".");

    //   if (
    //     !(
    //       lastAtPos < lastDotPos &&
    //       lastAtPos > 0 &&
    //       review.email.elOf("@@") === -1 &&
    //       lastDotPos > 2 &&
    //       review.email.length - lastDotPos > 2
    //     )
    //   ) {
    //     isValid = false;
    //     err["email_err"] = "Email is not valid";
    //   }
    // }

    if (Object.values(rating).every(e => e === 0) && !review.feedback) {
      isValid = false;
      err["feedback_err"] = "Please enter feedback";
    }

    setError(err);
    return isValid;
  };

  const handleImage = (e) => {
    let img = e.target.files[0];
    if (img.type === "image/png" || img.type === "image/jpeg" || img.type === "image/jpg") {
      const myurl = `${CONTIGO_API_URL}api/upload-image`;
      var bodyFormData = new FormData();
      bodyFormData.append("image", img);

      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
      }).then((response) => {
        if (response.data.success) {
          setReview({ ...review, image: response?.data?.data?.url });
        } else {
          setError({ ...error, img_err: "Please try again later" });
          imgRef.current.value = "";
        }
      })
    } else {
      setError({ ...error, img_err: "Please select image of PNG / JPEG / JPG format" });
      imgRef.current.value = "";
    }
  }

  return (
    <>
      <body className="cu-site cu-dashboard-site">
        <Header />
        <main className="cu-dashboard-content">
          <Sidebar />
          <div className="cu-dashboard-content-area">
            <div className="cu-dashboard-breadcrumb">
              <a href="/write-review" className="cu-dashboard-breadcrumb-link">
                <svg
                  width="19"
                  height="14"
                  viewBox="0 0 19 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.07 1.45621L1 6.88284L7.07 12.3095M18 6.88284H1.17"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <h2 className="cu-dashboard-breadcrumb-title">
                Reviews & Feedback
              </h2>
            </div>
            <div className="cu-inquiry-content">
              <div className="row">
                <div className="col col-12 col-md-12 col-lg-10 col-x-6">
                  <div className="cu-inquiry-form-content">
                    <form className="row">
                      {/* <div className="col-12">
                        <label htmlFor="inputUserName" className="form-label">
                          User Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputUserName"
                          name="name"
                          value={review.name}
                          onChange={handleInput}
                        />
                        <div className="error error-message">
                          {error.name_err}
                        </div>
                      </div>
                      <div className="col-12">
                        <label
                          htmlFor="inputEmailAddress"
                          className="form-label"
                        >
                          Email Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputEmailAddress"
                          name="email"
                          value={review.email}
                          onChange={handleInput}
                        />
                        <div className="error error-message">
                          {error.email_err}
                        </div>
                      </div> */}
                      <div className="col-12 mt-2">
                        <h3 className="cu-feedback-details-title">
                          Feedback Details
                        </h3>
                      </div>
                      <div className="col-12">
                        <div className="cu-rating-item">
                          <label
                            htmlFor="inputOverRatings"
                            className="form-label rating-label"
                          >
                            Over all Ratings
                          </label>
                          <div className="star-rating">
                            {stars.map((el) => (
                              <div key={el}>
                                <input
                                  type="checkbox"
                                  id={`${el}-overall-rating`}
                                  name="overall"
                                  value={el}
                                  checked={el <= rating.overall && rating.overall !== 0}
                                  onChange={(e) => !state.rating_user && handleRating(e)}
                                />
                                <label
                                  htmlFor={`${el}-overall-rating`}
                                  className="star multiple-star"
                                  key={el}
                                  style={state?.rating_user ? { pointerEvents: 'none' } : { cursor: 'pointer' }}
                                >
                                  <svg
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.7407 3.51001L15.5007 7.03001C15.7407 7.52002 16.3807 7.99001 16.9207 8.08001L20.1107 8.61001C22.1507 8.95001 22.6307 10.43 21.1607 11.89L18.6807 14.37C18.2607 14.79 18.0307 15.6 18.1607 16.18L18.8707 19.25C19.4307 21.68 18.1407 22.62 15.9907 21.35L13.0007 19.58C12.4607 19.26 11.5707 19.26 11.0207 19.58L8.03065 21.35C5.89065 22.62 4.59065 21.67 5.15065 19.25L5.86065 16.18C5.99065 15.6 5.76065 14.79 5.34065 14.37L2.86065 11.89C1.40065 10.43 1.87065 8.95001 3.91065 8.61001L7.10065 8.08001C7.63065 7.99001 8.27065 7.52002 8.51065 7.03001L10.2707 3.51001C11.2307 1.60001 12.7907 1.60001 13.7407 3.51001Z"
                                      stroke="#004744"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </label>
                              </div>
                            ))}
                            {/* {allCheckboxes.map((all, el) => (
                              <div key={all?.id}>
                                <input
                                  type="checkbox"
                                  id={`${all.id}-all-rating`}
                                  name="all-rating"
                                  checked={selected.includes(all.id)}
                                  onChange={() => handleCheckboxAll(all.id)}
                                />
                                <label
                                  htmlFor={`${all.id}-all-rating`}
                                  className="star multiple-star"
                                  key={all.id}
                                >
                                  <svg
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.7407 3.51001L15.5007 7.03001C15.7407 7.52002 16.3807 7.99001 16.9207 8.08001L20.1107 8.61001C22.1507 8.95001 22.6307 10.43 21.1607 11.89L18.6807 14.37C18.2607 14.79 18.0307 15.6 18.1607 16.18L18.8707 19.25C19.4307 21.68 18.1407 22.62 15.9907 21.35L13.0007 19.58C12.4607 19.26 11.5707 19.26 11.0207 19.58L8.03065 21.35C5.89065 22.62 4.59065 21.67 5.15065 19.25L5.86065 16.18C5.99065 15.6 5.76065 14.79 5.34065 14.37L2.86065 11.89C1.40065 10.43 1.87065 8.95001 3.91065 8.61001L7.10065 8.08001C7.63065 7.99001 8.27065 7.52002 8.51065 7.03001L10.2707 3.51001C11.2307 1.60001 12.7907 1.60001 13.7407 3.51001Z"
                                      stroke="#004744"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </label>
                              </div>
                            ))} */}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="cu-rating-item">
                          <label
                            htmlFor="inputexpectationRatings"
                            className="form-label rating-label"
                          >
                            Did the services meet your expectation?
                          </label>
                          <div className="star-rating">
                            {/* {serviceCheckboxes.map((service) => (
                              <div>
                                <input
                                  type="checkbox"
                                  id={`${service.id}-expectation-rating`}
                                  checked={selectedServices.includes(
                                    service.id
                                  )}
                                  onChange={() =>
                                    handleCheckBoxService(service.id)
                                  }
                                />
                                <label
                                  htmlFor={`${service.id}-expectation-rating`}
                                  className="star multiple-star"
                                >
                                  <svg
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.7407 3.51001L15.5007 7.03001C15.7407 7.52002 16.3807 7.99001 16.9207 8.08001L20.1107 8.61001C22.1507 8.95001 22.6307 10.43 21.1607 11.89L18.6807 14.37C18.2607 14.79 18.0307 15.6 18.1607 16.18L18.8707 19.25C19.4307 21.68 18.1407 22.62 15.9907 21.35L13.0007 19.58C12.4607 19.26 11.5707 19.26 11.0207 19.58L8.03065 21.35C5.89065 22.62 4.59065 21.67 5.15065 19.25L5.86065 16.18C5.99065 15.6 5.76065 14.79 5.34065 14.37L2.86065 11.89C1.40065 10.43 1.87065 8.95001 3.91065 8.61001L7.10065 8.08001C7.63065 7.99001 8.27065 7.52002 8.51065 7.03001L10.2707 3.51001C11.2307 1.60001 12.7907 1.60001 13.7407 3.51001Z"
                                      stroke="#004744"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </label>
                              </div>
                            ))} */}
                            {stars.map((el) => (
                              <div key={el}>
                                <input
                                  type="checkbox"
                                  id={`${el}-services-rating`}
                                  name="services"
                                  value={el}
                                  checked={el <= rating.services && rating.services !== 0}
                                  onChange={(e) => !state.rating_user && handleRating(e)}
                                />
                                <label
                                  htmlFor={`${el}-services-rating`}
                                  className="star multiple-star"
                                  key={el}
                                  style={state?.rating_user ? { pointerEvents: 'none' } : { cursor: 'pointer' }}
                                >
                                  <svg
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.7407 3.51001L15.5007 7.03001C15.7407 7.52002 16.3807 7.99001 16.9207 8.08001L20.1107 8.61001C22.1507 8.95001 22.6307 10.43 21.1607 11.89L18.6807 14.37C18.2607 14.79 18.0307 15.6 18.1607 16.18L18.8707 19.25C19.4307 21.68 18.1407 22.62 15.9907 21.35L13.0007 19.58C12.4607 19.26 11.5707 19.26 11.0207 19.58L8.03065 21.35C5.89065 22.62 4.59065 21.67 5.15065 19.25L5.86065 16.18C5.99065 15.6 5.76065 14.79 5.34065 14.37L2.86065 11.89C1.40065 10.43 1.87065 8.95001 3.91065 8.61001L7.10065 8.08001C7.63065 7.99001 8.27065 7.52002 8.51065 7.03001L10.2707 3.51001C11.2307 1.60001 12.7907 1.60001 13.7407 3.51001Z"
                                      stroke="#004744"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="cu-rating-item">
                          <label
                            htmlFor="inputCleanlinessRatings"
                            className="form-label rating-label"
                          >
                            Cleanliness
                          </label>
                          <div className="star-rating">
                            {/* {cleanCheckboxes.map((clean) => (
                              <div key={clean.id}>
                                <input
                                  type="checkbox"
                                  id={`${clean.id}-cleanliness-rating`}
                                  checked={selectedClean.includes(clean.id)}
                                  onChange={() => handleCheckboxClean(clean.id)}
                                />
                                <label
                                  htmlFor={`${clean.id}-cleanliness-rating`}
                                  className="star multiple-star"
                                >
                                  <svg
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.7407 3.51001L15.5007 7.03001C15.7407 7.52002 16.3807 7.99001 16.9207 8.08001L20.1107 8.61001C22.1507 8.95001 22.6307 10.43 21.1607 11.89L18.6807 14.37C18.2607 14.79 18.0307 15.6 18.1607 16.18L18.8707 19.25C19.4307 21.68 18.1407 22.62 15.9907 21.35L13.0007 19.58C12.4607 19.26 11.5707 19.26 11.0207 19.58L8.03065 21.35C5.89065 22.62 4.59065 21.67 5.15065 19.25L5.86065 16.18C5.99065 15.6 5.76065 14.79 5.34065 14.37L2.86065 11.89C1.40065 10.43 1.87065 8.95001 3.91065 8.61001L7.10065 8.08001C7.63065 7.99001 8.27065 7.52002 8.51065 7.03001L10.2707 3.51001C11.2307 1.60001 12.7907 1.60001 13.7407 3.51001Z"
                                      stroke="#004744"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </label>
                              </div>
                            ))} */}
                            {stars.map((el) => (
                              <div key={el}>
                                <input
                                  type="checkbox"
                                  id={`${el}-cleanliness-rating`}
                                  name="cleanliness"
                                  value={el}
                                  checked={el <= rating.cleanliness && rating.cleanliness !== 0}
                                  onChange={(e) => !state.rating_user && handleRating(e)}
                                />
                                <label
                                  htmlFor={`${el}-cleanliness-rating`}
                                  className="star multiple-star"
                                  key={el}
                                  style={state?.rating_user ? { pointerEvents: 'none' } : { cursor: 'pointer' }}
                                >
                                  <svg
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.7407 3.51001L15.5007 7.03001C15.7407 7.52002 16.3807 7.99001 16.9207 8.08001L20.1107 8.61001C22.1507 8.95001 22.6307 10.43 21.1607 11.89L18.6807 14.37C18.2607 14.79 18.0307 15.6 18.1607 16.18L18.8707 19.25C19.4307 21.68 18.1407 22.62 15.9907 21.35L13.0007 19.58C12.4607 19.26 11.5707 19.26 11.0207 19.58L8.03065 21.35C5.89065 22.62 4.59065 21.67 5.15065 19.25L5.86065 16.18C5.99065 15.6 5.76065 14.79 5.34065 14.37L2.86065 11.89C1.40065 10.43 1.87065 8.95001 3.91065 8.61001L7.10065 8.08001C7.63065 7.99001 8.27065 7.52002 8.51065 7.03001L10.2707 3.51001C11.2307 1.60001 12.7907 1.60001 13.7407 3.51001Z"
                                      stroke="#004744"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="cu-rating-item">
                          <label
                            htmlFor="inputFacilitiesRatings"
                            className="form-label rating-label"
                          >
                            Ambience & Facilities
                          </label>
                          <div className="star-rating">
                            {/* {facilitiesCheckboxes.map((facility) => (
                              <div key={facility.id}>
                                <input
                                  type="checkbox"
                                  id={`${facility?.id}-facilities-rating`}
                                  checked={selectedFacilities.includes(
                                    facility.id
                                  )}
                                  onChange={() =>
                                    handleCheckboxFacilities(facility.id)
                                  }
                                />
                                <label
                                  htmlFor={`${facility.id}-facilities-rating`}
                                  className="star multiple-star"
                                >
                                  <svg
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.7407 3.51001L15.5007 7.03001C15.7407 7.52002 16.3807 7.99001 16.9207 8.08001L20.1107 8.61001C22.1507 8.95001 22.6307 10.43 21.1607 11.89L18.6807 14.37C18.2607 14.79 18.0307 15.6 18.1607 16.18L18.8707 19.25C19.4307 21.68 18.1407 22.62 15.9907 21.35L13.0007 19.58C12.4607 19.26 11.5707 19.26 11.0207 19.58L8.03065 21.35C5.89065 22.62 4.59065 21.67 5.15065 19.25L5.86065 16.18C5.99065 15.6 5.76065 14.79 5.34065 14.37L2.86065 11.89C1.40065 10.43 1.87065 8.95001 3.91065 8.61001L7.10065 8.08001C7.63065 7.99001 8.27065 7.52002 8.51065 7.03001L10.2707 3.51001C11.2307 1.60001 12.7907 1.60001 13.7407 3.51001Z"
                                      stroke="#004744"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </label>
                              </div>
                            ))} */}
                            {stars.map((el) => (
                              <div key={el}>
                                <input
                                  type="checkbox"
                                  id={`${el}-ambience-rating`}
                                  name="ambience"
                                  value={el}
                                  checked={el <= rating.ambience && rating.ambience !== 0}
                                  onChange={(e) => !state.rating_user && handleRating(e)}
                                />
                                <label
                                  htmlFor={`${el}-ambience-rating`}
                                  className="star multiple-star"
                                  key={el}
                                  style={state?.rating_user ? { pointerEvents: 'none' } : { cursor: 'pointer' }}
                                >
                                  <svg
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.7407 3.51001L15.5007 7.03001C15.7407 7.52002 16.3807 7.99001 16.9207 8.08001L20.1107 8.61001C22.1507 8.95001 22.6307 10.43 21.1607 11.89L18.6807 14.37C18.2607 14.79 18.0307 15.6 18.1607 16.18L18.8707 19.25C19.4307 21.68 18.1407 22.62 15.9907 21.35L13.0007 19.58C12.4607 19.26 11.5707 19.26 11.0207 19.58L8.03065 21.35C5.89065 22.62 4.59065 21.67 5.15065 19.25L5.86065 16.18C5.99065 15.6 5.76065 14.79 5.34065 14.37L2.86065 11.89C1.40065 10.43 1.87065 8.95001 3.91065 8.61001L7.10065 8.08001C7.63065 7.99001 8.27065 7.52002 8.51065 7.03001L10.2707 3.51001C11.2307 1.60001 12.7907 1.60001 13.7407 3.51001Z"
                                      stroke="#004744"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="cu-rating-item">
                          <label
                            htmlFor="inputknowledgeRatings"
                            className="form-label rating-label"
                          >
                            Nurse knowledge and Care:
                          </label>
                          <div className="star-rating">
                            {/* {careCheckboxes.map((care) => (
                              <div key={care.id}>
                                <input
                                  type="checkbox"
                                  id={`${care.id}-knowledge-rating`}
                                  checked={selectedCare.includes(care.id)}
                                  onChange={() => handleCheckboxCare(care.id)}
                                />
                                <label
                                  htmlFor={`${care.id}-knowledge-rating`}
                                  className="star multiple-star"
                                >
                                  <svg
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.7407 3.51001L15.5007 7.03001C15.7407 7.52002 16.3807 7.99001 16.9207 8.08001L20.1107 8.61001C22.1507 8.95001 22.6307 10.43 21.1607 11.89L18.6807 14.37C18.2607 14.79 18.0307 15.6 18.1607 16.18L18.8707 19.25C19.4307 21.68 18.1407 22.62 15.9907 21.35L13.0007 19.58C12.4607 19.26 11.5707 19.26 11.0207 19.58L8.03065 21.35C5.89065 22.62 4.59065 21.67 5.15065 19.25L5.86065 16.18C5.99065 15.6 5.76065 14.79 5.34065 14.37L2.86065 11.89C1.40065 10.43 1.87065 8.95001 3.91065 8.61001L7.10065 8.08001C7.63065 7.99001 8.27065 7.52002 8.51065 7.03001L10.2707 3.51001C11.2307 1.60001 12.7907 1.60001 13.7407 3.51001Z"
                                      stroke="#004744"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </label>
                              </div>
                            ))} */}
                            {stars.map((el) => (
                              <div key={el}>
                                <input
                                  type="checkbox"
                                  id={`${el}-knowledge-rating`}
                                  name="knowledge"
                                  value={el}
                                  checked={el <= rating.knowledge && rating.knowledge !== 0}
                                  onChange={(e) => !state.rating_user && handleRating(e)}
                                />
                                <label
                                  htmlFor={`${el}-knowledge-rating`}
                                  className="star multiple-star"
                                  key={el}
                                  style={state?.rating_user ? { pointerEvents: 'none' } : { cursor: 'pointer' }}
                                >
                                  <svg
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.7407 3.51001L15.5007 7.03001C15.7407 7.52002 16.3807 7.99001 16.9207 8.08001L20.1107 8.61001C22.1507 8.95001 22.6307 10.43 21.1607 11.89L18.6807 14.37C18.2607 14.79 18.0307 15.6 18.1607 16.18L18.8707 19.25C19.4307 21.68 18.1407 22.62 15.9907 21.35L13.0007 19.58C12.4607 19.26 11.5707 19.26 11.0207 19.58L8.03065 21.35C5.89065 22.62 4.59065 21.67 5.15065 19.25L5.86065 16.18C5.99065 15.6 5.76065 14.79 5.34065 14.37L2.86065 11.89C1.40065 10.43 1.87065 8.95001 3.91065 8.61001L7.10065 8.08001C7.63065 7.99001 8.27065 7.52002 8.51065 7.03001L10.2707 3.51001C11.2307 1.60001 12.7907 1.60001 13.7407 3.51001Z"
                                      stroke="#004744"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="cu-rating-item">
                          <label
                            htmlFor="inputCommunicationRatings"
                            className="form-label rating-label"
                          >
                            Communication
                          </label>
                          <div className="star-rating">
                            {/* {communicationCheckboxes.map((talk) => (
                              <div key={talk.id}>
                                <input
                                  type="checkbox"
                                  id={`${talk.id}-communication-rating`}
                                  checked={selectedCommunication.includes(
                                    talk.id
                                  )}
                                  onChange={() =>
                                    handleCheckboxComminucation(talk.id)
                                  }
                                />
                                <label
                                  htmlFor={`${talk.id}-communication-rating`}
                                  className="star multiple-star"
                                >
                                  <svg
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.7407 3.51001L15.5007 7.03001C15.7407 7.52002 16.3807 7.99001 16.9207 8.08001L20.1107 8.61001C22.1507 8.95001 22.6307 10.43 21.1607 11.89L18.6807 14.37C18.2607 14.79 18.0307 15.6 18.1607 16.18L18.8707 19.25C19.4307 21.68 18.1407 22.62 15.9907 21.35L13.0007 19.58C12.4607 19.26 11.5707 19.26 11.0207 19.58L8.03065 21.35C5.89065 22.62 4.59065 21.67 5.15065 19.25L5.86065 16.18C5.99065 15.6 5.76065 14.79 5.34065 14.37L2.86065 11.89C1.40065 10.43 1.87065 8.95001 3.91065 8.61001L7.10065 8.08001C7.63065 7.99001 8.27065 7.52002 8.51065 7.03001L10.2707 3.51001C11.2307 1.60001 12.7907 1.60001 13.7407 3.51001Z"
                                      stroke="#004744"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </label>
                              </div>
                            ))} */}
                            {stars.map((el) => (
                              <div key={el}>
                                <input
                                  type="checkbox"
                                  id={`${el}-communication-rating`}
                                  name="communication"
                                  value={el}
                                  checked={el <= rating.communication && rating.communication !== 0}
                                  onChange={(e) => !state.rating_user && handleRating(e)}
                                />
                                <label
                                  htmlFor={`${el}-communication-rating`}
                                  className="star multiple-star"
                                  key={el}
                                  style={state?.rating_user ? { pointerEvents: 'none' } : { cursor: 'pointer' }}
                                >
                                  <svg
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.7407 3.51001L15.5007 7.03001C15.7407 7.52002 16.3807 7.99001 16.9207 8.08001L20.1107 8.61001C22.1507 8.95001 22.6307 10.43 21.1607 11.89L18.6807 14.37C18.2607 14.79 18.0307 15.6 18.1607 16.18L18.8707 19.25C19.4307 21.68 18.1407 22.62 15.9907 21.35L13.0007 19.58C12.4607 19.26 11.5707 19.26 11.0207 19.58L8.03065 21.35C5.89065 22.62 4.59065 21.67 5.15065 19.25L5.86065 16.18C5.99065 15.6 5.76065 14.79 5.34065 14.37L2.86065 11.89C1.40065 10.43 1.87065 8.95001 3.91065 8.61001L7.10065 8.08001C7.63065 7.99001 8.27065 7.52002 8.51065 7.03001L10.2707 3.51001C11.2307 1.60001 12.7907 1.60001 13.7407 3.51001Z"
                                      stroke="#004744"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <label
                          htmlFor="inputEmailAddress"
                          className="form-label"
                        >
                          Any Other Feedback
                        </label>
                        <textarea
                          className="form-control"
                          name="feedback"
                          onChange={handleInput}
                          value={review.feedback}
                          rows="2"
                          readOnly={state?.rating_user}
                        ></textarea>
                        <div className="error error-message">
                          {error.feedback_err}
                        </div>
                      </div>
                      <div className="col-12">
                        <label
                          htmlFor="inputEmailAddress"
                          className="form-label"
                        >
                          Attach Image For Feedback
                        </label>
                        {!state?.rating_user && <input
                          className="form-control"
                          type="file"
                          onChange={handleImage}
                          accept="image/*"
                          ref={imgRef}
                        />}
                        {review?.image ?
                          <img src={review?.image} style={{ height: "100px", width: "100%", objectFit: "cover" }} /> :
                          state?.rating_user ? <span>No Image Attached</span> : ""}
                        <div className="error error-message">
                          {error.img_err}
                        </div>
                      </div>
                      {!state?.rating_user &&
                        <div className="col-12">
                          <div className="cu-inqury-form-btn">
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={handleSubmit}
                              disabled={disable}
                            >
                              {disable ? "Processing..." : "Submit"}
                            </button>
                          </div>
                        </div>}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        {/* <!-- Thank you Modal --> */}
        {/* <div className="modal fade cu-thank-you-modal" id="thankYouModal" tabel="-1" aria-labelledby="thankYouModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content ">
                            <div className="modal-body">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                <div className="cu-thank-you-content">
                                    <div className="cu-thank-you-logo">
                                        <img src="assets/images/logo.png" alt="Logo" className="img-fluid" />
                                    </div>
                                    <h3 className="cu-thank-you-title">Thank You !!</h3>
                                    <p className="cu-thank-you-description">Thankyou for your valuable feedback</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        <Modal show={show} onHide={() => setShow(false)} centered>
          <Modal.Body>
            <div className="cu-thank-you-content">
              <div className="cu-thank-you-logo">
                <img
                  src="assets/images/logo.png"
                  alt="Logo"
                  className="img-fluid"
                />
              </div>
              <h3 className="cu-thank-you-title">Thank You !!</h3>
              <p className="cu-thank-you-description">
                Thank you for your valuable feedback
              </p>
            </div>
          </Modal.Body>
        </Modal>
      </body>
    </>
  );
};

export default Review;
