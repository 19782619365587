import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { toast, Toaster } from "react-hot-toast";

import cityData from "../city.json";
import zipData from "../zip_code.json";
import { CONTIGO_API_URL } from "../BaseURL";
import axios from "axios";

import Skeleton from "react-loading-skeleton";
import { Modal, Button } from "react-bootstrap";

const SerachResult = () => {
  const [like, setLike] = useState("");
  const location = useLocation();
  const nav = useNavigate();
  const data = location.state;
  // console.log(data?.add, "data");

  const [address, setAddress] = useState(null);
  const [zipcode, setZipcode] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsTwo, setSearchResultsTwo] = useState([]);
  const [cancelTokenSource1, setCancelTokenSource1] = useState(null);  // Cancel token source for first API call


  const [centerData, setCenterData] = useState([]);
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const [show, setShow] = useState(false);
  const [shareModalShow, setShareModalShow] = useState(false);

  const initialState = {
    center_id: "",
    sender_name: "",
    sender_email: "",
    receivers: "",
    message: "",
  };
  const [shareModalDetails, setShareModalDetails] = useState(initialState);

  const [error, setError] = useState({})

  const handleClose = () => setShow(false);
  const handleShareModalClose = () => setShareModalShow(false);
  const handleShow = () => setShow(true);

  const isLoggedIn = localStorage.getItem("CONTIGO_TOKEN_PATIENT");

  const handleLogout = () => {
    localStorage.removeItem("CONTIGO_TOKEN_PATIENT");
    toast.success("Logout Successful..!");
    setTimeout(() => {
      nav("/");
      window.location.reload();
    }, 1000);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setAddress(inputValue);

    if (inputValue) {
      const filteredResults = cityData.filter((city) =>
        city.toUpperCase().includes(inputValue.toUpperCase())
      );
      setSearchResults(filteredResults);
    } else {
      setSearchResults([]);
      if (!zipcode) {
        setAddress("");
        setLoading(true);
        getCenterData(e, inputValue, zipcode);
      }
    }
  };

  const handleResultClick = (result) => {
    setAddress(result);
    setSearchResults([]); // Clear search results after selection
  };

  const handleResultClickTwo = (data) => {
    setZipcode(data);
    setSearchResultsTwo([]);
  };

  const handleInput = (e) => {
    const inputVal = e.target.value;

    setZipcode(inputVal);
    if (inputVal) {
      const filteredResults = zipData.filter((zip) =>
        zip.toString().includes(inputVal)
      );
      setSearchResultsTwo(filteredResults);
    } else {
      setSearchResultsTwo([]);
      if (!address) {
        setZipcode("");
        setLoading(true);
        getCenterData(e, address, inputVal);
      }
    }
  };

  const [sharedLink, setSharedLink] = useState("");
  const [copyMessage, setCopyMessage] = useState(false);

  const handleSend = (id) => {
    // if (localStorage.getItem("CONTIGO_TOKEN_PATIENT")) {
    //   const urlToCopy = "http://34.237.130.61/";

    //   const tempTextarea = document.createElement("textarea");
    //   tempTextarea.value = urlToCopy;

    //   tempTextarea.style.position = "absolute";
    //   tempTextarea.style.left = "-9999px";
    //   document.body.appendChild(tempTextarea);

    //   tempTextarea.select();
    //   document.execCommand("copy");

    //   // Clean up by removing the temporary textarea
    //   document.body.removeChild(tempTextarea);

    //   console.log("URL copied to clipboard:", urlToCopy);
    //   toast.success("Link copied to clipboard");
    // } else {
    //   setShow(true);
    // }

    setShareModalShow(true);
    setShareModalDetails(shareModalDetails => ({ ...shareModalDetails, center_id: id }))

  };

  // const handleSend = (id) => {
  //     if (localStorage.getItem('CONTIGO_TOKEN_PATIENT')) {
  //         const urlToCopy = 'http://34.237.130.61/';

  //         if (navigator.clipboard) {
  //             navigator.clipboard.writeText(urlToCopy)
  //                 .then(() => {
  //                     console.log('URL copied to clipboard:', urlToCopy);
  //                     toast.success('Link copied to clipboard');
  //                 })
  //                 .catch((error) => {
  //                     console.error('Error copying URL to clipboard:', error);
  //                 });
  //         } else {
  //             console.error('Clipboard API not supported');
  //         }
  //     } else {
  //         setShow(true);
  //     }
  // };

  useEffect((e) => {
    const getResult = localStorage.getItem("SEARCH_RESULT")
    if (getResult) {
      const parseData = JSON.parse(getResult)
      setAddress(parseData?.add)
      setZipcode(parseData?.zip)
      localStorage.removeItem("SEARCH_RESULT")
      getCenterData(e, parseData?.add, parseData?.zip);
    } else {
      getCenterImage()
    }
  }, []);

  const handleSearchButtonClick = (e) => {
    setSearchResults([]);
    setSearchResultsTwo([]);
    getCenterData(e, address, zipcode);
  };

  const getCenterImage = (e) => {
    setAddress(null)
    setZipcode(null)
    e?.preventDefault();

    setLoading(true);
    const myurl = `${CONTIGO_API_URL}api/patient-user/search-center`;
    const fd = new URLSearchParams();

    if (cancelTokenSource1) {
      cancelTokenSource1.cancel('Search term changed');  // Cancel any pending first API call with a reason
      setCancelTokenSource1(null);  // Reset the source for the next search
    }

    const cancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource1(cancelTokenSource);  // Set source for the current search


    if (localStorage.getItem("CONTIGO_TOKEN_PATIENT")) {
      axios({
        method: "post",
        url: myurl,
        data: fd,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization:
            "Bearer " + localStorage.getItem("CONTIGO_TOKEN_PATIENT"),
        },
        cancelToken: cancelTokenSource.token,  // Add cancellation token here
      })
        .then((response) => {
          console.log("@@", response?.data);
          setCenterData(response?.data?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
          setAddress("");
          setZipcode("");
        })
        .catch((error) => {
          console.log(error, "err");
          setCenterData([]);
          if (error?.response?.data?.message) {
            setLoading(false);
          }
          if (error?.response?.status === 401) {
            localStorage.removeItem("CONTIGO_TOKEN_PATIENT");
            nav("/login");
          }
        });
    } else {
      axios({
        method: "post",
        url: myurl,
        data: fd,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "",
        },
        cancelToken: cancelTokenSource.token,  // Add cancellation token here
      })
        .then((response) => {
          console.log("@@", response?.data);
          setCenterData(response?.data?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
          setAddress("");
          setZipcode("");
        })
        .catch((error) => {
          console.log(error, "err");
          if (error?.response?.data?.message) {
            setLoading(false);
          }
          setCenterData([]);
          if (error?.response?.status === 401) {
            localStorage.removeItem("CONTIGO_TOKEN_PATIENT");
            nav("/login");
          }
        });
    }
  };

  const getCenterData = (e, add, zip) => {
    e?.preventDefault();
    setLoading(true);
    const myurl = `${CONTIGO_API_URL}api/patient-user/search-center`;
    const fd = new URLSearchParams();

    // if (!clickButton && (address || zipcode)) {
    if (add) fd.append("city", add);
    if (zip) fd.append("zip", zip);
    // }


    if (cancelTokenSource1) {
      cancelTokenSource1.cancel('Search term changed');  // Cancel any pending first API call with a reason
      setCancelTokenSource1(null);  // Reset the source for the next search
    }

    const cancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource1(cancelTokenSource);  // Set source for the current search



    // fd.append("page", currentPage);

    if (localStorage.getItem("CONTIGO_TOKEN_PATIENT")) {
      axios({
        method: "post",
        url: myurl,
        data: fd,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization:
            "Bearer " + localStorage.getItem("CONTIGO_TOKEN_PATIENT"),
        },
        cancelToken: cancelTokenSource.token,  // Add cancellation token here
      })
        .then((response) => {
          console.log("@@", response?.data);
          setCenterData(response?.data?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        })
        .catch((error) => {
          console.log(error, "err");
          setCenterData([]);
          if (error?.response?.data?.message) {
            toast.error(error?.response?.data?.message);
            setLoading(false);
          }
          if (error?.response?.status === 401) {
            localStorage.removeItem("CONTIGO_TOKEN_PATIENT");
            nav("/login");
          }
        });
    } else {
      axios({
        method: "post",
        url: myurl,
        data: fd,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "",
        },
        cancelToken: cancelTokenSource.token,  // Add cancellation token here
      })
        .then((response) => {
          console.log("@@", response?.data);
          setCenterData(response?.data?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        })
        .catch((error) => {
          console.log("error =============", error);
          setCenterData([]);
          if (error?.response?.data?.message) {
            setLoading(false);
            toast.error(error?.response?.data?.message);
          }
          if (error?.response?.status === 401) {
            localStorage.removeItem("CONTIGO_TOKEN_PATIENT");
            nav("/login");
          }
        });
    }
  };

  const handleDetail = (id) => {
    if (localStorage.getItem("CONTIGO_TOKEN_PATIENT")) {
      nav("/details", { state: id });
      // window.location.reload();
      localStorage.setItem("LOCATION_ID", id);
    } else {
      setShow(true);
      // nav('/login');
    }
  };

  const handleLike = (id, e) => {
    e?.preventDefault();
    const finalData = centerData.map((sea) => {
      return {
        ...sea,
        isLoading: id == sea?._id ? true : false,
        // like: id == sea?._id ? !sea?.like : sea?.like
      }
    })
    setCenterData(finalData)

    if (localStorage.getItem("CONTIGO_TOKEN_PATIENT")) {
      const myurl = `${CONTIGO_API_URL}api/patient-user/center-like`;
      const bodyFormData = new URLSearchParams();
      bodyFormData.append("center_id", id);

      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization:
            "Bearer " + localStorage.getItem("CONTIGO_TOKEN_PATIENT"),
        },
      })
        .then((response) => {
          console.log(response?.data);
          if (response?.data?.success === true) {
            const finalData = centerData.map((sea) => {
              return {
                ...sea,
                like: id == sea?._id ? !sea?.like : sea?.like
              }
            })
            setCenterData(finalData)
            console.log(response?.data, "$$");
            // setDisable(true)
            setLoading(false);
          } else {
            toast.error(response.data.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error, "error");
          toast.error(error.response?.data?.message);
          setCenterData([]);
          setLoading(false);
          if (error?.response?.status === 401) {
            localStorage.removeItem("CONTIGO_TOKEN_PATIENT");
            nav("/login");
          }
        });
    } else {
      setShow(true);
    }
  };

  // google map

  const google = window.google;
  const mapRef = useRef(null);

  // useEffect(() => {
  //     const defaultLocation = { lat: 36.778259, lng: -119.417931 };

  //     const map = new window.google.maps.Map(mapRef.current, {
  //         center: defaultLocation,
  //         zoom: 6,
  //     });

  //     centerData.forEach(center => {
  //         const marker = new window.google.maps.Marker({
  //             position: { lat: center.address.lat, lng: center.address.lng },
  //             map: map,
  //             title: center.address,
  //         });

  //         const infoWindow = new window.google.maps.InfoWindow({
  //             content: `<div>${center.center_name}<br><strong>${center.address.address}</strong></div>`, // Customize this content as needed
  //         });

  //         marker.addListener('click', () => {
  //             infoWindow.open(map, marker);
  //         });

  //     });
  // }, [centerData]);

  useEffect(() => {
    const defaultLocation = { lat: 36.778259, lng: -119.417931 };
    let locationToFocus = null; // Store the location to focus on
    let zoomLevel = 6;

    if (centerData.length === 1) {
      const center = centerData[0];

      if (center.address.lat && center.address.lng) {
        locationToFocus = { lat: center.address.lat, lng: center.address.lng };
        zoomLevel = 13; // Adjust the zoom level as needed
      } else if (center.address.zipcode) {
        const geocoder = new window.google.maps.Geocoder();


        geocoder.geocode(
          { address: center.address.zipcode },
          (results, status) => {
            if (status === "OK" && results.length > 0) {
              locationToFocus = {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              };
              map.setCenter(locationToFocus);
              map.setZoom(zoomLevel);
            }
          }
        );
      }
    }

    const map = new window.google.maps.Map(mapRef.current, {
      center: locationToFocus || defaultLocation,
      zoom: zoomLevel,
    });

    centerData.forEach((center) => {
      const marker = new window.google.maps.Marker({
        position: { lat: center.address.lat, lng: center.address.lng },
        map: map,
        title: center.center_name,
      });

      const infoWindow = new window.google.maps.InfoWindow({
        content: `<div>${center.center_name}<br><strong>${center.address.address}</strong></div>`,
      });

      marker.addListener("click", () => {
        infoWindow.open(map, marker);
      });
    });
  }, [centerData]);

  const handleChange = (e) => {
    setShareModalDetails({ ...shareModalDetails, [e.target.name]: e.target.value.trimStart() })
  }

  const handleChangeWithoutNumber = (e) => {
    const sanitizedValue = e.target.value.replace(/[^a-zA-Z\s]/g, '');
    setShareModalDetails({ ...shareModalDetails, [e.target.name]: sanitizedValue.trimStart() })
  }

  const handleShare = () => {
    if (validation()) {

      setDisable(true)
      const bodyFormData = new URLSearchParams();


      bodyFormData.append('center_id', shareModalDetails.center_id);
      bodyFormData.append('sender_name', shareModalDetails.sender_name);
      bodyFormData.append('sender_email', shareModalDetails.sender_email);
      bodyFormData.append('receivers', JSON.stringify(shareModalDetails.receivers.split(',')));
      bodyFormData.append('message', shareModalDetails.message);

      axios({
        method: 'post',
        url: `${CONTIGO_API_URL}api/patient-user/share-with-friends`,
        data: bodyFormData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded ', Authorization: `Bearer ${localStorage.getItem(
            "CONTIGO_TOKEN_PATIENT"
          )}`,
        }
      }).then((response) => {
        console.log("@@", response.data);
        if (response.data.success === true) {

          toast.success(response.data.message)
          setDisable(false)
          setShareModalShow(false)
          setShareModalDetails(initialState);
        }
      }).catch((err) => {
        console.log(err);
        setError({ ...error, password_err: err.response.data.message })
        toast.error(err.response.data.message);
        setDisable(false)
      })
    }
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validation = () => {
    var isValid = true;
    let err = {};

    if (!localStorage.getItem("CONTIGO_TOKEN_PATIENT")) {
      isValid = false;
      toast.error("Please login first to share this");
    }

    if (!shareModalDetails.sender_name) {
      isValid = false;
      err['sender_name_err'] = "Please enter your name"
    }



    if (!shareModalDetails.sender_email) {
      isValid = false;
      err['sender_email_err'] = "Please enter your email"
    }
    else {
      if (!validateEmail(shareModalDetails.sender_email)) {
        isValid = false;
        err['sender_email_err'] = "Please enter valid email address"
      }
    }



    if (!shareModalDetails.receivers) {
      isValid = false;
      err['receivers_err'] = "Please enter receivers email"
    }
    else {
      shareModalDetails.receivers.split(",").map((email) => {
        if (!validateEmail(email)) {
          isValid = false;
          err['receivers_err'] = "Please enter valid email address"
          return
        }
      })

    }
    setError(err);
    return isValid;
  }

  console.log("centerData", centerData);

  return (
    <div>
      <header className="cu-site-header cu-search-result-header">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid p-0">
            <Link className="navbar-brand" to="/">
              <img
                src="assets/images/logo.png"
                alt="Logo"
                className="img-fluid"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icons"></span>
              <span className="navbar-toggler-icons"></span>
              <span className="navbar-toggler-icons"></span>
            </button>
            <ul className="cu-sidebar-menu-list list-unstyled me-auto">
              <li className="cu-sidebar-menu-item active">
                <a
                  onClick={() => getCenterImage()}
                  style={{ cursor: "pointer" }}
                  className="cu-sidebar-menu-link"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.03366 1.66669H4.45033C2.62533 1.66669 1.66699 2.62502 1.66699 4.44169V6.02502C1.66699 7.84169 2.62533 8.80002 4.44199 8.80002H6.02533C7.84199 8.80002 8.80033 7.84169 8.80033 6.02502V4.44169C8.80866 2.62502 7.85033 1.66669 6.03366 1.66669Z"
                      fill="#78797A"
                    ></path>
                    <path
                      opacity="0.4"
                      d="M15.5585 1.66669H13.9752C12.1585 1.66669 11.2002 2.62502 11.2002 4.44169V6.02502C11.2002 7.84169 12.1585 8.80002 13.9752 8.80002H15.5585C17.3752 8.80002 18.3335 7.84169 18.3335 6.02502V4.44169C18.3335 2.62502 17.3752 1.66669 15.5585 1.66669Z"
                      fill="white"
                    ></path>
                    <path
                      d="M15.5585 11.1917H13.9752C12.1585 11.1917 11.2002 12.15 11.2002 13.9667V15.55C11.2002 17.3667 12.1585 18.325 13.9752 18.325H15.5585C17.3752 18.325 18.3335 17.3667 18.3335 15.55V13.9667C18.3335 12.15 17.3752 11.1917 15.5585 11.1917Z"
                      fill="#78797A"
                    ></path>
                    <path
                      opacity="0.4"
                      d="M6.03366 11.1917H4.45033C2.62533 11.1917 1.66699 12.15 1.66699 13.9667V15.55C1.66699 17.375 2.62533 18.3333 4.44199 18.3333H6.02533C7.84199 18.3333 8.80033 17.375 8.80033 15.5583V13.975C8.80866 12.15 7.85033 11.1917 6.03366 11.1917Z"
                      fill="white"
                    ></path>
                  </svg>
                  <span>Browse Nursing Home</span>
                </a>
              </li>
            </ul>
            {isLoggedIn ? (
              <div className="cu-header-btn">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleLogout}
                >
                  Logout
                </button>
                {/* <GoogleLogout className='btn btn-light' clientId={clientId} buttonText={"Logout"} onLogoutSuccess={onSuccess} /> */}
              </div>
            ) : (
              <div className="cu-header-btn">
                <Link to="/login" className="btn btn-info">
                  Login
                </Link>
                <Link to="/register" className="btn btn-primary">
                  Sign Up
                </Link>
              </div>
            )}
          </div>
        </nav>
      </header>

      <main className="cu-dashboard-content cu-search-result-content">
        <div className="cu-dashboard-content-area">
          <div className="cu-search-result-list">
            <div className="cu-browse-home-content">
              <div className="cu-browse-search">
                <div className="cu-landing-search">
                  <div className="cu-input-icon-group">
                    <input
                      type="search"
                      id="pac-input"
                      value={address}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder="Search City , State"
                    //disabled={loading}
                    />
                    {searchResults.length > 0 && (
                      <ul className="dropdown-menu map-event-city">
                        {searchResults.map((result, index) => (
                          <li
                            key={index}
                            onClick={() => handleResultClick(result)}
                            className="dropdown-item"
                            value={address}
                          >
                            {result}
                          </li>
                        ))}
                      </ul>
                    )}
                    <span className="cu-input-icon">
                      <svg
                        width="19"
                        height="18"
                        viewBox="0 0 19 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.7812 16.1875L15.3438 14.75M9.23438 15.4688C10.1311 15.4688 11.019 15.2921 11.8474 14.949C12.6758 14.6058 13.4285 14.1029 14.0626 13.4688C14.6966 12.8348 15.1996 12.0821 15.5427 11.2536C15.8859 10.4252 16.0625 9.53731 16.0625 8.64062C16.0625 7.74394 15.8859 6.85604 15.5427 6.02761C15.1996 5.19919 14.6966 4.44646 14.0626 3.81241C13.4285 3.17836 12.6758 2.67541 11.8474 2.33226C11.019 1.98911 10.1311 1.8125 9.23438 1.8125C7.42344 1.8125 5.68668 2.53189 4.40616 3.81241C3.12564 5.09293 2.40625 6.82969 2.40625 8.64062C2.40625 10.4516 3.12564 12.1883 4.40616 13.4688C5.68668 14.7494 7.42344 15.4687 9.23438 15.4688Z"
                          stroke="#7D7D7D"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="cu-input-icon-group">
                    <input
                      type="search"
                      value={zipcode}
                      onChange={handleInput}
                      className="form-control"
                      placeholder="Zipcode"
                    //disabled={loading}
                    />
                    {searchResultsTwo.length > 0 && (
                      <ul className="dropdown-menu map-event-zip">
                        {searchResultsTwo.map((elem, index) => (
                          <li
                            key={index}
                            onClick={() => handleResultClickTwo(elem)}
                            className="dropdown-item"
                            value={zipcode}
                          >
                            {elem}
                          </li>
                        ))}
                      </ul>
                    )}
                    <span className="cu-input-icon">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.87531 10.0278C9.1698 10.0278 9.46141 9.96981 9.73348 9.85712C10.0056 9.74442 10.2528 9.57924 10.461 9.371C10.6692 9.16277 10.8344 8.91556 10.9471 8.64348C11.0598 8.37141 11.1178 8.07981 11.1178 7.78532C11.1178 7.49083 11.0598 7.19922 10.9471 6.92715C10.8344 6.65508 10.6692 6.40786 10.461 6.19963C10.2528 5.99139 10.0056 5.82621 9.73348 5.71352C9.46141 5.60082 9.1698 5.54282 8.87531 5.54282C8.28056 5.54282 7.71018 5.77908 7.28963 6.19963C6.86908 6.62018 6.63281 7.19057 6.63281 7.78532C6.63281 8.38006 6.86908 8.95045 7.28963 9.371C7.71018 9.79155 8.28056 10.0278 8.87531 10.0278Z"
                          stroke="#B2B2B2"
                          stroke-width="1.5"
                        />
                        <path
                          d="M2.85222 6.47719C4.26815 0.252814 13.4897 0.260002 14.8985 6.48438C15.725 10.1356 13.4538 13.2263 11.4628 15.1381C10.7671 15.8091 9.83829 16.184 8.87175 16.184C7.9052 16.184 6.97635 15.8091 6.28065 15.1381C4.2969 13.2263 2.02565 10.1284 2.85222 6.47719Z"
                          stroke="#B2B2B2"
                          stroke-width="1.5"
                        />
                      </svg>
                    </span>
                    <span className="cu-input-icon cu-current-location-icon">
                      <svg
                        width="19"
                        height="18"
                        viewBox="0 0 19 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.59375 14.3906C11.0234 14.3906 12.3946 13.8227 13.4055 12.8117C14.4164 11.8008 14.9844 10.4297 14.9844 9C14.9844 7.57032 14.4164 6.19919 13.4055 5.18825C12.3946 4.17731 11.0234 3.60938 9.59375 3.60938C8.16407 3.60938 6.79294 4.17731 5.782 5.18825C4.77106 6.19919 4.20313 7.57032 4.20312 9C4.20313 10.4297 4.77106 11.8008 5.782 12.8117C6.79294 13.8227 8.16407 14.3906 9.59375 14.3906Z"
                          stroke="#00657B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.59375 3.25V1.8125M3.84375 9H2.40625M9.59375 14.75V16.1875M15.3438 9H16.7812M9.59375 11.1562C10.1656 11.1562 10.7141 10.9291 11.1184 10.5247C11.5228 10.1203 11.75 9.57187 11.75 9C11.75 8.42813 11.5228 7.87968 11.1184 7.4753C10.7141 7.07093 10.1656 6.84375 9.59375 6.84375C9.02188 6.84375 8.47343 7.07093 8.06905 7.4753C7.66468 7.87968 7.4375 8.42813 7.4375 9C7.4375 9.57187 7.66468 10.1203 8.06905 10.5247C8.47343 10.9291 9.02188 11.1562 9.59375 11.1562Z"
                          stroke="#00657B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <button
                    //disabled={loading}
                    type="button"
                    onClick={handleSearchButtonClick}
                    className="input-group-text btn btn-primary"
                    id="basic-addon2"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>

            <div className="cu-search-result-list__inner">
              {loading ? (
                <div className="cu-search-result-item">
                  <div className="cu-search-result-card mb-4">
                    <div className="cu-search-result-img">
                      <Skeleton
                        count={1}
                        className="img-fluid"
                        height="306px"
                      />
                    </div>
                    <div className="cu-search-result-body">
                      <div className="cu-browse-home-heading">
                        <Skeleton
                          count={1}
                          width="240px"
                          height="35px"
                          className=""
                        />
                      </div>
                      <p className="cu-browse-home-description">
                        <Skeleton
                          count={1}
                          width="240px"
                          height="18px"
                          className="mt-3"
                        />
                      </p>
                      <p className="cu-browse-home-info">
                        <Skeleton
                          count={1}
                          width="240px"
                          height="18px"
                          className=""
                        />
                      </p>
                      <p className="cu-browse-home-info">
                        <Skeleton
                          count={1}
                          width="70px"
                          height="30px"
                          className=""
                        />
                      </p>
                      <p className="cu-browse-home-time">
                        <Skeleton
                          count={1}
                          width="200px"
                          height="18px"
                          className=""
                        />
                      </p>
                      <p className="mt-2">
                        <span>
                          <Skeleton
                            count={1}
                            width="50px"
                            height="30px"
                            className=""
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="cu-search-result-card">
                    <div className="cu-search-result-img">
                      <Skeleton
                        count={1}
                        className="img-fluid"
                        height="305px"
                      />
                    </div>
                    <div className="cu-search-result-body">
                      <div className="cu-browse-home-heading">
                        <Skeleton
                          count={1}
                          width="240px"
                          height="35px"
                          className=""
                        />
                      </div>
                      <p className="cu-browse-home-description">
                        <Skeleton
                          count={1}
                          width="240px"
                          height="18px"
                          className="mt-3"
                        />
                      </p>
                      <p className="cu-browse-home-info">
                        <Skeleton
                          count={1}
                          width="240px"
                          height="18px"
                          className=""
                        />
                      </p>
                      <p className="cu-browse-home-info">
                        <Skeleton
                          count={1}
                          width="70px"
                          height="30px"
                          className=""
                        />
                      </p>
                      <p className="cu-browse-home-time">
                        <Skeleton
                          count={1}
                          width="200px"
                          height="18px"
                          className=""
                        />
                      </p>
                      <p className="mt-2">
                        <span>
                          <Skeleton
                            count={1}
                            width="50px"
                            height="30px"
                            className=""
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                centerData.map((elem) => (
                  <div className="cu-search-result-item" key={elem?.id}>
                    <div className="cu-search-result-card">
                      <div
                        className="cu-search-result-img"
                        onClick={() => handleDetail(elem?.id)}
                        style={{ cursor: "pointer", background: "#f2f2f2" }}
                      >
                        <img
                          src={elem?.center_image && elem?.center_image[0]}
                          style={{ objectFit: "contain", height: "305px" }}
                          alt="Home"
                          className="img-fluid"
                        />
                      </div>
                      <div className="cu-search-result-body">
                        <div className="cu-browse-home-heading">
                          <a
                            onClick={() => handleDetail(elem?.id)}
                            style={{ cursor: "pointer" }}
                            className="cu-browse-home-title"
                          >
                            {elem?.center_name}
                          </a>
                        </div>
                        <p className="cu-browse-home-description">
                          {elem?.description?.length > 40
                            ? elem?.description?.substring(0, 40) + "..."
                            : elem?.description}
                          <br />
                        </p>
                        <p className="cu-browse-home-info">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.0004 11.1917C10.3418 11.1917 10.6799 11.1244 10.9954 10.9938C11.3108 10.8631 11.5974 10.6716 11.8389 10.4301C12.0803 10.1887 12.2718 9.90209 12.4025 9.58665C12.5331 9.2712 12.6004 8.93311 12.6004 8.59167C12.6004 8.25023 12.5331 7.91214 12.4025 7.59669C12.2718 7.28125 12.0803 6.99462 11.8389 6.75319C11.5974 6.51176 11.3108 6.32024 10.9954 6.18958C10.6799 6.05892 10.3418 5.99167 10.0004 5.99167C9.31083 5.99167 8.64951 6.2656 8.16191 6.75319C7.67432 7.24079 7.40039 7.90211 7.40039 8.59167C7.40039 9.28123 7.67432 9.94255 8.16191 10.4301C8.64951 10.9177 9.31083 11.1917 10.0004 11.1917Z"
                              stroke="#B2B2B2"
                              stroke-width="1.5"
                            />
                            <path
                              d="M3.01675 7.07501C4.65842 -0.14166 15.3501 -0.133326 16.9834 7.08334C17.9418 11.3167 15.3084 14.9 13.0001 17.1167C12.1935 17.8946 11.1165 18.3293 9.99592 18.3293C8.87529 18.3293 7.79835 17.8946 6.99175 17.1167C4.69175 14.9 2.05842 11.3083 3.01675 7.07501Z"
                              stroke="#B2B2B2"
                              stroke-width="1.5"
                            />
                          </svg>
                          <span>
                            {elem?.address?.address.length > 40
                              ? elem?.address?.address.substring(0, 40) + "..."
                              : (elem?.address?.address + " , " + elem?.address?.zip)}
                          </span>
                        </p>
                        <p className="cu-browse-home-info">
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.09961 16.775V5.22501"
                              stroke="#B2B2B2"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M1.09961 14.575H20.8996"
                              stroke="#B2B2B2"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M20.9004 14.575V16.775"
                              stroke="#B2B2B2"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M20.7623 14.575C20.8382 14.575 20.8998 14.5134 20.8998 14.4375V12.375C20.8998 9.94494 18.9299 7.97501 16.4998 7.97501H9.3498C9.04604 7.97501 8.7998 8.22124 8.7998 8.52501V14.4375C8.7998 14.5134 8.86138 14.575 8.9373 14.575"
                              stroke="#B2B2B2"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.06591 9.62501C4.6554 9.28467 5.38167 9.28467 5.97116 9.62501C6.56065 9.96535 6.92379 10.5943 6.92379 11.275C6.92379 11.9557 6.56065 12.5847 5.97116 12.925C5.38167 13.2653 4.6554 13.2653 4.06591 12.925C3.47642 12.5847 3.11328 11.9557 3.11328 11.275C3.11328 10.5943 3.47642 9.96535 4.06591 9.62501Z"
                              stroke="#B2B2B2"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <span>{elem?.No_of_bed}</span>
                        </p>
                        <p className="cu-browse-home-time">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_443_5160)">
                              <path
                                d="M18.3337 9.99999C18.3337 14.6 14.6003 18.3333 10.0003 18.3333C5.40033 18.3333 1.66699 14.6 1.66699 9.99999C1.66699 5.39999 5.40033 1.66666 10.0003 1.66666C14.6003 1.66666 18.3337 5.39999 18.3337 9.99999Z"
                                stroke="#00657B"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M13.0914 12.65L10.5081 11.1083C10.0581 10.8417 9.69141 10.2 9.69141 9.675V6.25833"
                                stroke="#00657B"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_443_5160">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <span>
                            {elem?.timing} to {elem?.end_timing}
                          </span>
                        </p>
                        <p className="cu-browse-home-rate">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.7171 18.8917C14.2754 18.8917 13.7088 18.75 13.0004 18.3333L10.5088 16.8583C10.2504 16.7083 9.75042 16.7083 9.50042 16.8583L7.00042 18.3333C5.52542 19.2083 4.65876 18.8583 4.26709 18.575C3.88376 18.2917 3.28376 17.5667 3.67542 15.9L4.26709 13.3417C4.33376 13.075 4.20042 12.6167 4.00042 12.4167L1.93376 10.35C0.900424 9.31667 0.983757 8.43334 1.12542 8.00001C1.26709 7.56667 1.71709 6.80001 3.15042 6.55834L5.80876 6.11667C6.05876 6.07501 6.41709 5.80834 6.52542 5.58334L8.00042 2.64167C8.66709 1.30001 9.54209 1.10001 10.0004 1.10001C10.4588 1.10001 11.3338 1.30001 12.0004 2.64167L13.4671 5.57501C13.5838 5.80001 13.9421 6.06667 14.1921 6.10834L16.8504 6.55001C18.2921 6.79167 18.7421 7.55834 18.8754 7.99167C19.0088 8.42501 19.0921 9.30834 18.0671 10.3417L16.0004 12.4167C15.8004 12.6167 15.6754 13.0667 15.7338 13.3417L16.3254 15.9C16.7088 17.5667 16.1171 18.2917 15.7338 18.575C15.5254 18.725 15.1921 18.8917 14.7171 18.8917ZM10.0004 15.4917C10.4088 15.4917 10.8171 15.5917 11.1421 15.7833L13.6338 17.2583C14.3588 17.6917 14.8171 17.6917 14.9921 17.5667C15.1671 17.4417 15.2921 17 15.1088 16.1833L14.5171 13.625C14.3588 12.9333 14.6171 12.0417 15.1171 11.5333L17.1838 9.46667C17.5921 9.05834 17.7754 8.65834 17.6921 8.38334C17.6004 8.10834 17.2171 7.88334 16.6504 7.79167L13.9921 7.35001C13.3504 7.24167 12.6504 6.72501 12.3588 6.14167L10.8921 3.20834C10.6254 2.67501 10.2921 2.35834 10.0004 2.35834C9.70876 2.35834 9.37542 2.67501 9.11709 3.20834L7.64209 6.14167C7.35042 6.72501 6.65042 7.24167 6.00876 7.35001L3.35876 7.79167C2.79209 7.88334 2.40876 8.10834 2.31709 8.38334C2.22542 8.65834 2.41709 9.06667 2.82542 9.46667L4.89209 11.5333C5.39209 12.0333 5.65042 12.9333 5.49209 13.625L4.90042 16.1833C4.70876 17.0083 4.84209 17.4417 5.01709 17.5667C5.19209 17.6917 5.64209 17.6833 6.37542 17.2583L8.86709 15.7833C9.18376 15.5917 9.59209 15.4917 10.0004 15.4917Z"
                              fill="white"
                            />
                          </svg>
                          <span>
                            {parseFloat(elem?.center_overall_rating?.toFixed(1))}/5
                          </span>
                        </p>
                        <div className="cu-search-result-share">
                          <div className="cu-search-result-share-item">
                            <a
                              onClick={() => handleSend(elem?.id)}
                              style={{ cursor: "pointer" }}
                              className="cu-search-result-share-link"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.001 14.25C16.643 14.25 15.462 14.983 14.804 16.068L9.4729 13.403C9.6489 12.969 9.75098 12.497 9.75098 12C9.75098 11.503 9.6489 11.031 9.4729 10.597L14.804 7.93201C15.462 9.01601 16.643 9.75 18.001 9.75C20.068 9.75 21.751 8.068 21.751 6C21.751 3.932 20.068 2.25 18.001 2.25C15.934 2.25 14.251 3.932 14.251 6C14.251 6.173 14.279 6.33901 14.302 6.50601L8.63696 9.33801C7.95896 8.66601 7.028 8.25 6 8.25C3.933 8.25 2.25 9.932 2.25 12C2.25 14.068 3.933 15.75 6 15.75C7.027 15.75 7.95896 15.333 8.63696 14.662L14.302 17.494C14.279 17.661 14.251 17.827 14.251 18C14.251 20.068 15.934 21.75 18.001 21.75C20.068 21.75 21.751 20.068 21.751 18C21.751 15.932 20.068 14.25 18.001 14.25ZM18.001 3.75C19.241 3.75 20.251 4.759 20.251 6C20.251 7.241 19.241 8.25 18.001 8.25C16.761 8.25 15.751 7.241 15.751 6C15.751 4.759 16.761 3.75 18.001 3.75ZM6.00098 14.25C4.76098 14.25 3.75098 13.241 3.75098 12C3.75098 10.759 4.76098 9.75 6.00098 9.75C7.24098 9.75 8.25098 10.759 8.25098 12C8.25098 13.241 7.24098 14.25 6.00098 14.25ZM18.001 20.25C16.761 20.25 15.751 19.241 15.751 18C15.751 16.759 16.761 15.75 18.001 15.75C19.241 15.75 20.251 16.759 20.251 18C20.251 19.241 19.241 20.25 18.001 20.25Z"
                                  fill="#00657B"
                                />
                              </svg>
                            </a>
                          </div>
                          <div className="cu-search-result-share-item">
                            {/* {elem?.isLoading ? "....." : */}
                            <a
                              onClick={() => handleLike(elem?.id)}
                              style={{ cursor: "pointer" }}
                              className="cu-search-result-share-link"
                            >
                              {elem.like === true ? (
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M20.8301 11.4651C19.6571 17.5151 12.0001 21.0001 12.0001 21.0001C12.0001 21.0001 4.34305 17.5151 3.16905 11.4651C2.44904 7.75308 4.0221 4.01906 8.0211 4.00006C11.0001 3.98606 12.0001 6.98803 12.0001 6.98803C12.0001 6.98803 13.0001 3.98506 15.9781 4.00006C19.9861 4.01906 21.5501 7.75408 20.8301 11.4651Z"
                                    fill="#00657B"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.999 21.7501C11.893 21.7501 11.787 21.728 11.688 21.683C11.361 21.534 3.665 17.9651 2.431 11.6091C1.954 9.15006 2.433 6.75105 3.71201 5.19305C4.747 3.93105 6.23496 3.26003 8.01596 3.25103C8.02496 3.25103 8.03396 3.25103 8.04196 3.25103C10.074 3.25103 11.313 4.40806 11.998 5.39306C12.686 4.40406 13.9349 3.24203 15.9799 3.25103C17.7619 3.26003 19.251 3.93105 20.287 5.19305C21.564 6.75005 22.0419 9.14904 21.5639 11.61C20.3319 17.966 12.635 21.5361 12.308 21.6841C12.211 21.7281 12.105 21.7501 11.999 21.7501ZM8.04098 4.75005C8.03498 4.75005 8.03002 4.75005 8.02402 4.75005C6.68602 4.75605 5.62604 5.22503 4.87204 6.14403C3.87304 7.36103 3.51202 9.29705 3.90402 11.323C4.85902 16.247 10.592 19.447 11.999 20.165C13.406 19.447 19.139 16.247 20.093 11.323C20.487 9.29605 20.126 7.36003 19.129 6.14403C18.375 5.22603 17.315 4.75803 15.974 4.75103C15.968 4.75103 15.962 4.75103 15.957 4.75103C13.585 4.75103 12.7441 7.12806 12.7101 7.22906C12.6061 7.53206 12.32 7.73803 12 7.73803C11.998 7.73803 11.9969 7.73803 11.9959 7.73803C11.6749 7.73703 11.389 7.53204 11.287 7.22704C11.254 7.12704 10.412 4.75005 8.04098 4.75005Z"
                                    fill="#00657B"
                                  />
                                </svg>
                              )}
                            </a>
                            {/* } */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
              {/* {centerData.map((elem) =>
                                        <div className="cu-search-result-item">
                                            <div className="cu-search-result-card">
                                                <div className="cu-search-result-img" onClick={() => handleDetail(elem?.id)} style={{ cursor: "pointer" }}>
                                                    <img src={elem?.center_image[0]} style={{ objectFit: "cover", height: "305px" }} alt="Home" className="img-fluid" />
                                                </div>
                                                <div className="cu-search-result-body">
                                                    <div className="cu-browse-home-heading">
                                                        <a onClick={() => handleDetail(elem?.id)} style={{ cursor: "pointer" }} className="cu-browse-home-title">{elem?.center_name}</a>
                                                    </div>
                                                    <p className="cu-browse-home-description">{elem?.description.length > 55 ? elem?.description?.substring(0, 55) + '...' : elem?.description}
                                                        <br />
                                                    </p>
                                                    <p className="cu-browse-home-info">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M10.0004 11.1917C10.3418 11.1917 10.6799 11.1244 10.9954 10.9938C11.3108 10.8631 11.5974 10.6716 11.8389 10.4301C12.0803 10.1887 12.2718 9.90209 12.4025 9.58665C12.5331 9.2712 12.6004 8.93311 12.6004 8.59167C12.6004 8.25023 12.5331 7.91214 12.4025 7.59669C12.2718 7.28125 12.0803 6.99462 11.8389 6.75319C11.5974 6.51176 11.3108 6.32024 10.9954 6.18958C10.6799 6.05892 10.3418 5.99167 10.0004 5.99167C9.31083 5.99167 8.64951 6.2656 8.16191 6.75319C7.67432 7.24079 7.40039 7.90211 7.40039 8.59167C7.40039 9.28123 7.67432 9.94255 8.16191 10.4301C8.64951 10.9177 9.31083 11.1917 10.0004 11.1917Z"
                                                                stroke="#B2B2B2" stroke-width="1.5" />
                                                            <path
                                                                d="M3.01675 7.07501C4.65842 -0.14166 15.3501 -0.133326 16.9834 7.08334C17.9418 11.3167 15.3084 14.9 13.0001 17.1167C12.1935 17.8946 11.1165 18.3293 9.99592 18.3293C8.87529 18.3293 7.79835 17.8946 6.99175 17.1167C4.69175 14.9 2.05842 11.3083 3.01675 7.07501Z"
                                                                stroke="#B2B2B2" stroke-width="1.5" />
                                                        </svg>
                                                        <span>{elem?.address?.address}</span>
                                                    </p>
                                                    <p className="cu-browse-home-info">
                                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.09961 16.775V5.22501" stroke="#B2B2B2" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M1.09961 14.575H20.8996" stroke="#B2B2B2" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M20.9004 14.575V16.775" stroke="#B2B2B2" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                            <path
                                                                d="M20.7623 14.575C20.8382 14.575 20.8998 14.5134 20.8998 14.4375V12.375C20.8998 9.94494 18.9299 7.97501 16.4998 7.97501H9.3498C9.04604 7.97501 8.7998 8.22124 8.7998 8.52501V14.4375C8.7998 14.5134 8.86138 14.575 8.9373 14.575"
                                                                stroke="#B2B2B2" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path
                                                                d="M4.06591 9.62501C4.6554 9.28467 5.38167 9.28467 5.97116 9.62501C6.56065 9.96535 6.92379 10.5943 6.92379 11.275C6.92379 11.9557 6.56065 12.5847 5.97116 12.925C5.38167 13.2653 4.6554 13.2653 4.06591 12.925C3.47642 12.5847 3.11328 11.9557 3.11328 11.275C3.11328 10.5943 3.47642 9.96535 4.06591 9.62501Z"
                                                                stroke="#B2B2B2" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </svg>
                                                        <span>{elem?.No_of_bed}</span>
                                                    </p>
                                                    <p className="cu-browse-home-time">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_443_5160)">
                                                                <path
                                                                    d="M18.3337 9.99999C18.3337 14.6 14.6003 18.3333 10.0003 18.3333C5.40033 18.3333 1.66699 14.6 1.66699 9.99999C1.66699 5.39999 5.40033 1.66666 10.0003 1.66666C14.6003 1.66666 18.3337 5.39999 18.3337 9.99999Z"
                                                                    stroke="#00657B" stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round" />
                                                                <path
                                                                    d="M13.0914 12.65L10.5081 11.1083C10.0581 10.8417 9.69141 10.2 9.69141 9.675V6.25833"
                                                                    stroke="#00657B" stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_443_5160">
                                                                    <rect width="20" height="20" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        <span>{elem?.timing} to {elem?.end_timing}</span>
                                                    </p>
                                                    <p className="cu-browse-home-rate">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M14.7171 18.8917C14.2754 18.8917 13.7088 18.75 13.0004 18.3333L10.5088 16.8583C10.2504 16.7083 9.75042 16.7083 9.50042 16.8583L7.00042 18.3333C5.52542 19.2083 4.65876 18.8583 4.26709 18.575C3.88376 18.2917 3.28376 17.5667 3.67542 15.9L4.26709 13.3417C4.33376 13.075 4.20042 12.6167 4.00042 12.4167L1.93376 10.35C0.900424 9.31667 0.983757 8.43334 1.12542 8.00001C1.26709 7.56667 1.71709 6.80001 3.15042 6.55834L5.80876 6.11667C6.05876 6.07501 6.41709 5.80834 6.52542 5.58334L8.00042 2.64167C8.66709 1.30001 9.54209 1.10001 10.0004 1.10001C10.4588 1.10001 11.3338 1.30001 12.0004 2.64167L13.4671 5.57501C13.5838 5.80001 13.9421 6.06667 14.1921 6.10834L16.8504 6.55001C18.2921 6.79167 18.7421 7.55834 18.8754 7.99167C19.0088 8.42501 19.0921 9.30834 18.0671 10.3417L16.0004 12.4167C15.8004 12.6167 15.6754 13.0667 15.7338 13.3417L16.3254 15.9C16.7088 17.5667 16.1171 18.2917 15.7338 18.575C15.5254 18.725 15.1921 18.8917 14.7171 18.8917ZM10.0004 15.4917C10.4088 15.4917 10.8171 15.5917 11.1421 15.7833L13.6338 17.2583C14.3588 17.6917 14.8171 17.6917 14.9921 17.5667C15.1671 17.4417 15.2921 17 15.1088 16.1833L14.5171 13.625C14.3588 12.9333 14.6171 12.0417 15.1171 11.5333L17.1838 9.46667C17.5921 9.05834 17.7754 8.65834 17.6921 8.38334C17.6004 8.10834 17.2171 7.88334 16.6504 7.79167L13.9921 7.35001C13.3504 7.24167 12.6504 6.72501 12.3588 6.14167L10.8921 3.20834C10.6254 2.67501 10.2921 2.35834 10.0004 2.35834C9.70876 2.35834 9.37542 2.67501 9.11709 3.20834L7.64209 6.14167C7.35042 6.72501 6.65042 7.24167 6.00876 7.35001L3.35876 7.79167C2.79209 7.88334 2.40876 8.10834 2.31709 8.38334C2.22542 8.65834 2.41709 9.06667 2.82542 9.46667L4.89209 11.5333C5.39209 12.0333 5.65042 12.9333 5.49209 13.625L4.90042 16.1833C4.70876 17.0083 4.84209 17.4417 5.01709 17.5667C5.19209 17.6917 5.64209 17.6833 6.37542 17.2583L8.86709 15.7833C9.18376 15.5917 9.59209 15.4917 10.0004 15.4917Z"
                                                                fill="white" />
                                                        </svg>
                                                        <span>0/5</span>
                                                    </p>
                                                    <div className="cu-search-result-share">
                                                        <div className="cu-search-result-share-item">
                                                            <a onClick={() => handleSend(elem?.id)} style={{ cursor: "pointer" }} className="cu-search-result-share-link">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M18.001 14.25C16.643 14.25 15.462 14.983 14.804 16.068L9.4729 13.403C9.6489 12.969 9.75098 12.497 9.75098 12C9.75098 11.503 9.6489 11.031 9.4729 10.597L14.804 7.93201C15.462 9.01601 16.643 9.75 18.001 9.75C20.068 9.75 21.751 8.068 21.751 6C21.751 3.932 20.068 2.25 18.001 2.25C15.934 2.25 14.251 3.932 14.251 6C14.251 6.173 14.279 6.33901 14.302 6.50601L8.63696 9.33801C7.95896 8.66601 7.028 8.25 6 8.25C3.933 8.25 2.25 9.932 2.25 12C2.25 14.068 3.933 15.75 6 15.75C7.027 15.75 7.95896 15.333 8.63696 14.662L14.302 17.494C14.279 17.661 14.251 17.827 14.251 18C14.251 20.068 15.934 21.75 18.001 21.75C20.068 21.75 21.751 20.068 21.751 18C21.751 15.932 20.068 14.25 18.001 14.25ZM18.001 3.75C19.241 3.75 20.251 4.759 20.251 6C20.251 7.241 19.241 8.25 18.001 8.25C16.761 8.25 15.751 7.241 15.751 6C15.751 4.759 16.761 3.75 18.001 3.75ZM6.00098 14.25C4.76098 14.25 3.75098 13.241 3.75098 12C3.75098 10.759 4.76098 9.75 6.00098 9.75C7.24098 9.75 8.25098 10.759 8.25098 12C8.25098 13.241 7.24098 14.25 6.00098 14.25ZM18.001 20.25C16.761 20.25 15.751 19.241 15.751 18C15.751 16.759 16.761 15.75 18.001 15.75C19.241 15.75 20.251 16.759 20.251 18C20.251 19.241 19.241 20.25 18.001 20.25Z"
                                                                        fill="#00657B" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <div className="cu-search-result-share-item">
                                                            <a href="#" className="cu-search-result-share-link">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M11.999 21.7501C11.893 21.7501 11.787 21.728 11.688 21.683C11.361 21.534 3.665 17.9651 2.431 11.6091C1.954 9.15006 2.433 6.75105 3.71201 5.19305C4.747 3.93105 6.23496 3.26003 8.01596 3.25103C8.02496 3.25103 8.03396 3.25103 8.04196 3.25103C10.074 3.25103 11.313 4.40806 11.998 5.39306C12.686 4.40406 13.9349 3.24203 15.9799 3.25103C17.7619 3.26003 19.251 3.93105 20.287 5.19305C21.564 6.75005 22.0419 9.14904 21.5639 11.61C20.3319 17.966 12.635 21.5361 12.308 21.6841C12.211 21.7281 12.105 21.7501 11.999 21.7501ZM8.04098 4.75005C8.03498 4.75005 8.03002 4.75005 8.02402 4.75005C6.68602 4.75605 5.62604 5.22503 4.87204 6.14403C3.87304 7.36103 3.51202 9.29705 3.90402 11.323C4.85902 16.247 10.592 19.447 11.999 20.165C13.406 19.447 19.139 16.247 20.093 11.323C20.487 9.29605 20.126 7.36003 19.129 6.14403C18.375 5.22603 17.315 4.75803 15.974 4.75103C15.968 4.75103 15.962 4.75103 15.957 4.75103C13.585 4.75103 12.7441 7.12806 12.7101 7.22906C12.6061 7.53206 12.32 7.73803 12 7.73803C11.998 7.73803 11.9969 7.73803 11.9959 7.73803C11.6749 7.73703 11.389 7.53204 11.287 7.22704C11.254 7.12704 10.412 4.75005 8.04098 4.75005Z"
                                                                        fill="#00657B" />
                                                                </svg>
                                                            

                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )} */}
            </div>
          </div>
          <div className="cu-search-result-map" id="map" ref={mapRef}>
            {/* <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d472371.2178719699!2d-88.23581538477832!3d41.85678137690042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2c3cd0f4cbed%3A0xafe0a6ad09c0c000!2sChicago%2C%20IL%2C%20USA!5e0!3m2!1sen!2sin!4v1690798036043!5m2!1sen!2sin"
                            style={{ 'border': '0' }} allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe> */}
          </div>
        </div>
      </main>

      <Modal
        backdrop="static"
        className="cu-delete-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Body>
          <div className="cu-thank-you-content">
            <div className="cu-delete-icon">
              <img src="/assets/images/logo.png" />
            </div>
            <h3 className="cu-thank-you-title" style={{ fontSize: "21px" }}>
              You need to sign up or log in. Once you sign up or log in, you can
              view all details.
            </h3>
            <div className="cu-modal-btn">
              <Button variant="primary mx-3" onClick={() => nav("/login")}>
                Login
              </Button>
              <Button
                variant="info"
                onClick={handleClose}
                style={{ backgroundColor: "#78797A", borderColor: "#78797A" }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        backdrop="static"
        className="cu-delete-modal"
        show={shareModalShow}
        onHide={handleClose}
        centered
      >
        <Modal.Body>
          <div className="cu-thank-you-content">
            <div className="cu-delete-icon">
              <img src="/assets/images/logo.png" />
            </div>
            <h3 className="cu-thank-you-title" style={{ fontSize: "21px" }}>
              Email to Your Friends
            </h3>
            <hr />
          </div>
          <div>
            <form className="row">
              <div className="col-12 mb-4">
                <label htmlFor="inputUsername" className="form-label" >Sender Name</label>
                <input type="text" className="form-control" id="input_sender_name" name='sender_name' onChange={handleChangeWithoutNumber} value={shareModalDetails.sender_name} />
                <div className='error'>{error?.sender_name_err}</div>
              </div>
              <div className="col-12 mb-4">
                <label htmlFor="inputUsername" className="form-label" >Sender Email</label>
                <input type="text" className="form-control" id="input_sender_email" name='sender_email' onChange={handleChange} value={shareModalDetails.sender_email} />
                <div className='error'>{error?.sender_email_err}</div>
              </div>
              <div className="col-12 mb-4">
                <label htmlFor="inputUsername" className="form-label" >Receivers</label>
                <input type="text" className="form-control" id="input_receivers" name='receivers' placeholder="Separate emails with a comma" onChange={handleChange} value={shareModalDetails.receivers} />
                <div className='error'>{error?.receivers_err}</div>
              </div>
              <div className="col-12 mb-4">
                <label htmlFor="input_message" className="form-label" >Message</label>
                <textarea className="form-control" id="input_message" name='message' onChange={handleChange}>{shareModalDetails.message}</textarea>
              </div>
            </form>
            <hr />

            <div className="cu-modal-btn">
              <Button variant="primary mx-3" onClick={handleShare} disabled={disable} >
                {disable ? "Processing..." : "Send"}
              </Button>
              <Button
                variant="info"
                onClick={handleShareModalClose}
                style={{ backgroundColor: "#78797A", borderColor: "#78797A" }}
                disabled={disable}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SerachResult;
