import React, { useEffect, useState, useRef, useMemo } from 'react'
import { toast, Toaster } from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CONTIGO_API_URL } from '../BaseURL';

import zipData from '../zip_code.json'
import cityData from '../city.json'
import Skeleton from 'react-loading-skeleton';
import { Button, Modal } from 'react-bootstrap';
import Footer from '../layout/Footer';
import Header from '../layout/Header';

const Index = () => {

    useEffect(() => {
        getFeaturedFacilities();
        getCenterData();
    }, [])

    const nav = useNavigate();

    const [contact, setContact] = useState({ name: "", email: "", message: "" });
    const [address, setAddress] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [user, SetUser] = useState({ city: "", zip: "" })
    const [postion, setPosition] = useState({ lat: null, lng: null })

    const google = window.google;
    const mapRef = useRef(null);
    const [searchResults, setSearchResults] = useState([]);
    const [searchResultsTwo, setSearchResultsTwo] = useState([]);

    // const courseData = useMemo(() => {
    //     let computedPlan  = cityList
    //     if (search) {
    //         computedPlan = computedPlan.filter(
    //             (course) =>

    //         );
    // })

    const [error, setError] = useState({})
    const [disable, setDisable] = useState(false);
    const location = useLocation()

    const aboutRef = useRef(null);
    const howRef = useRef(null);
    const service = useRef(null);
    const contactRef = useRef(null);
    const homeRef = useRef(null);

    const navigate = useNavigate();
    const isLoggedIn = localStorage.getItem("CONTIGO_TOKEN_PATIENT");
    const [loading, setLoading] = useState(true);
    const [loadingCards, setLoadingCards] = useState([1, 2, 3, 4, 5, 6, 7, 8]);
    const [centerData, setCenterData] = useState([]);
    const [centerData2, setCenterData2] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        const defaultLocation = { lat: 36.778259, lng: -119.417931 };
        let locationToFocus = null; // Store the location to focus on
        let zoomLevel = 6;

        if (centerData2.length === 1) {
            const center = centerData2[0];

            if (center.address.lat && center.address.lng) {
                locationToFocus = { lat: center.address.lat, lng: center.address.lng };
                zoomLevel = 13; // Adjust the zoom level as needed
            } else if (center.address.zipcode) {
                const geocoder = new window.google.maps.Geocoder();


                geocoder.geocode(
                    { address: center.address.zipcode },
                    (results, status) => {
                        if (status === "OK" && results.length > 0) {
                            locationToFocus = {
                                lat: results[0].geometry.location.lat(),
                                lng: results[0].geometry.location.lng(),
                            };
                            map.setCenter(locationToFocus);
                            map.setZoom(zoomLevel);
                        }
                    }
                );
            }
        }

        const map = new window.google.maps.Map(mapRef.current, {
            center: locationToFocus || defaultLocation,
            zoom: zoomLevel,
        });

        centerData2.forEach((center) => {
            const marker = new window.google.maps.Marker({
                position: { lat: center.address.lat, lng: center.address.lng },
                map: map,
                title: center.center_name,
            });
            console.log("center.address", center.address)

            const infoWindow = new window.google.maps.InfoWindow({
                content: `<div>${center.center_name}<br><strong>${center.address.address}</strong></div>`,
            });

            marker.addListener("click", () => {
                infoWindow.open(map, marker);
            });
        });
    }, [centerData2]);

    const handleClose = () => setShow(false)

    const getFeaturedFacilities = () => {
        axios({
            method: 'get',
            url: `${CONTIGO_API_URL}api/patient-user/featured-facilities`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: isLoggedIn ?
                    ("Bearer " + localStorage.getItem("CONTIGO_TOKEN_PATIENT")) : '',
            },
        }).then((response) => {
            console.log("@@", response?.data);
            setCenterData(response?.data?.data);
            setLoading(false);
        })
            .catch((error) => {
                console.log(error, "err");
                toast.error(error?.response?.data?.message);
                setLoading(false);
                if (error?.response?.status === 401) {
                    localStorage.removeItem("CONTIGO_TOKEN_PATIENT");
                    nav("/login");
                }
            });
    }

    const getCenterData = () => {
        const fd = new URLSearchParams();
        fd.append("city", "San Francisco");

        axios({
            method: 'post',
            url: `${CONTIGO_API_URL}api/patient-user/search-center`,
            data: fd,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: isLoggedIn ?
                    ("Bearer " + localStorage.getItem("CONTIGO_TOKEN_PATIENT")) : '',
            },
        }).then((response) => {
            console.log("@@", response?.data);
            setCenterData2(response?.data?.data);
            setLoading(false);
        })
            .catch((error) => {
                console.log(error, "err");
                toast.error(error?.response?.data?.message);
                setLoading(false);
                if (error?.response?.status === 401) {
                    localStorage.removeItem("CONTIGO_TOKEN_PATIENT");
                    nav("/login");
                }
            });
    }

    // search zip and city
    const handleSearch = () => {
        if (validation()) {
            const finaData = { add: address, zip: zipcode }

            localStorage.setItem("SEARCH_RESULT", JSON.stringify(finaData))
            navigate('/search-result', { state: { add: address, zip: zipcode } })
        }
    }

    const handleLogout = () => {
        localStorage.removeItem('CONTIGO_TOKEN_PATIENT');
        toast.success("Logout Successful..!");
        setTimeout(() => {
            nav('/')
        }, 1000);
    }

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setAddress(inputValue);

        if (inputValue) {
            const filteredResults = cityData.filter(city =>
                city.toUpperCase().includes(inputValue.toUpperCase())
            );
            setSearchResults(filteredResults);
        } else {
            setSearchResults([]);
        }
    };

    const handleResultClick = (result) => {
        setAddress(result);
        setSearchResults([]); // Clear search results after selection
    };

    const handleResultClickTwo = (data) => {
        setZipcode(data);
        setSearchResultsTwo([])
    }

    const handleInput = (e) => {
        const inputVal = e.target.value;

        setZipcode(inputVal)
        if (inputVal) {
            const filteredResults = zipData.filter(zip => zip.toString().includes(inputVal));
            setSearchResultsTwo(filteredResults);
        } else {
            setSearchResultsTwo([]);
        }
    }

    const validation = () => {
        var isValid = true;
        let err = {};

        if (!address && !zipcode) {
            isValid = false;
            err['common_err'] = "Please enter either address or zip code";
        }

        setError(err);
        return isValid;
    }

    const handleChangeWithoutNumber = (e) => {
        const sanitizedValue = e.target.value.replace(/[^a-zA-Z\s]/g, '');
        setContact({ ...contact, [e.target.name]: sanitizedValue?.trimStart() })
    }




    // google map

    // useEffect(() => {
    //     const map = new google.maps.Map(mapRef.current, {
    //         center: { lat: 36.778259, lng: -119.417931 },
    //         zoom: 17,
    //         mapTypeControlOptions: {
    //             position: google.maps.ControlPosition.BOTTOM_RIGHT
    //         }
    //     });

    //     const marker = new google.maps.Marker({
    //         position: { lat: 36.778259, lng: -119.417931 },
    //         map: map,
    //         title: 'California'
    //     });

    //     map.panTo(marker.getPosition());

    //     const searchBox = new google.maps.places.SearchBox(
    //         document.getElementById("pac-input")
    //     );

    //     searchBox.addListener("places_changed", () => {
    //         const places = searchBox.getPlaces();
    //         if (places.length === 0) return;

    //         const bounds = new google.maps.LatLngBounds();
    //         places.forEach((place) => {
    //             if (!place.geometry) return;

    //             if (place.geometry.viewport) {
    //                 bounds.union(place.geometry.viewport);
    //             } else {
    //                 bounds.extend(place.geometry.location);
    //             }
    //         });

    //         map.fitBounds(bounds);
    //         setAddress(places[0].formatted_address);
    //         handleGeocode(places[0].formatted_address, map);
    //     });
    // }, []);

    // const handleGeocode = (adr, map) => {
    //     const geocoder = new window.google.maps.Geocoder();
    //     geocoder.geocode({ address: adr }, (results, status) => {
    //         if (status === 'OK') {
    //             setPosition({
    //                 lat: results[0].geometry.location.lat(),
    //                 lng: results[0].geometry.location.lng(),
    //             });

    //             const marker = new google.maps.Marker({
    //                 position: { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() },
    //                 map: map,
    //                 title: 'California'
    //             });

    //             map.panTo(marker.getPosition());
    //             updateLatLong(results[0].geometry.location.lat(), results[0].geometry.location.lng());
    //         } else {
    //             console.log('Geocode was not successful for the following reason: ' + status);
    //         }
    //     });
    // };

    // function updateLatLong(lat, lng) {
    //     SetUser(prevAddInfo => ({
    //         ...prevAddInfo,
    //         lat: lat,
    //         lng: lng
    //     }));
    // }

    const handleChangeCity = (e) => {
        SetUser({ ...user, [e.target.name]: e.target.value })
    }


    const smoothScrollToRef = (ref) => {
        ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };


    useEffect(() => {
        const getRef = localStorage.getItem("INPUT_DATA")
        if (getRef) {
            setTimeout(() => {
                if (getRef == "howRef") {
                    smoothScrollToRef(howRef)
                }
                if (getRef == "aboutRef") {
                    smoothScrollToRef(aboutRef)
                }
                if (getRef == "contactRef") {
                    smoothScrollToRef(contactRef)
                }
                if (getRef == "service") {
                    smoothScrollToRef(service)
                }
                localStorage.removeItem("INPUT_DATA")
            }, 100);
        }
    }, [])
    const scrollToTop = (ref) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }


    const handleContact = (e) => {
        e.preventDefault();
        if (validate()) {
            setDisable(true)
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('email', contact.email);
            bodyFormData.append('name', contact.name);
            bodyFormData.append('message', contact.message);
            bodyFormData.append('status', "2");
            console.log(bodyFormData, "body")

            axios({
                method: 'post',
                url: `${CONTIGO_API_URL}api/patient-user/add-inquiry`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then((response) => {
                console.log(response.data, "@@$$")
                toast.success("Thanks for contacting us.")
                setContact({ name: "", email: "", message: "" })
                setDisable(false)
            }).catch((error) => {
                console.log("error", error);
                toast.error(error?.response?.data?.message)
                setDisable(false)
            })
        }
    }

    const handleDetail = (id) => {
        if (localStorage.getItem("CONTIGO_TOKEN_PATIENT")) {
            nav("/details", { state: id });
            // window.location.reload();
            localStorage.setItem("LOCATION_ID", id);
        } else {
            setShow(true);
            // nav('/login');
        }
    };

    const handleChange = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value?.trimStart() })
    }


    const validate = () => {
        let errors = {};
        for (let key in contact) {
            if (!contact[key]) {
                errors[key + '_err'] = 'Please enter ' + key.replace(/_/g, " ");
                // toast.error('Please enter ' + key.replace(/_/g, " "), { style: { background: '#333', color: '#fff' } })
            }
            else if (key === "email") {
                if (typeof contact[key] !== "undefined") {
                    let lastAtPos = contact[key].lastIndexOf('@');
                    let lastDotPos = contact[key].lastIndexOf('.');
                    if (!(lastAtPos < lastDotPos && lastAtPos > 0 && contact[key].indexOf('@@') === -1 && lastDotPos > 2 && (contact[key].length - lastDotPos) > 2)) {
                        errors['email_err'] = "Email is not valid";
                        // toast.error('Email is not valid', { style: { background: '#333', color: '#fff' } })
                    }
                }
            }
        }
        setError(errors)
        console.log(errors, "errors")
        return (Object.keys(errors).length > 0) ? false : true;
    }


    // useEffect(() => {

    //     if (localStorage.getItem('CONTIGO_TOKEN_PATIENT')) {
    //         toast.success("Already Logged-In")
    //         nav('/dashboard')
    //     }
    // }, [])

    const handleLike = (id, e) => {
        e?.preventDefault();
        const myurl = `${CONTIGO_API_URL}api/patient-user/center-like`;
        const bodyFormData = new URLSearchParams();
        bodyFormData.append("center_id", id);

        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization:
                    "Bearer " + localStorage.getItem("CONTIGO_TOKEN_PATIENT"),
            },
        })
            .then((response) => {
                console.log(response?.data);
                if (response?.data?.success === true) {
                    console.log(response?.data, "$$");
                    // setDisable(true)
                    getFeaturedFacilities();
                } else {
                    toast.error(response.data.message);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error, "error");
                toast.error(error.response?.data?.message);
                setLoading(false);
                if (error?.response?.status === 401) {
                    localStorage.removeItem("CONTIGO_TOKEN_PATIENT");
                    nav("/login");
                }
            });
    };

    return (
        <>
            <body className="cu-site">
                {/* <!-- Site Header Start --> */}
                <Header
                    isLoggedIn={isLoggedIn}
                    smoothScrollToRef={smoothScrollToRef}
                    handleLogout={handleLogout}
                    homeRef={homeRef}
                    aboutRef={aboutRef}
                    howRef={howRef}
                    service={service}
                    contactRef={contactRef}
                    value={"Home"}
                />
                {/* <!-- Site Header End --> */}

                {/* <!-- Site Content Start --> */}
                <main className="cu-site-content">
                    {/* <!-- Landing Section Start --> */}
                    <section className="cu-landing-section">
                        <div className="container">
                            <h1 className="cu-section-title">Connecting Patients to Inpatient Rehab</h1>
                            <div className="cu-landing-search">
                                <div className="cu-input-icon-group">
                                    <input
                                        type="search"
                                        id="pac-input"
                                        value={address}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        placeholder="Search City , State"
                                    />
                                    {searchResults.length > 0 && (
                                        <ul className="dropdown-menu" style={{ marginLeft: "25px" }}>
                                            {searchResults.map((result, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => handleResultClick(result)}
                                                    className="dropdown-item"
                                                >
                                                    {result}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    <span className="cu-input-icon">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M22 22L20 20M11.5 21C12.7476 21 13.9829 20.7543 15.1355 20.2769C16.2881 19.7994 17.3354 19.0997 18.2175 18.2175C19.0997 17.3354 19.7994 16.2881 20.2769 15.1355C20.7543 13.9829 21 12.7476 21 11.5C21 10.2524 20.7543 9.0171 20.2769 7.86451C19.7994 6.71191 19.0997 5.66464 18.2175 4.78249C17.3354 3.90033 16.2881 3.20056 15.1355 2.72314C13.9829 2.24572 12.7476 2 11.5 2C8.98044 2 6.56408 3.00089 4.78249 4.78249C3.00089 6.56408 2 8.98044 2 11.5C2 14.0196 3.00089 16.4359 4.78249 18.2175C6.56408 19.9991 8.98044 21 11.5 21Z"
                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                    </span>

                                </div>
                                <div className="cu-input-icon-group">
                                    {/* <input type="text" className="form-control" placeholder="Zipcode" /> */}
                                    <input
                                        type="search"
                                        value={zipcode}
                                        onChange={handleInput}
                                        className="form-control"
                                        placeholder="Zipcode"
                                    // name='zipcode'
                                    />
                                    {searchResultsTwo.length > 0 && (
                                        <ul className="dropdown-menu zip-code">
                                            {searchResultsTwo.map((elem, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => handleResultClickTwo(elem)}
                                                    className="dropdown-item"
                                                >
                                                    {elem}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    <span className="cu-input-icon">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.9999 13.4299C12.4096 13.4299 12.8153 13.3492 13.1939 13.1924C13.5724 13.0357 13.9163 12.8058 14.2061 12.5161C14.4958 12.2264 14.7256 11.8824 14.8824 11.5039C15.0392 11.1254 15.1199 10.7197 15.1199 10.3099C15.1199 9.90022 15.0392 9.49451 14.8824 9.11597C14.7256 8.73743 14.4958 8.39349 14.2061 8.10377C13.9163 7.81405 13.5724 7.58423 13.1939 7.42744C12.8153 7.27064 12.4096 7.18994 11.9999 7.18994C11.1724 7.18994 10.3788 7.51865 9.79371 8.10377C9.2086 8.68888 8.87988 9.48247 8.87988 10.3099C8.87988 11.1374 9.2086 11.931 9.79371 12.5161C10.3788 13.1012 11.1724 13.4299 11.9999 13.4299Z"
                                                stroke="#B2B2B2" stroke-width="1.5" />
                                            <path
                                                d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C14.6318 21.4735 13.3395 21.9952 11.9947 21.9952C10.65 21.9952 9.35763 21.4735 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z"
                                                stroke="#B2B2B2" stroke-width="1.5" />
                                        </svg>
                                    </span>
                                    <span className="cu-input-icon cu-current-location-icon">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 19.5C13.9891 19.5 15.8968 18.7098 17.3033 17.3033C18.7098 15.8968 19.5 13.9891 19.5 12C19.5 10.0109 18.7098 8.10322 17.3033 6.6967C15.8968 5.29018 13.9891 4.5 12 4.5C10.0109 4.5 8.10322 5.29018 6.6967 6.6967C5.29018 8.10322 4.5 10.0109 4.5 12C4.5 13.9891 5.29018 15.8968 6.6967 17.3033C8.10322 18.7098 10.0109 19.5 12 19.5Z"
                                                stroke="#00657B" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            <path
                                                d="M12 4V2M4 12H2M12 20V22M20 12H22M12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15Z"
                                                stroke="#00657B" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                    </span>
                                </div>
                                <button type="button" className="input-group-text btn btn-primary"
                                    //  onClick={() => nav('/search-result')}
                                    onClick={handleSearch}
                                    id="basic-addon2">Search</button>
                            </div>
                        </div>
                        <div className="cu-landing-img">
                            <img src="assets/images/landing-img.png" alt="Landing Image" className="img-fluid" />
                        </div>
                    </section>
                    {/* <!-- Landing Section End --> */}

                    {/* <!-- Facilities Section Start --> */}
                    <section className="cu-facilities-section"
                        // id="services"
                        ref={service}
                    >
                        <div className="container">
                            <div className="cu-section-heading">
                                <h3 className="cu-section-subtitle">Post Acute Care Facilities</h3>
                                <h2 className="cu-section-title">Featured Facilities</h2>
                            </div>
                            <div className="cu-facilities-content">
                                <div className="row">
                                    {!loading ? centerData?.map((el) =>
                                        <div className="col col-12 col-md-6 col-lg-4 col-xl-3">
                                            <div className="cu-facilities-card">
                                                <div className="cu-facilities-card-img">
                                                    <img src={el?.center_image && el?.center_image[0]} alt="facilities Image" className="img-fluid" />
                                                    {isLoggedIn && <span className="cu-facilities-card-like" onClick={() => handleLike(el?.id)}>
                                                        {el?.like ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M12 21.652C11.69 21.652 11.39 21.612 11.14 21.522C7.32 20.212 1.25 15.562 1.25 8.69198C1.25 5.19198 4.08 2.35198 7.56 2.35198C9.25 2.35198 10.83 3.01198 12 4.19198C12.5808 3.60585 13.2726 3.14126 14.0349 2.82535C14.7972 2.50943 15.6148 2.34852 16.44 2.35198C19.92 2.35198 22.75 5.20198 22.75 8.69198C22.75 15.572 16.68 20.212 12.86 21.522C12.61 21.612 12.31 21.652 12 21.652ZM7.56 3.85198C4.91 3.85198 2.75 6.02198 2.75 8.69198C2.75 15.522 9.32 19.322 11.63 20.112C11.81 20.172 12.2 20.172 12.38 20.112C14.68 19.322 21.26 15.532 21.26 8.69198C21.26 6.02198 19.1 3.85198 16.45 3.85198C14.93 3.85198 13.52 4.56198 12.61 5.79198C12.33 6.17198 11.69 6.17198 11.41 5.79198C10.9656 5.18906 10.3855 4.69925 9.71668 4.36221C9.04781 4.02518 8.30898 3.85038 7.56 3.85198Z"
                                                                fill="white" />
                                                        </svg> : <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M14.44 0C12.63 0 11.01 0.88 10 2.23C9.48413 1.53881 8.81426 0.977391 8.04353 0.590294C7.27281 0.203198 6.42247 0.00108555 5.56 0C2.49 0 0 2.5 0 5.59C0 6.78 0.19 7.88 0.52 8.9C2.1 13.9 6.97 16.89 9.38 17.71C9.72 17.83 10.28 17.83 10.62 17.71C13.03 16.89 17.9 13.9 19.48 8.9C19.81 7.88 20 6.78 20 5.59C20 2.5 17.51 0 14.44 0Z" fill="white" />
                                                        </svg>}
                                                    </span>}
                                                    <span className="cu-facilities-card-ratting">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M14.7171 18.8918C14.2754 18.8918 13.7088 18.7501 13.0004 18.3334L10.5088 16.8584C10.2504 16.7084 9.75042 16.7084 9.50042 16.8584L7.00042 18.3334C5.52542 19.2084 4.65876 18.8584 4.26709 18.5751C3.88376 18.2918 3.28376 17.5668 3.67542 15.9001L4.26709 13.3418C4.33376 13.0751 4.20042 12.6168 4.00042 12.4168L1.93376 10.3501C0.900424 9.31676 0.983757 8.43343 1.12542 8.0001C1.26709 7.56677 1.71709 6.8001 3.15042 6.55843L5.80876 6.11676C6.05876 6.0751 6.41709 5.80843 6.52542 5.58343L8.00042 2.64176C8.66709 1.3001 9.54209 1.1001 10.0004 1.1001C10.4588 1.1001 11.3338 1.3001 12.0004 2.64176L13.4671 5.5751C13.5838 5.8001 13.9421 6.06676 14.1921 6.10843L16.8504 6.5501C18.2921 6.79176 18.7421 7.55843 18.8754 7.99177C19.0088 8.4251 19.0921 9.30843 18.0671 10.3418L16.0004 12.4168C15.8004 12.6168 15.6754 13.0668 15.7338 13.3418L16.3254 15.9001C16.7088 17.5668 16.1171 18.2918 15.7338 18.5751C15.5254 18.7251 15.1921 18.8918 14.7171 18.8918ZM10.0004 15.4918C10.4088 15.4918 10.8171 15.5918 11.1421 15.7834L13.6338 17.2584C14.3588 17.6918 14.8171 17.6918 14.9921 17.5668C15.1671 17.4418 15.2921 17.0001 15.1088 16.1834L14.5171 13.6251C14.3588 12.9334 14.6171 12.0418 15.1171 11.5334L17.1838 9.46676C17.5921 9.05843 17.7754 8.65843 17.6921 8.38343C17.6004 8.10843 17.2171 7.88343 16.6504 7.79177L13.9921 7.3501C13.3504 7.24176 12.6504 6.7251 12.3588 6.14176L10.8921 3.20843C10.6254 2.6751 10.2921 2.35843 10.0004 2.35843C9.70876 2.35843 9.37542 2.6751 9.11709 3.20843L7.64209 6.14176C7.35042 6.7251 6.65042 7.24176 6.00876 7.3501L3.35876 7.79177C2.79209 7.88343 2.40876 8.10843 2.31709 8.38343C2.22542 8.65843 2.41709 9.06676 2.82542 9.46676L4.89209 11.5334C5.39209 12.0334 5.65042 12.9334 5.49209 13.6251L4.90042 16.1834C4.70876 17.0084 4.84209 17.4418 5.01709 17.5668C5.19209 17.6918 5.64209 17.6834 6.37542 17.2584L8.86709 15.7834C9.18376 15.5918 9.59209 15.4918 10.0004 15.4918Z"
                                                                fill="white" />
                                                        </svg>
                                                        <span>{Math.round(el?.center_overall_rating)}/5</span>
                                                    </span>
                                                </div>
                                                <div className="cu-facilities-card-body">
                                                    <a onClick={() => handleDetail(el?.id)}
                                                        style={{ cursor: "pointer" }} className="cu-facilities-card-link">{el?.center_name}</a>
                                                </div>
                                                <p className="cu-facilities-card-location" style={{ padding: "0 18px" }}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.0004 11.1917C10.3418 11.1917 10.6799 11.1244 10.9954 10.9938C11.3108 10.8631 11.5974 10.6716 11.8389 10.4302C12.0803 10.1887 12.2718 9.90212 12.4025 9.58668C12.5331 9.27123 12.6004 8.93314 12.6004 8.5917C12.6004 8.25026 12.5331 7.91217 12.4025 7.59672C12.2718 7.28128 12.0803 6.99465 11.8389 6.75322C11.5974 6.51179 11.3108 6.32027 10.9954 6.18961C10.6799 6.05895 10.3418 5.9917 10.0004 5.9917C9.31083 5.9917 8.64951 6.26563 8.16191 6.75322C7.67432 7.24082 7.40039 7.90214 7.40039 8.5917C7.40039 9.28126 7.67432 9.94258 8.16191 10.4302C8.64951 10.9178 9.31083 11.1917 10.0004 11.1917Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" />
                                                        <path
                                                            d="M3.01675 7.07496C4.65842 -0.141705 15.3501 -0.133372 16.9834 7.08329C17.9418 11.3166 15.3084 14.9 13.0001 17.1166C12.1935 17.8946 11.1165 18.3293 9.99592 18.3293C8.87529 18.3293 7.79835 17.8946 6.99175 17.1166C4.69175 14.9 2.05842 11.3083 3.01675 7.07496Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" />
                                                    </svg>
                                                    <span>{el?.address?.address.length > 40
                                                        ? el?.address?.address.substring(0, 40) + "..."
                                                        : (el?.address?.address + " , " + el?.address?.zip)}</span>
                                                </p>
                                                <div className="cu-facilities-card-footer">
                                                    <p className="cu-facilities-footer-detail">
                                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.09961 16.775V5.22498" stroke="#7D7D7D" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M1.09961 14.575H20.8996" stroke="#7D7D7D" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M20.9004 14.575V16.775" stroke="#7D7D7D" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                            <path
                                                                d="M20.7623 14.575C20.8382 14.575 20.8998 14.5134 20.8998 14.4375V12.375C20.8998 9.94491 18.9299 7.97498 16.4998 7.97498H9.3498C9.04604 7.97498 8.7998 8.22121 8.7998 8.52498V14.4375C8.7998 14.5134 8.86138 14.575 8.9373 14.575"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path
                                                                d="M4.06591 9.62501C4.6554 9.28467 5.38167 9.28467 5.97116 9.62501C6.56065 9.96535 6.92379 10.5943 6.92379 11.275C6.92379 11.9557 6.56065 12.5847 5.97116 12.925C5.38167 13.2653 4.6554 13.2653 4.06591 12.925C3.47642 12.5847 3.11328 11.9557 3.11328 11.275C3.11328 10.5943 3.47642 9.96535 4.06591 9.62501Z"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </svg>
                                                        <span>{el?.No_of_bed}</span>
                                                    </p>
                                                    <p className="cu-facilities-footer-detail">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_526_1932)">
                                                                <path
                                                                    d="M18.3337 9.99996C18.3337 14.6 14.6003 18.3333 10.0003 18.3333C5.40033 18.3333 1.66699 14.6 1.66699 9.99996C1.66699 5.39996 5.40033 1.66663 10.0003 1.66663C14.6003 1.66663 18.3337 5.39996 18.3337 9.99996Z"
                                                                    stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round" />
                                                                <path
                                                                    d="M13.0914 12.65L10.5081 11.1083C10.0581 10.8416 9.69141 10.2 9.69141 9.67497V6.2583"
                                                                    stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_526_1932">
                                                                    <rect width="20" height="20" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        <span>{el?.timing} to {el?.end_timing}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>) :
                                        loadingCards?.map((el) =>
                                            <div className="col col-12 col-md-6 col-lg-4 col-xl-3">
                                                <div className="cu-facilities-card">
                                                    <div className="cu-facilities-card-img">
                                                        <Skeleton height="200px" count={1} className='img-fluid' />
                                                    </div>
                                                    <div className="cu-facilities-card-body">
                                                        <Skeleton count={1} height="20px" className='mb-3' />
                                                        <p className="cu-facilities-card-location">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M10.0004 11.1917C10.3418 11.1917 10.6799 11.1244 10.9954 10.9938C11.3108 10.8631 11.5974 10.6716 11.8389 10.4302C12.0803 10.1887 12.2718 9.90212 12.4025 9.58668C12.5331 9.27123 12.6004 8.93314 12.6004 8.5917C12.6004 8.25026 12.5331 7.91217 12.4025 7.59672C12.2718 7.28128 12.0803 6.99465 11.8389 6.75322C11.5974 6.51179 11.3108 6.32027 10.9954 6.18961C10.6799 6.05895 10.3418 5.9917 10.0004 5.9917C9.31083 5.9917 8.64951 6.26563 8.16191 6.75322C7.67432 7.24082 7.40039 7.90214 7.40039 8.5917C7.40039 9.28126 7.67432 9.94258 8.16191 10.4302C8.64951 10.9178 9.31083 11.1917 10.0004 11.1917Z"
                                                                    stroke="#7D7D7D" stroke-width="1.5" />
                                                                <path
                                                                    d="M3.01675 7.07496C4.65842 -0.141705 15.3501 -0.133372 16.9834 7.08329C17.9418 11.3166 15.3084 14.9 13.0001 17.1166C12.1935 17.8946 11.1165 18.3293 9.99592 18.3293C8.87529 18.3293 7.79835 17.8946 6.99175 17.1166C4.69175 14.9 2.05842 11.3083 3.01675 7.07496Z"
                                                                    stroke="#7D7D7D" stroke-width="1.5" />
                                                            </svg>
                                                            <Skeleton count={1} height="15px" width="200px" />
                                                        </p>
                                                    </div>
                                                    <div className="cu-facilities-card-footer">
                                                        <p className="cu-facilities-footer-detail">
                                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1.09961 16.775V5.22498" stroke="#7D7D7D" stroke-width="1.5"
                                                                    stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M1.09961 14.575H20.8996" stroke="#7D7D7D" stroke-width="1.5"
                                                                    stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M20.9004 14.575V16.775" stroke="#7D7D7D" stroke-width="1.5"
                                                                    stroke-linecap="round" stroke-linejoin="round" />
                                                                <path
                                                                    d="M20.7623 14.575C20.8382 14.575 20.8998 14.5134 20.8998 14.4375V12.375C20.8998 9.94491 18.9299 7.97498 16.4998 7.97498H9.3498C9.04604 7.97498 8.7998 8.22121 8.7998 8.52498V14.4375C8.7998 14.5134 8.86138 14.575 8.9373 14.575"
                                                                    stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round" />
                                                                <path
                                                                    d="M4.06591 9.62501C4.6554 9.28467 5.38167 9.28467 5.97116 9.62501C6.56065 9.96535 6.92379 10.5943 6.92379 11.275C6.92379 11.9557 6.56065 12.5847 5.97116 12.925C5.38167 13.2653 4.6554 13.2653 4.06591 12.925C3.47642 12.5847 3.11328 11.9557 3.11328 11.275C3.11328 10.5943 3.47642 9.96535 4.06591 9.62501Z"
                                                                    stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round" />
                                                            </svg>
                                                            <Skeleton count={1} height="15px" width="30px" />
                                                        </p>
                                                        <p className="cu-facilities-footer-detail">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0_526_1932)">
                                                                    <path
                                                                        d="M18.3337 9.99996C18.3337 14.6 14.6003 18.3333 10.0003 18.3333C5.40033 18.3333 1.66699 14.6 1.66699 9.99996C1.66699 5.39996 5.40033 1.66663 10.0003 1.66663C14.6003 1.66663 18.3337 5.39996 18.3337 9.99996Z"
                                                                        stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                        stroke-linejoin="round" />
                                                                    <path
                                                                        d="M13.0914 12.65L10.5081 11.1083C10.0581 10.8416 9.69141 10.2 9.69141 9.67497V6.2583"
                                                                        stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                        stroke-linejoin="round" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_526_1932">
                                                                        <rect width="20" height="20" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            <Skeleton count={1} height="15px" width="100px" />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    {/* <div className="col col-12 col-md-6 col-lg-4 col-xl-3">
                                        <div className="cu-facilities-card">
                                            <div className="cu-facilities-card-img">
                                                <img src="assets/images/facilities2.png" alt="facilities Image" className="img-fluid" />
                                                <span className="cu-facilities-card-like">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12 21.652C11.69 21.652 11.39 21.612 11.14 21.522C7.32 20.212 1.25 15.562 1.25 8.69198C1.25 5.19198 4.08 2.35198 7.56 2.35198C9.25 2.35198 10.83 3.01198 12 4.19198C12.5808 3.60585 13.2726 3.14126 14.0349 2.82535C14.7972 2.50943 15.6148 2.34852 16.44 2.35198C19.92 2.35198 22.75 5.20198 22.75 8.69198C22.75 15.572 16.68 20.212 12.86 21.522C12.61 21.612 12.31 21.652 12 21.652ZM7.56 3.85198C4.91 3.85198 2.75 6.02198 2.75 8.69198C2.75 15.522 9.32 19.322 11.63 20.112C11.81 20.172 12.2 20.172 12.38 20.112C14.68 19.322 21.26 15.532 21.26 8.69198C21.26 6.02198 19.1 3.85198 16.45 3.85198C14.93 3.85198 13.52 4.56198 12.61 5.79198C12.33 6.17198 11.69 6.17198 11.41 5.79198C10.9656 5.18906 10.3855 4.69925 9.71668 4.36221C9.04781 4.02518 8.30898 3.85038 7.56 3.85198Z"
                                                            fill="white" />
                                                    </svg>
                                                </span>
                                                <span className="cu-facilities-card-ratting">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M14.7171 18.8918C14.2754 18.8918 13.7088 18.7501 13.0004 18.3334L10.5088 16.8584C10.2504 16.7084 9.75042 16.7084 9.50042 16.8584L7.00042 18.3334C5.52542 19.2084 4.65876 18.8584 4.26709 18.5751C3.88376 18.2918 3.28376 17.5668 3.67542 15.9001L4.26709 13.3418C4.33376 13.0751 4.20042 12.6168 4.00042 12.4168L1.93376 10.3501C0.900424 9.31676 0.983757 8.43343 1.12542 8.0001C1.26709 7.56677 1.71709 6.8001 3.15042 6.55843L5.80876 6.11676C6.05876 6.0751 6.41709 5.80843 6.52542 5.58343L8.00042 2.64176C8.66709 1.3001 9.54209 1.1001 10.0004 1.1001C10.4588 1.1001 11.3338 1.3001 12.0004 2.64176L13.4671 5.5751C13.5838 5.8001 13.9421 6.06676 14.1921 6.10843L16.8504 6.5501C18.2921 6.79176 18.7421 7.55843 18.8754 7.99177C19.0088 8.4251 19.0921 9.30843 18.0671 10.3418L16.0004 12.4168C15.8004 12.6168 15.6754 13.0668 15.7338 13.3418L16.3254 15.9001C16.7088 17.5668 16.1171 18.2918 15.7338 18.5751C15.5254 18.7251 15.1921 18.8918 14.7171 18.8918ZM10.0004 15.4918C10.4088 15.4918 10.8171 15.5918 11.1421 15.7834L13.6338 17.2584C14.3588 17.6918 14.8171 17.6918 14.9921 17.5668C15.1671 17.4418 15.2921 17.0001 15.1088 16.1834L14.5171 13.6251C14.3588 12.9334 14.6171 12.0418 15.1171 11.5334L17.1838 9.46676C17.5921 9.05843 17.7754 8.65843 17.6921 8.38343C17.6004 8.10843 17.2171 7.88343 16.6504 7.79177L13.9921 7.3501C13.3504 7.24176 12.6504 6.7251 12.3588 6.14176L10.8921 3.20843C10.6254 2.6751 10.2921 2.35843 10.0004 2.35843C9.70876 2.35843 9.37542 2.6751 9.11709 3.20843L7.64209 6.14176C7.35042 6.7251 6.65042 7.24176 6.00876 7.3501L3.35876 7.79177C2.79209 7.88343 2.40876 8.10843 2.31709 8.38343C2.22542 8.65843 2.41709 9.06676 2.82542 9.46676L4.89209 11.5334C5.39209 12.0334 5.65042 12.9334 5.49209 13.6251L4.90042 16.1834C4.70876 17.0084 4.84209 17.4418 5.01709 17.5668C5.19209 17.6918 5.64209 17.6834 6.37542 17.2584L8.86709 15.7834C9.18376 15.5918 9.59209 15.4918 10.0004 15.4918Z"
                                                            fill="white" />
                                                    </svg>
                                                    <span>4.6/5</span>
                                                </span>
                                            </div>
                                            <div className="cu-facilities-card-body">
                                                <a href="#" className="cu-facilities-card-link">Ararat Convalescent Hospital</a>
                                                <p className="cu-facilities-card-location">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.0004 11.1917C10.3418 11.1917 10.6799 11.1244 10.9954 10.9938C11.3108 10.8631 11.5974 10.6716 11.8389 10.4302C12.0803 10.1887 12.2718 9.90212 12.4025 9.58668C12.5331 9.27123 12.6004 8.93314 12.6004 8.5917C12.6004 8.25026 12.5331 7.91217 12.4025 7.59672C12.2718 7.28128 12.0803 6.99465 11.8389 6.75322C11.5974 6.51179 11.3108 6.32027 10.9954 6.18961C10.6799 6.05895 10.3418 5.9917 10.0004 5.9917C9.31083 5.9917 8.64951 6.26563 8.16191 6.75322C7.67432 7.24082 7.40039 7.90214 7.40039 8.5917C7.40039 9.28126 7.67432 9.94258 8.16191 10.4302C8.64951 10.9178 9.31083 11.1917 10.0004 11.1917Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" />
                                                        <path
                                                            d="M3.01675 7.07496C4.65842 -0.141705 15.3501 -0.133372 16.9834 7.08329C17.9418 11.3166 15.3084 14.9 13.0001 17.1166C12.1935 17.8946 11.1165 18.3293 9.99592 18.3293C8.87529 18.3293 7.79835 17.8946 6.99175 17.1166C4.69175 14.9 2.05842 11.3083 3.01675 7.07496Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" />
                                                    </svg>
                                                    <span>Ararat Convalescent Hospital</span>
                                                </p>
                                            </div>
                                            <div className="cu-facilities-card-footer">
                                                <p className="cu-facilities-footer-detail">
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.09961 16.775V5.22498" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M1.09961 14.575H20.8996" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M20.9004 14.575V16.775" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path
                                                            d="M20.7623 14.575C20.8382 14.575 20.8998 14.5134 20.8998 14.4375V12.375C20.8998 9.94491 18.9299 7.97498 16.4998 7.97498H9.3498C9.04604 7.97498 8.7998 8.22121 8.7998 8.52498V14.4375C8.7998 14.5134 8.86138 14.575 8.9373 14.575"
                                                            stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                        <path
                                                            d="M4.06591 9.62501C4.6554 9.28467 5.38167 9.28467 5.97116 9.62501C6.56065 9.96535 6.92379 10.5943 6.92379 11.275C6.92379 11.9557 6.56065 12.5847 5.97116 12.925C5.38167 13.2653 4.6554 13.2653 4.06591 12.925C3.47642 12.5847 3.11328 11.9557 3.11328 11.275C3.11328 10.5943 3.47642 9.96535 4.06591 9.62501Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                    <span>23</span>
                                                </p>
                                                <p className="cu-facilities-footer-detail">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_526_1932)">
                                                            <path
                                                                d="M18.3337 9.99996C18.3337 14.6 14.6003 18.3333 10.0003 18.3333C5.40033 18.3333 1.66699 14.6 1.66699 9.99996C1.66699 5.39996 5.40033 1.66663 10.0003 1.66663C14.6003 1.66663 18.3337 5.39996 18.3337 9.99996Z"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path
                                                                d="M13.0914 12.65L10.5081 11.1083C10.0581 10.8416 9.69141 10.2 9.69141 9.67497V6.2583"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_526_1932">
                                                                <rect width="20" height="20" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span>9 AM to 6 PM</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-6 col-lg-4 col-xl-3">
                                        <div className="cu-facilities-card">
                                            <div className="cu-facilities-card-img">
                                                <img src="assets/images/facilities3.png" alt="facilities Image" className="img-fluid" />
                                                <span className="cu-facilities-card-like">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12 21.652C11.69 21.652 11.39 21.612 11.14 21.522C7.32 20.212 1.25 15.562 1.25 8.69198C1.25 5.19198 4.08 2.35198 7.56 2.35198C9.25 2.35198 10.83 3.01198 12 4.19198C12.5808 3.60585 13.2726 3.14126 14.0349 2.82535C14.7972 2.50943 15.6148 2.34852 16.44 2.35198C19.92 2.35198 22.75 5.20198 22.75 8.69198C22.75 15.572 16.68 20.212 12.86 21.522C12.61 21.612 12.31 21.652 12 21.652ZM7.56 3.85198C4.91 3.85198 2.75 6.02198 2.75 8.69198C2.75 15.522 9.32 19.322 11.63 20.112C11.81 20.172 12.2 20.172 12.38 20.112C14.68 19.322 21.26 15.532 21.26 8.69198C21.26 6.02198 19.1 3.85198 16.45 3.85198C14.93 3.85198 13.52 4.56198 12.61 5.79198C12.33 6.17198 11.69 6.17198 11.41 5.79198C10.9656 5.18906 10.3855 4.69925 9.71668 4.36221C9.04781 4.02518 8.30898 3.85038 7.56 3.85198Z"
                                                            fill="white" />
                                                    </svg>
                                                </span>
                                                <span className="cu-facilities-card-ratting">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M14.7171 18.8918C14.2754 18.8918 13.7088 18.7501 13.0004 18.3334L10.5088 16.8584C10.2504 16.7084 9.75042 16.7084 9.50042 16.8584L7.00042 18.3334C5.52542 19.2084 4.65876 18.8584 4.26709 18.5751C3.88376 18.2918 3.28376 17.5668 3.67542 15.9001L4.26709 13.3418C4.33376 13.0751 4.20042 12.6168 4.00042 12.4168L1.93376 10.3501C0.900424 9.31676 0.983757 8.43343 1.12542 8.0001C1.26709 7.56677 1.71709 6.8001 3.15042 6.55843L5.80876 6.11676C6.05876 6.0751 6.41709 5.80843 6.52542 5.58343L8.00042 2.64176C8.66709 1.3001 9.54209 1.1001 10.0004 1.1001C10.4588 1.1001 11.3338 1.3001 12.0004 2.64176L13.4671 5.5751C13.5838 5.8001 13.9421 6.06676 14.1921 6.10843L16.8504 6.5501C18.2921 6.79176 18.7421 7.55843 18.8754 7.99177C19.0088 8.4251 19.0921 9.30843 18.0671 10.3418L16.0004 12.4168C15.8004 12.6168 15.6754 13.0668 15.7338 13.3418L16.3254 15.9001C16.7088 17.5668 16.1171 18.2918 15.7338 18.5751C15.5254 18.7251 15.1921 18.8918 14.7171 18.8918ZM10.0004 15.4918C10.4088 15.4918 10.8171 15.5918 11.1421 15.7834L13.6338 17.2584C14.3588 17.6918 14.8171 17.6918 14.9921 17.5668C15.1671 17.4418 15.2921 17.0001 15.1088 16.1834L14.5171 13.6251C14.3588 12.9334 14.6171 12.0418 15.1171 11.5334L17.1838 9.46676C17.5921 9.05843 17.7754 8.65843 17.6921 8.38343C17.6004 8.10843 17.2171 7.88343 16.6504 7.79177L13.9921 7.3501C13.3504 7.24176 12.6504 6.7251 12.3588 6.14176L10.8921 3.20843C10.6254 2.6751 10.2921 2.35843 10.0004 2.35843C9.70876 2.35843 9.37542 2.6751 9.11709 3.20843L7.64209 6.14176C7.35042 6.7251 6.65042 7.24176 6.00876 7.3501L3.35876 7.79177C2.79209 7.88343 2.40876 8.10843 2.31709 8.38343C2.22542 8.65843 2.41709 9.06676 2.82542 9.46676L4.89209 11.5334C5.39209 12.0334 5.65042 12.9334 5.49209 13.6251L4.90042 16.1834C4.70876 17.0084 4.84209 17.4418 5.01709 17.5668C5.19209 17.6918 5.64209 17.6834 6.37542 17.2584L8.86709 15.7834C9.18376 15.5918 9.59209 15.4918 10.0004 15.4918Z"
                                                            fill="white" />
                                                    </svg>
                                                    <span>4.6/5</span>
                                                </span>
                                            </div>
                                            <div className="cu-facilities-card-body">
                                                <a href="#" className="cu-facilities-card-link">Ararat Convalescent Hospital</a>
                                                <p className="cu-facilities-card-location">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.0004 11.1917C10.3418 11.1917 10.6799 11.1244 10.9954 10.9938C11.3108 10.8631 11.5974 10.6716 11.8389 10.4302C12.0803 10.1887 12.2718 9.90212 12.4025 9.58668C12.5331 9.27123 12.6004 8.93314 12.6004 8.5917C12.6004 8.25026 12.5331 7.91217 12.4025 7.59672C12.2718 7.28128 12.0803 6.99465 11.8389 6.75322C11.5974 6.51179 11.3108 6.32027 10.9954 6.18961C10.6799 6.05895 10.3418 5.9917 10.0004 5.9917C9.31083 5.9917 8.64951 6.26563 8.16191 6.75322C7.67432 7.24082 7.40039 7.90214 7.40039 8.5917C7.40039 9.28126 7.67432 9.94258 8.16191 10.4302C8.64951 10.9178 9.31083 11.1917 10.0004 11.1917Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" />
                                                        <path
                                                            d="M3.01675 7.07496C4.65842 -0.141705 15.3501 -0.133372 16.9834 7.08329C17.9418 11.3166 15.3084 14.9 13.0001 17.1166C12.1935 17.8946 11.1165 18.3293 9.99592 18.3293C8.87529 18.3293 7.79835 17.8946 6.99175 17.1166C4.69175 14.9 2.05842 11.3083 3.01675 7.07496Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" />
                                                    </svg>
                                                    <span>Ararat Convalescent Hospital</span>
                                                </p>
                                            </div>
                                            <div className="cu-facilities-card-footer">
                                                <p className="cu-facilities-footer-detail">
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.09961 16.775V5.22498" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M1.09961 14.575H20.8996" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M20.9004 14.575V16.775" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path
                                                            d="M20.7623 14.575C20.8382 14.575 20.8998 14.5134 20.8998 14.4375V12.375C20.8998 9.94491 18.9299 7.97498 16.4998 7.97498H9.3498C9.04604 7.97498 8.7998 8.22121 8.7998 8.52498V14.4375C8.7998 14.5134 8.86138 14.575 8.9373 14.575"
                                                            stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                        <path
                                                            d="M4.06591 9.62501C4.6554 9.28467 5.38167 9.28467 5.97116 9.62501C6.56065 9.96535 6.92379 10.5943 6.92379 11.275C6.92379 11.9557 6.56065 12.5847 5.97116 12.925C5.38167 13.2653 4.6554 13.2653 4.06591 12.925C3.47642 12.5847 3.11328 11.9557 3.11328 11.275C3.11328 10.5943 3.47642 9.96535 4.06591 9.62501Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                    <span>23</span>
                                                </p>
                                                <p className="cu-facilities-footer-detail">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_526_1932)">
                                                            <path
                                                                d="M18.3337 9.99996C18.3337 14.6 14.6003 18.3333 10.0003 18.3333C5.40033 18.3333 1.66699 14.6 1.66699 9.99996C1.66699 5.39996 5.40033 1.66663 10.0003 1.66663C14.6003 1.66663 18.3337 5.39996 18.3337 9.99996Z"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path
                                                                d="M13.0914 12.65L10.5081 11.1083C10.0581 10.8416 9.69141 10.2 9.69141 9.67497V6.2583"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_526_1932">
                                                                <rect width="20" height="20" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span>9 AM to 6 PM</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-6 col-lg-4 col-xl-3">
                                        <div className="cu-facilities-card">
                                            <div className="cu-facilities-card-img">
                                                <img src="assets/images/facilities4.png" alt="facilities Image" className="img-fluid" />
                                                <span className="cu-facilities-card-like">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12 21.652C11.69 21.652 11.39 21.612 11.14 21.522C7.32 20.212 1.25 15.562 1.25 8.69198C1.25 5.19198 4.08 2.35198 7.56 2.35198C9.25 2.35198 10.83 3.01198 12 4.19198C12.5808 3.60585 13.2726 3.14126 14.0349 2.82535C14.7972 2.50943 15.6148 2.34852 16.44 2.35198C19.92 2.35198 22.75 5.20198 22.75 8.69198C22.75 15.572 16.68 20.212 12.86 21.522C12.61 21.612 12.31 21.652 12 21.652ZM7.56 3.85198C4.91 3.85198 2.75 6.02198 2.75 8.69198C2.75 15.522 9.32 19.322 11.63 20.112C11.81 20.172 12.2 20.172 12.38 20.112C14.68 19.322 21.26 15.532 21.26 8.69198C21.26 6.02198 19.1 3.85198 16.45 3.85198C14.93 3.85198 13.52 4.56198 12.61 5.79198C12.33 6.17198 11.69 6.17198 11.41 5.79198C10.9656 5.18906 10.3855 4.69925 9.71668 4.36221C9.04781 4.02518 8.30898 3.85038 7.56 3.85198Z"
                                                            fill="white" />
                                                    </svg>
                                                </span>
                                                <span className="cu-facilities-card-ratting">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M14.7171 18.8918C14.2754 18.8918 13.7088 18.7501 13.0004 18.3334L10.5088 16.8584C10.2504 16.7084 9.75042 16.7084 9.50042 16.8584L7.00042 18.3334C5.52542 19.2084 4.65876 18.8584 4.26709 18.5751C3.88376 18.2918 3.28376 17.5668 3.67542 15.9001L4.26709 13.3418C4.33376 13.0751 4.20042 12.6168 4.00042 12.4168L1.93376 10.3501C0.900424 9.31676 0.983757 8.43343 1.12542 8.0001C1.26709 7.56677 1.71709 6.8001 3.15042 6.55843L5.80876 6.11676C6.05876 6.0751 6.41709 5.80843 6.52542 5.58343L8.00042 2.64176C8.66709 1.3001 9.54209 1.1001 10.0004 1.1001C10.4588 1.1001 11.3338 1.3001 12.0004 2.64176L13.4671 5.5751C13.5838 5.8001 13.9421 6.06676 14.1921 6.10843L16.8504 6.5501C18.2921 6.79176 18.7421 7.55843 18.8754 7.99177C19.0088 8.4251 19.0921 9.30843 18.0671 10.3418L16.0004 12.4168C15.8004 12.6168 15.6754 13.0668 15.7338 13.3418L16.3254 15.9001C16.7088 17.5668 16.1171 18.2918 15.7338 18.5751C15.5254 18.7251 15.1921 18.8918 14.7171 18.8918ZM10.0004 15.4918C10.4088 15.4918 10.8171 15.5918 11.1421 15.7834L13.6338 17.2584C14.3588 17.6918 14.8171 17.6918 14.9921 17.5668C15.1671 17.4418 15.2921 17.0001 15.1088 16.1834L14.5171 13.6251C14.3588 12.9334 14.6171 12.0418 15.1171 11.5334L17.1838 9.46676C17.5921 9.05843 17.7754 8.65843 17.6921 8.38343C17.6004 8.10843 17.2171 7.88343 16.6504 7.79177L13.9921 7.3501C13.3504 7.24176 12.6504 6.7251 12.3588 6.14176L10.8921 3.20843C10.6254 2.6751 10.2921 2.35843 10.0004 2.35843C9.70876 2.35843 9.37542 2.6751 9.11709 3.20843L7.64209 6.14176C7.35042 6.7251 6.65042 7.24176 6.00876 7.3501L3.35876 7.79177C2.79209 7.88343 2.40876 8.10843 2.31709 8.38343C2.22542 8.65843 2.41709 9.06676 2.82542 9.46676L4.89209 11.5334C5.39209 12.0334 5.65042 12.9334 5.49209 13.6251L4.90042 16.1834C4.70876 17.0084 4.84209 17.4418 5.01709 17.5668C5.19209 17.6918 5.64209 17.6834 6.37542 17.2584L8.86709 15.7834C9.18376 15.5918 9.59209 15.4918 10.0004 15.4918Z"
                                                            fill="white" />
                                                    </svg>
                                                    <span>4.6/5</span>
                                                </span>
                                            </div>
                                            <div className="cu-facilities-card-body">
                                                <a href="#" className="cu-facilities-card-link">Ararat Convalescent Hospital</a>
                                                <p className="cu-facilities-card-location">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.0004 11.1917C10.3418 11.1917 10.6799 11.1244 10.9954 10.9938C11.3108 10.8631 11.5974 10.6716 11.8389 10.4302C12.0803 10.1887 12.2718 9.90212 12.4025 9.58668C12.5331 9.27123 12.6004 8.93314 12.6004 8.5917C12.6004 8.25026 12.5331 7.91217 12.4025 7.59672C12.2718 7.28128 12.0803 6.99465 11.8389 6.75322C11.5974 6.51179 11.3108 6.32027 10.9954 6.18961C10.6799 6.05895 10.3418 5.9917 10.0004 5.9917C9.31083 5.9917 8.64951 6.26563 8.16191 6.75322C7.67432 7.24082 7.40039 7.90214 7.40039 8.5917C7.40039 9.28126 7.67432 9.94258 8.16191 10.4302C8.64951 10.9178 9.31083 11.1917 10.0004 11.1917Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" />
                                                        <path
                                                            d="M3.01675 7.07496C4.65842 -0.141705 15.3501 -0.133372 16.9834 7.08329C17.9418 11.3166 15.3084 14.9 13.0001 17.1166C12.1935 17.8946 11.1165 18.3293 9.99592 18.3293C8.87529 18.3293 7.79835 17.8946 6.99175 17.1166C4.69175 14.9 2.05842 11.3083 3.01675 7.07496Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" />
                                                    </svg>
                                                    <span>Ararat Convalescent Hospital</span>
                                                </p>
                                            </div>
                                            <div className="cu-facilities-card-footer">
                                                <p className="cu-facilities-footer-detail">
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.09961 16.775V5.22498" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M1.09961 14.575H20.8996" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M20.9004 14.575V16.775" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path
                                                            d="M20.7623 14.575C20.8382 14.575 20.8998 14.5134 20.8998 14.4375V12.375C20.8998 9.94491 18.9299 7.97498 16.4998 7.97498H9.3498C9.04604 7.97498 8.7998 8.22121 8.7998 8.52498V14.4375C8.7998 14.5134 8.86138 14.575 8.9373 14.575"
                                                            stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                        <path
                                                            d="M4.06591 9.62501C4.6554 9.28467 5.38167 9.28467 5.97116 9.62501C6.56065 9.96535 6.92379 10.5943 6.92379 11.275C6.92379 11.9557 6.56065 12.5847 5.97116 12.925C5.38167 13.2653 4.6554 13.2653 4.06591 12.925C3.47642 12.5847 3.11328 11.9557 3.11328 11.275C3.11328 10.5943 3.47642 9.96535 4.06591 9.62501Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                    <span>23</span>
                                                </p>
                                                <p className="cu-facilities-footer-detail">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_526_1932)">
                                                            <path
                                                                d="M18.3337 9.99996C18.3337 14.6 14.6003 18.3333 10.0003 18.3333C5.40033 18.3333 1.66699 14.6 1.66699 9.99996C1.66699 5.39996 5.40033 1.66663 10.0003 1.66663C14.6003 1.66663 18.3337 5.39996 18.3337 9.99996Z"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path
                                                                d="M13.0914 12.65L10.5081 11.1083C10.0581 10.8416 9.69141 10.2 9.69141 9.67497V6.2583"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_526_1932">
                                                                <rect width="20" height="20" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span>9 AM to 6 PM</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-6 col-lg-4 col-xl-3">
                                        <div className="cu-facilities-card">
                                            <div className="cu-facilities-card-img">
                                                <img src="assets/images/facilities1.png" alt="facilities Image" className="img-fluid" />
                                                <span className="cu-facilities-card-like">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12 21.652C11.69 21.652 11.39 21.612 11.14 21.522C7.32 20.212 1.25 15.562 1.25 8.69198C1.25 5.19198 4.08 2.35198 7.56 2.35198C9.25 2.35198 10.83 3.01198 12 4.19198C12.5808 3.60585 13.2726 3.14126 14.0349 2.82535C14.7972 2.50943 15.6148 2.34852 16.44 2.35198C19.92 2.35198 22.75 5.20198 22.75 8.69198C22.75 15.572 16.68 20.212 12.86 21.522C12.61 21.612 12.31 21.652 12 21.652ZM7.56 3.85198C4.91 3.85198 2.75 6.02198 2.75 8.69198C2.75 15.522 9.32 19.322 11.63 20.112C11.81 20.172 12.2 20.172 12.38 20.112C14.68 19.322 21.26 15.532 21.26 8.69198C21.26 6.02198 19.1 3.85198 16.45 3.85198C14.93 3.85198 13.52 4.56198 12.61 5.79198C12.33 6.17198 11.69 6.17198 11.41 5.79198C10.9656 5.18906 10.3855 4.69925 9.71668 4.36221C9.04781 4.02518 8.30898 3.85038 7.56 3.85198Z"
                                                            fill="white" />
                                                    </svg>
                                                </span>
                                                <span className="cu-facilities-card-ratting">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M14.7171 18.8918C14.2754 18.8918 13.7088 18.7501 13.0004 18.3334L10.5088 16.8584C10.2504 16.7084 9.75042 16.7084 9.50042 16.8584L7.00042 18.3334C5.52542 19.2084 4.65876 18.8584 4.26709 18.5751C3.88376 18.2918 3.28376 17.5668 3.67542 15.9001L4.26709 13.3418C4.33376 13.0751 4.20042 12.6168 4.00042 12.4168L1.93376 10.3501C0.900424 9.31676 0.983757 8.43343 1.12542 8.0001C1.26709 7.56677 1.71709 6.8001 3.15042 6.55843L5.80876 6.11676C6.05876 6.0751 6.41709 5.80843 6.52542 5.58343L8.00042 2.64176C8.66709 1.3001 9.54209 1.1001 10.0004 1.1001C10.4588 1.1001 11.3338 1.3001 12.0004 2.64176L13.4671 5.5751C13.5838 5.8001 13.9421 6.06676 14.1921 6.10843L16.8504 6.5501C18.2921 6.79176 18.7421 7.55843 18.8754 7.99177C19.0088 8.4251 19.0921 9.30843 18.0671 10.3418L16.0004 12.4168C15.8004 12.6168 15.6754 13.0668 15.7338 13.3418L16.3254 15.9001C16.7088 17.5668 16.1171 18.2918 15.7338 18.5751C15.5254 18.7251 15.1921 18.8918 14.7171 18.8918ZM10.0004 15.4918C10.4088 15.4918 10.8171 15.5918 11.1421 15.7834L13.6338 17.2584C14.3588 17.6918 14.8171 17.6918 14.9921 17.5668C15.1671 17.4418 15.2921 17.0001 15.1088 16.1834L14.5171 13.6251C14.3588 12.9334 14.6171 12.0418 15.1171 11.5334L17.1838 9.46676C17.5921 9.05843 17.7754 8.65843 17.6921 8.38343C17.6004 8.10843 17.2171 7.88343 16.6504 7.79177L13.9921 7.3501C13.3504 7.24176 12.6504 6.7251 12.3588 6.14176L10.8921 3.20843C10.6254 2.6751 10.2921 2.35843 10.0004 2.35843C9.70876 2.35843 9.37542 2.6751 9.11709 3.20843L7.64209 6.14176C7.35042 6.7251 6.65042 7.24176 6.00876 7.3501L3.35876 7.79177C2.79209 7.88343 2.40876 8.10843 2.31709 8.38343C2.22542 8.65843 2.41709 9.06676 2.82542 9.46676L4.89209 11.5334C5.39209 12.0334 5.65042 12.9334 5.49209 13.6251L4.90042 16.1834C4.70876 17.0084 4.84209 17.4418 5.01709 17.5668C5.19209 17.6918 5.64209 17.6834 6.37542 17.2584L8.86709 15.7834C9.18376 15.5918 9.59209 15.4918 10.0004 15.4918Z"
                                                            fill="white" />
                                                    </svg>
                                                    <span>4.6/5</span>
                                                </span>
                                            </div>
                                            <div className="cu-facilities-card-body">
                                                <a href="#" className="cu-facilities-card-link">Ararat Convalescent Hospital</a>
                                                <p className="cu-facilities-card-location">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.0004 11.1917C10.3418 11.1917 10.6799 11.1244 10.9954 10.9938C11.3108 10.8631 11.5974 10.6716 11.8389 10.4302C12.0803 10.1887 12.2718 9.90212 12.4025 9.58668C12.5331 9.27123 12.6004 8.93314 12.6004 8.5917C12.6004 8.25026 12.5331 7.91217 12.4025 7.59672C12.2718 7.28128 12.0803 6.99465 11.8389 6.75322C11.5974 6.51179 11.3108 6.32027 10.9954 6.18961C10.6799 6.05895 10.3418 5.9917 10.0004 5.9917C9.31083 5.9917 8.64951 6.26563 8.16191 6.75322C7.67432 7.24082 7.40039 7.90214 7.40039 8.5917C7.40039 9.28126 7.67432 9.94258 8.16191 10.4302C8.64951 10.9178 9.31083 11.1917 10.0004 11.1917Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" />
                                                        <path
                                                            d="M3.01675 7.07496C4.65842 -0.141705 15.3501 -0.133372 16.9834 7.08329C17.9418 11.3166 15.3084 14.9 13.0001 17.1166C12.1935 17.8946 11.1165 18.3293 9.99592 18.3293C8.87529 18.3293 7.79835 17.8946 6.99175 17.1166C4.69175 14.9 2.05842 11.3083 3.01675 7.07496Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" />
                                                    </svg>
                                                    <span>Ararat Convalescent Hospital</span>
                                                </p>
                                            </div>
                                            <div className="cu-facilities-card-footer">
                                                <p className="cu-facilities-footer-detail">
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.09961 16.775V5.22498" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M1.09961 14.575H20.8996" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M20.9004 14.575V16.775" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path
                                                            d="M20.7623 14.575C20.8382 14.575 20.8998 14.5134 20.8998 14.4375V12.375C20.8998 9.94491 18.9299 7.97498 16.4998 7.97498H9.3498C9.04604 7.97498 8.7998 8.22121 8.7998 8.52498V14.4375C8.7998 14.5134 8.86138 14.575 8.9373 14.575"
                                                            stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                        <path
                                                            d="M4.06591 9.62501C4.6554 9.28467 5.38167 9.28467 5.97116 9.62501C6.56065 9.96535 6.92379 10.5943 6.92379 11.275C6.92379 11.9557 6.56065 12.5847 5.97116 12.925C5.38167 13.2653 4.6554 13.2653 4.06591 12.925C3.47642 12.5847 3.11328 11.9557 3.11328 11.275C3.11328 10.5943 3.47642 9.96535 4.06591 9.62501Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                    <span>23</span>
                                                </p>
                                                <p className="cu-facilities-footer-detail">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_526_1932)">
                                                            <path
                                                                d="M18.3337 9.99996C18.3337 14.6 14.6003 18.3333 10.0003 18.3333C5.40033 18.3333 1.66699 14.6 1.66699 9.99996C1.66699 5.39996 5.40033 1.66663 10.0003 1.66663C14.6003 1.66663 18.3337 5.39996 18.3337 9.99996Z"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path
                                                                d="M13.0914 12.65L10.5081 11.1083C10.0581 10.8416 9.69141 10.2 9.69141 9.67497V6.2583"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_526_1932">
                                                                <rect width="20" height="20" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span>9 AM to 6 PM</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-6 col-lg-4 col-xl-3">
                                        <div className="cu-facilities-card">
                                            <div className="cu-facilities-card-img">
                                                <img src="assets/images/facilities2.png" alt="facilities Image" className="img-fluid" />
                                                <span className="cu-facilities-card-like">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12 21.652C11.69 21.652 11.39 21.612 11.14 21.522C7.32 20.212 1.25 15.562 1.25 8.69198C1.25 5.19198 4.08 2.35198 7.56 2.35198C9.25 2.35198 10.83 3.01198 12 4.19198C12.5808 3.60585 13.2726 3.14126 14.0349 2.82535C14.7972 2.50943 15.6148 2.34852 16.44 2.35198C19.92 2.35198 22.75 5.20198 22.75 8.69198C22.75 15.572 16.68 20.212 12.86 21.522C12.61 21.612 12.31 21.652 12 21.652ZM7.56 3.85198C4.91 3.85198 2.75 6.02198 2.75 8.69198C2.75 15.522 9.32 19.322 11.63 20.112C11.81 20.172 12.2 20.172 12.38 20.112C14.68 19.322 21.26 15.532 21.26 8.69198C21.26 6.02198 19.1 3.85198 16.45 3.85198C14.93 3.85198 13.52 4.56198 12.61 5.79198C12.33 6.17198 11.69 6.17198 11.41 5.79198C10.9656 5.18906 10.3855 4.69925 9.71668 4.36221C9.04781 4.02518 8.30898 3.85038 7.56 3.85198Z"
                                                            fill="white" />
                                                    </svg>
                                                </span>
                                                <span className="cu-facilities-card-ratting">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M14.7171 18.8918C14.2754 18.8918 13.7088 18.7501 13.0004 18.3334L10.5088 16.8584C10.2504 16.7084 9.75042 16.7084 9.50042 16.8584L7.00042 18.3334C5.52542 19.2084 4.65876 18.8584 4.26709 18.5751C3.88376 18.2918 3.28376 17.5668 3.67542 15.9001L4.26709 13.3418C4.33376 13.0751 4.20042 12.6168 4.00042 12.4168L1.93376 10.3501C0.900424 9.31676 0.983757 8.43343 1.12542 8.0001C1.26709 7.56677 1.71709 6.8001 3.15042 6.55843L5.80876 6.11676C6.05876 6.0751 6.41709 5.80843 6.52542 5.58343L8.00042 2.64176C8.66709 1.3001 9.54209 1.1001 10.0004 1.1001C10.4588 1.1001 11.3338 1.3001 12.0004 2.64176L13.4671 5.5751C13.5838 5.8001 13.9421 6.06676 14.1921 6.10843L16.8504 6.5501C18.2921 6.79176 18.7421 7.55843 18.8754 7.99177C19.0088 8.4251 19.0921 9.30843 18.0671 10.3418L16.0004 12.4168C15.8004 12.6168 15.6754 13.0668 15.7338 13.3418L16.3254 15.9001C16.7088 17.5668 16.1171 18.2918 15.7338 18.5751C15.5254 18.7251 15.1921 18.8918 14.7171 18.8918ZM10.0004 15.4918C10.4088 15.4918 10.8171 15.5918 11.1421 15.7834L13.6338 17.2584C14.3588 17.6918 14.8171 17.6918 14.9921 17.5668C15.1671 17.4418 15.2921 17.0001 15.1088 16.1834L14.5171 13.6251C14.3588 12.9334 14.6171 12.0418 15.1171 11.5334L17.1838 9.46676C17.5921 9.05843 17.7754 8.65843 17.6921 8.38343C17.6004 8.10843 17.2171 7.88343 16.6504 7.79177L13.9921 7.3501C13.3504 7.24176 12.6504 6.7251 12.3588 6.14176L10.8921 3.20843C10.6254 2.6751 10.2921 2.35843 10.0004 2.35843C9.70876 2.35843 9.37542 2.6751 9.11709 3.20843L7.64209 6.14176C7.35042 6.7251 6.65042 7.24176 6.00876 7.3501L3.35876 7.79177C2.79209 7.88343 2.40876 8.10843 2.31709 8.38343C2.22542 8.65843 2.41709 9.06676 2.82542 9.46676L4.89209 11.5334C5.39209 12.0334 5.65042 12.9334 5.49209 13.6251L4.90042 16.1834C4.70876 17.0084 4.84209 17.4418 5.01709 17.5668C5.19209 17.6918 5.64209 17.6834 6.37542 17.2584L8.86709 15.7834C9.18376 15.5918 9.59209 15.4918 10.0004 15.4918Z"
                                                            fill="white" />
                                                    </svg>
                                                    <span>4.6/5</span>
                                                </span>
                                            </div>
                                            <div className="cu-facilities-card-body">
                                                <a href="#" className="cu-facilities-card-link">Ararat Convalescent Hospital</a>
                                                <p className="cu-facilities-card-location">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.0004 11.1917C10.3418 11.1917 10.6799 11.1244 10.9954 10.9938C11.3108 10.8631 11.5974 10.6716 11.8389 10.4302C12.0803 10.1887 12.2718 9.90212 12.4025 9.58668C12.5331 9.27123 12.6004 8.93314 12.6004 8.5917C12.6004 8.25026 12.5331 7.91217 12.4025 7.59672C12.2718 7.28128 12.0803 6.99465 11.8389 6.75322C11.5974 6.51179 11.3108 6.32027 10.9954 6.18961C10.6799 6.05895 10.3418 5.9917 10.0004 5.9917C9.31083 5.9917 8.64951 6.26563 8.16191 6.75322C7.67432 7.24082 7.40039 7.90214 7.40039 8.5917C7.40039 9.28126 7.67432 9.94258 8.16191 10.4302C8.64951 10.9178 9.31083 11.1917 10.0004 11.1917Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" />
                                                        <path
                                                            d="M3.01675 7.07496C4.65842 -0.141705 15.3501 -0.133372 16.9834 7.08329C17.9418 11.3166 15.3084 14.9 13.0001 17.1166C12.1935 17.8946 11.1165 18.3293 9.99592 18.3293C8.87529 18.3293 7.79835 17.8946 6.99175 17.1166C4.69175 14.9 2.05842 11.3083 3.01675 7.07496Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" />
                                                    </svg>
                                                    <span>Ararat Convalescent Hospital</span>
                                                </p>
                                            </div>
                                            <div className="cu-facilities-card-footer">
                                                <p className="cu-facilities-footer-detail">
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.09961 16.775V5.22498" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M1.09961 14.575H20.8996" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M20.9004 14.575V16.775" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path
                                                            d="M20.7623 14.575C20.8382 14.575 20.8998 14.5134 20.8998 14.4375V12.375C20.8998 9.94491 18.9299 7.97498 16.4998 7.97498H9.3498C9.04604 7.97498 8.7998 8.22121 8.7998 8.52498V14.4375C8.7998 14.5134 8.86138 14.575 8.9373 14.575"
                                                            stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                        <path
                                                            d="M4.06591 9.62501C4.6554 9.28467 5.38167 9.28467 5.97116 9.62501C6.56065 9.96535 6.92379 10.5943 6.92379 11.275C6.92379 11.9557 6.56065 12.5847 5.97116 12.925C5.38167 13.2653 4.6554 13.2653 4.06591 12.925C3.47642 12.5847 3.11328 11.9557 3.11328 11.275C3.11328 10.5943 3.47642 9.96535 4.06591 9.62501Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                    <span>23</span>
                                                </p>
                                                <p className="cu-facilities-footer-detail">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_526_1932)">
                                                            <path
                                                                d="M18.3337 9.99996C18.3337 14.6 14.6003 18.3333 10.0003 18.3333C5.40033 18.3333 1.66699 14.6 1.66699 9.99996C1.66699 5.39996 5.40033 1.66663 10.0003 1.66663C14.6003 1.66663 18.3337 5.39996 18.3337 9.99996Z"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path
                                                                d="M13.0914 12.65L10.5081 11.1083C10.0581 10.8416 9.69141 10.2 9.69141 9.67497V6.2583"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_526_1932">
                                                                <rect width="20" height="20" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span>9 AM to 6 PM</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-6 col-lg-4 col-xl-3">
                                        <div className="cu-facilities-card">
                                            <div className="cu-facilities-card-img">
                                                <img src="assets/images/facilities3.png" alt="facilities Image" className="img-fluid" />
                                                <span className="cu-facilities-card-like">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12 21.652C11.69 21.652 11.39 21.612 11.14 21.522C7.32 20.212 1.25 15.562 1.25 8.69198C1.25 5.19198 4.08 2.35198 7.56 2.35198C9.25 2.35198 10.83 3.01198 12 4.19198C12.5808 3.60585 13.2726 3.14126 14.0349 2.82535C14.7972 2.50943 15.6148 2.34852 16.44 2.35198C19.92 2.35198 22.75 5.20198 22.75 8.69198C22.75 15.572 16.68 20.212 12.86 21.522C12.61 21.612 12.31 21.652 12 21.652ZM7.56 3.85198C4.91 3.85198 2.75 6.02198 2.75 8.69198C2.75 15.522 9.32 19.322 11.63 20.112C11.81 20.172 12.2 20.172 12.38 20.112C14.68 19.322 21.26 15.532 21.26 8.69198C21.26 6.02198 19.1 3.85198 16.45 3.85198C14.93 3.85198 13.52 4.56198 12.61 5.79198C12.33 6.17198 11.69 6.17198 11.41 5.79198C10.9656 5.18906 10.3855 4.69925 9.71668 4.36221C9.04781 4.02518 8.30898 3.85038 7.56 3.85198Z"
                                                            fill="white" />
                                                    </svg>
                                                </span>
                                                <span className="cu-facilities-card-ratting">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M14.7171 18.8918C14.2754 18.8918 13.7088 18.7501 13.0004 18.3334L10.5088 16.8584C10.2504 16.7084 9.75042 16.7084 9.50042 16.8584L7.00042 18.3334C5.52542 19.2084 4.65876 18.8584 4.26709 18.5751C3.88376 18.2918 3.28376 17.5668 3.67542 15.9001L4.26709 13.3418C4.33376 13.0751 4.20042 12.6168 4.00042 12.4168L1.93376 10.3501C0.900424 9.31676 0.983757 8.43343 1.12542 8.0001C1.26709 7.56677 1.71709 6.8001 3.15042 6.55843L5.80876 6.11676C6.05876 6.0751 6.41709 5.80843 6.52542 5.58343L8.00042 2.64176C8.66709 1.3001 9.54209 1.1001 10.0004 1.1001C10.4588 1.1001 11.3338 1.3001 12.0004 2.64176L13.4671 5.5751C13.5838 5.8001 13.9421 6.06676 14.1921 6.10843L16.8504 6.5501C18.2921 6.79176 18.7421 7.55843 18.8754 7.99177C19.0088 8.4251 19.0921 9.30843 18.0671 10.3418L16.0004 12.4168C15.8004 12.6168 15.6754 13.0668 15.7338 13.3418L16.3254 15.9001C16.7088 17.5668 16.1171 18.2918 15.7338 18.5751C15.5254 18.7251 15.1921 18.8918 14.7171 18.8918ZM10.0004 15.4918C10.4088 15.4918 10.8171 15.5918 11.1421 15.7834L13.6338 17.2584C14.3588 17.6918 14.8171 17.6918 14.9921 17.5668C15.1671 17.4418 15.2921 17.0001 15.1088 16.1834L14.5171 13.6251C14.3588 12.9334 14.6171 12.0418 15.1171 11.5334L17.1838 9.46676C17.5921 9.05843 17.7754 8.65843 17.6921 8.38343C17.6004 8.10843 17.2171 7.88343 16.6504 7.79177L13.9921 7.3501C13.3504 7.24176 12.6504 6.7251 12.3588 6.14176L10.8921 3.20843C10.6254 2.6751 10.2921 2.35843 10.0004 2.35843C9.70876 2.35843 9.37542 2.6751 9.11709 3.20843L7.64209 6.14176C7.35042 6.7251 6.65042 7.24176 6.00876 7.3501L3.35876 7.79177C2.79209 7.88343 2.40876 8.10843 2.31709 8.38343C2.22542 8.65843 2.41709 9.06676 2.82542 9.46676L4.89209 11.5334C5.39209 12.0334 5.65042 12.9334 5.49209 13.6251L4.90042 16.1834C4.70876 17.0084 4.84209 17.4418 5.01709 17.5668C5.19209 17.6918 5.64209 17.6834 6.37542 17.2584L8.86709 15.7834C9.18376 15.5918 9.59209 15.4918 10.0004 15.4918Z"
                                                            fill="white" />
                                                    </svg>
                                                    <span>4.6/5</span>
                                                </span>
                                            </div>
                                            <div className="cu-facilities-card-body">
                                                <a href="#" className="cu-facilities-card-link">Ararat Convalescent Hospital</a>
                                                <p className="cu-facilities-card-location">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.0004 11.1917C10.3418 11.1917 10.6799 11.1244 10.9954 10.9938C11.3108 10.8631 11.5974 10.6716 11.8389 10.4302C12.0803 10.1887 12.2718 9.90212 12.4025 9.58668C12.5331 9.27123 12.6004 8.93314 12.6004 8.5917C12.6004 8.25026 12.5331 7.91217 12.4025 7.59672C12.2718 7.28128 12.0803 6.99465 11.8389 6.75322C11.5974 6.51179 11.3108 6.32027 10.9954 6.18961C10.6799 6.05895 10.3418 5.9917 10.0004 5.9917C9.31083 5.9917 8.64951 6.26563 8.16191 6.75322C7.67432 7.24082 7.40039 7.90214 7.40039 8.5917C7.40039 9.28126 7.67432 9.94258 8.16191 10.4302C8.64951 10.9178 9.31083 11.1917 10.0004 11.1917Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" />
                                                        <path
                                                            d="M3.01675 7.07496C4.65842 -0.141705 15.3501 -0.133372 16.9834 7.08329C17.9418 11.3166 15.3084 14.9 13.0001 17.1166C12.1935 17.8946 11.1165 18.3293 9.99592 18.3293C8.87529 18.3293 7.79835 17.8946 6.99175 17.1166C4.69175 14.9 2.05842 11.3083 3.01675 7.07496Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" />
                                                    </svg>
                                                    <span>Ararat Convalescent Hospital</span>
                                                </p>
                                            </div>
                                            <div className="cu-facilities-card-footer">
                                                <p className="cu-facilities-footer-detail">
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.09961 16.775V5.22498" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M1.09961 14.575H20.8996" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M20.9004 14.575V16.775" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path
                                                            d="M20.7623 14.575C20.8382 14.575 20.8998 14.5134 20.8998 14.4375V12.375C20.8998 9.94491 18.9299 7.97498 16.4998 7.97498H9.3498C9.04604 7.97498 8.7998 8.22121 8.7998 8.52498V14.4375C8.7998 14.5134 8.86138 14.575 8.9373 14.575"
                                                            stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                        <path
                                                            d="M4.06591 9.62501C4.6554 9.28467 5.38167 9.28467 5.97116 9.62501C6.56065 9.96535 6.92379 10.5943 6.92379 11.275C6.92379 11.9557 6.56065 12.5847 5.97116 12.925C5.38167 13.2653 4.6554 13.2653 4.06591 12.925C3.47642 12.5847 3.11328 11.9557 3.11328 11.275C3.11328 10.5943 3.47642 9.96535 4.06591 9.62501Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                    <span>23</span>
                                                </p>
                                                <p className="cu-facilities-footer-detail">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_526_1932)">
                                                            <path
                                                                d="M18.3337 9.99996C18.3337 14.6 14.6003 18.3333 10.0003 18.3333C5.40033 18.3333 1.66699 14.6 1.66699 9.99996C1.66699 5.39996 5.40033 1.66663 10.0003 1.66663C14.6003 1.66663 18.3337 5.39996 18.3337 9.99996Z"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path
                                                                d="M13.0914 12.65L10.5081 11.1083C10.0581 10.8416 9.69141 10.2 9.69141 9.67497V6.2583"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_526_1932">
                                                                <rect width="20" height="20" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span>9 AM to 6 PM</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-6 col-lg-4 col-xl-3">
                                        <div className="cu-facilities-card">
                                            <div className="cu-facilities-card-img">
                                                <img src="assets/images/facilities4.png" alt="facilities Image" className="img-fluid" />
                                                <span className="cu-facilities-card-like">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12 21.652C11.69 21.652 11.39 21.612 11.14 21.522C7.32 20.212 1.25 15.562 1.25 8.69198C1.25 5.19198 4.08 2.35198 7.56 2.35198C9.25 2.35198 10.83 3.01198 12 4.19198C12.5808 3.60585 13.2726 3.14126 14.0349 2.82535C14.7972 2.50943 15.6148 2.34852 16.44 2.35198C19.92 2.35198 22.75 5.20198 22.75 8.69198C22.75 15.572 16.68 20.212 12.86 21.522C12.61 21.612 12.31 21.652 12 21.652ZM7.56 3.85198C4.91 3.85198 2.75 6.02198 2.75 8.69198C2.75 15.522 9.32 19.322 11.63 20.112C11.81 20.172 12.2 20.172 12.38 20.112C14.68 19.322 21.26 15.532 21.26 8.69198C21.26 6.02198 19.1 3.85198 16.45 3.85198C14.93 3.85198 13.52 4.56198 12.61 5.79198C12.33 6.17198 11.69 6.17198 11.41 5.79198C10.9656 5.18906 10.3855 4.69925 9.71668 4.36221C9.04781 4.02518 8.30898 3.85038 7.56 3.85198Z"
                                                            fill="white" />
                                                    </svg>
                                                </span>
                                                <span className="cu-facilities-card-ratting">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M14.7171 18.8918C14.2754 18.8918 13.7088 18.7501 13.0004 18.3334L10.5088 16.8584C10.2504 16.7084 9.75042 16.7084 9.50042 16.8584L7.00042 18.3334C5.52542 19.2084 4.65876 18.8584 4.26709 18.5751C3.88376 18.2918 3.28376 17.5668 3.67542 15.9001L4.26709 13.3418C4.33376 13.0751 4.20042 12.6168 4.00042 12.4168L1.93376 10.3501C0.900424 9.31676 0.983757 8.43343 1.12542 8.0001C1.26709 7.56677 1.71709 6.8001 3.15042 6.55843L5.80876 6.11676C6.05876 6.0751 6.41709 5.80843 6.52542 5.58343L8.00042 2.64176C8.66709 1.3001 9.54209 1.1001 10.0004 1.1001C10.4588 1.1001 11.3338 1.3001 12.0004 2.64176L13.4671 5.5751C13.5838 5.8001 13.9421 6.06676 14.1921 6.10843L16.8504 6.5501C18.2921 6.79176 18.7421 7.55843 18.8754 7.99177C19.0088 8.4251 19.0921 9.30843 18.0671 10.3418L16.0004 12.4168C15.8004 12.6168 15.6754 13.0668 15.7338 13.3418L16.3254 15.9001C16.7088 17.5668 16.1171 18.2918 15.7338 18.5751C15.5254 18.7251 15.1921 18.8918 14.7171 18.8918ZM10.0004 15.4918C10.4088 15.4918 10.8171 15.5918 11.1421 15.7834L13.6338 17.2584C14.3588 17.6918 14.8171 17.6918 14.9921 17.5668C15.1671 17.4418 15.2921 17.0001 15.1088 16.1834L14.5171 13.6251C14.3588 12.9334 14.6171 12.0418 15.1171 11.5334L17.1838 9.46676C17.5921 9.05843 17.7754 8.65843 17.6921 8.38343C17.6004 8.10843 17.2171 7.88343 16.6504 7.79177L13.9921 7.3501C13.3504 7.24176 12.6504 6.7251 12.3588 6.14176L10.8921 3.20843C10.6254 2.6751 10.2921 2.35843 10.0004 2.35843C9.70876 2.35843 9.37542 2.6751 9.11709 3.20843L7.64209 6.14176C7.35042 6.7251 6.65042 7.24176 6.00876 7.3501L3.35876 7.79177C2.79209 7.88343 2.40876 8.10843 2.31709 8.38343C2.22542 8.65843 2.41709 9.06676 2.82542 9.46676L4.89209 11.5334C5.39209 12.0334 5.65042 12.9334 5.49209 13.6251L4.90042 16.1834C4.70876 17.0084 4.84209 17.4418 5.01709 17.5668C5.19209 17.6918 5.64209 17.6834 6.37542 17.2584L8.86709 15.7834C9.18376 15.5918 9.59209 15.4918 10.0004 15.4918Z"
                                                            fill="white" />
                                                    </svg>
                                                    <span>4.6/5</span>
                                                </span>
                                            </div>
                                            <div className="cu-facilities-card-body">
                                                <a href="#" className="cu-facilities-card-link">Ararat Convalescent Hospital</a>
                                                <p className="cu-facilities-card-location">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.0004 11.1917C10.3418 11.1917 10.6799 11.1244 10.9954 10.9938C11.3108 10.8631 11.5974 10.6716 11.8389 10.4302C12.0803 10.1887 12.2718 9.90212 12.4025 9.58668C12.5331 9.27123 12.6004 8.93314 12.6004 8.5917C12.6004 8.25026 12.5331 7.91217 12.4025 7.59672C12.2718 7.28128 12.0803 6.99465 11.8389 6.75322C11.5974 6.51179 11.3108 6.32027 10.9954 6.18961C10.6799 6.05895 10.3418 5.9917 10.0004 5.9917C9.31083 5.9917 8.64951 6.26563 8.16191 6.75322C7.67432 7.24082 7.40039 7.90214 7.40039 8.5917C7.40039 9.28126 7.67432 9.94258 8.16191 10.4302C8.64951 10.9178 9.31083 11.1917 10.0004 11.1917Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" />
                                                        <path
                                                            d="M3.01675 7.07496C4.65842 -0.141705 15.3501 -0.133372 16.9834 7.08329C17.9418 11.3166 15.3084 14.9 13.0001 17.1166C12.1935 17.8946 11.1165 18.3293 9.99592 18.3293C8.87529 18.3293 7.79835 17.8946 6.99175 17.1166C4.69175 14.9 2.05842 11.3083 3.01675 7.07496Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" />
                                                    </svg>
                                                    <span>Ararat Convalescent Hospital</span>
                                                </p>
                                            </div>
                                            <div className="cu-facilities-card-footer">
                                                <p className="cu-facilities-footer-detail">
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.09961 16.775V5.22498" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M1.09961 14.575H20.8996" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M20.9004 14.575V16.775" stroke="#7D7D7D" stroke-width="1.5"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path
                                                            d="M20.7623 14.575C20.8382 14.575 20.8998 14.5134 20.8998 14.4375V12.375C20.8998 9.94491 18.9299 7.97498 16.4998 7.97498H9.3498C9.04604 7.97498 8.7998 8.22121 8.7998 8.52498V14.4375C8.7998 14.5134 8.86138 14.575 8.9373 14.575"
                                                            stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                        <path
                                                            d="M4.06591 9.62501C4.6554 9.28467 5.38167 9.28467 5.97116 9.62501C6.56065 9.96535 6.92379 10.5943 6.92379 11.275C6.92379 11.9557 6.56065 12.5847 5.97116 12.925C5.38167 13.2653 4.6554 13.2653 4.06591 12.925C3.47642 12.5847 3.11328 11.9557 3.11328 11.275C3.11328 10.5943 3.47642 9.96535 4.06591 9.62501Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                    <span>23</span>
                                                </p>
                                                <p className="cu-facilities-footer-detail">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_526_1932)">
                                                            <path
                                                                d="M18.3337 9.99996C18.3337 14.6 14.6003 18.3333 10.0003 18.3333C5.40033 18.3333 1.66699 14.6 1.66699 9.99996C1.66699 5.39996 5.40033 1.66663 10.0003 1.66663C14.6003 1.66663 18.3337 5.39996 18.3337 9.99996Z"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path
                                                                d="M13.0914 12.65L10.5081 11.1083C10.0581 10.8416 9.69141 10.2 9.69141 9.67497V6.2583"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_526_1932">
                                                                <rect width="20" height="20" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span>9 AM to 6 PM</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- Facilities Section End --> */}

                    {/* <!-- Areas Section Start --> */}
                    <section className="cu-areas-section">
                        <div className="container">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="cu-areas-content">
                                        <h2 className="cu-section-title text-start">Areas we connect to you</h2>
                                        <p className="cu-section-description">Listing all of California. Moving throughout the USA.</p>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="cu-areas-map" style={{ height: "500px" }} ref={mapRef}>
                                        {/* {console.log(mapRef)} */}

                                        <img src="assets/images/map-img.png" alt="map-img" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- Areas Section End --> */}

                    {/* <!-- How Works Section Start --> */}
                    <section className="cu-how-work-section" ref={howRef}>
                        <div className="container">
                            <h3 className="cu-section-subtitle">How it works</h3>
                            <h3 className="cu-section-title">We've made finding healthcare simple. See how ContigoU can help you discover the right medical recovery center step by step.</h3>
                            <div className="cu-how-work-content">
                                <div className="row">
                                    <div className="col col-12 col-md-6 col-lg-4">
                                        <div className="cu-how-work-card">
                                            <div className="cu-how-work-card-img">
                                                <img src="assets/images/how-work1.png" alt="How Work" className="img-fluid" />
                                            </div>
                                            <div className="cu-how-work-card-body">
                                                <div className="cu-how-work-step">
                                                    <p className="cu-how-work-step-number"><span>1</span></p>
                                                </div>
                                                <div className="cu-how-work-detail">
                                                    <h4 className="cu-how-work-card-title">Find your Center</h4>
                                                    <p className="cu-how-work-card-description">Find a Skilled Nursing Rehab easily. Look at pictures to find your place. Click on them to get info about services and locations.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-6 col-lg-4">
                                        <div className="cu-how-work-card">
                                            <div className="cu-how-work-card-img">
                                                <img src="assets/images/how-work2.png" alt="How Work" className="img-fluid" />
                                            </div>
                                            <div className="cu-how-work-card-body">
                                                <div className="cu-how-work-step">
                                                    <p className="cu-how-work-step-number"><span>2</span></p>
                                                </div>
                                                <div className="cu-how-work-detail">
                                                    <h4 className="cu-how-work-card-title">Compare</h4>
                                                    <p className="cu-how-work-card-description">Look at different centers next to each other to pick the one that's best for you.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-6 col-lg-4">
                                        <div className="cu-how-work-card">
                                            <div className="cu-how-work-card-img">
                                                <img src="assets/images/how-work3.png" alt="How Work" className="img-fluid" />
                                            </div>
                                            <div className="cu-how-work-card-body">
                                                <div className="cu-how-work-step">
                                                    <p className="cu-how-work-step-number"><span>3</span></p>
                                                </div>
                                                <div className="cu-how-work-detail">
                                                    <h4 className="cu-how-work-card-title">Choose the Best One</h4>
                                                    <p className="cu-how-work-card-description">Pick the best place for you and book it – it's super easy!!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- How Works Section End --> */}

                    {/* <!-- Our Services Section Start --> */}
                    <section className="cu-service-section">
                        <div className="container">
                            <div className="cu-service-heading">
                                <h3 className="cu-section-subtitle text-start">Our Services</h3>
                                <h2 className="cu-section-title text-start">ContigoU offers different ways to assist you on your road to recovery. We're here to make it easier for you.</h2>
                            </div>
                            <div className="cu-service-content">
                                <div className="row">
                                    <div className="col col-12 col-md-6 col-lg-4">
                                        <div className="cu-service-card">
                                            <div className="cu-service-card-icon">
                                                <svg width="65" height="65" viewBox="0 0 65 65" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M7.16016 53.9297C7.16016 54.154 7.34204 54.3359 7.56641 54.3359H37.695C42.8147 54.3359 47.782 52.5935 51.7798 49.3952L56.5948 45.5433C57.9087 44.4921 58.1218 42.5747 57.0706 41.2607C56.0194 39.9467 54.102 39.7337 52.788 40.7848L47.9731 44.6368C47.3203 45.1591 46.4941 45.3984 45.658 45.3984H28.2851C28.173 45.3984 28.082 45.3075 28.082 45.1954C28.082 45.0853 28.1698 44.9952 28.2799 44.9937C28.3538 44.9927 28.4278 44.9922 28.5018 44.9922H36.4102C38.0929 44.9922 39.457 43.6281 39.457 41.9453C39.457 40.2625 38.0929 38.8984 36.4102 38.8984H28.5018C24.7755 38.8984 21.1189 39.8218 17.8539 41.5711C16.7433 42.1661 15.5222 42.5547 14.2622 42.5547H7.56641C7.34204 42.5547 7.16016 42.7366 7.16016 42.9609V53.9297Z"
                                                        stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path
                                                        d="M33.9727 15.5476L35.2038 14.3028C36.3467 13.1473 37.8966 12.4981 39.5128 12.4981C41.129 12.4981 42.6789 13.1473 43.8217 14.3028C44.9646 15.4583 45.6065 17.0255 45.6065 18.6596C45.6065 20.2937 44.9646 21.8609 43.8217 23.0164L33.9727 32.9748M33.9727 15.5476L32.7415 14.3028C31.5988 13.1473 30.0488 12.4981 28.4326 12.4981C26.8165 12.4981 25.2665 13.1473 24.1237 14.3028C22.9809 15.4583 22.3389 17.0255 22.3389 18.6596C22.3389 20.2937 22.9809 21.8609 24.1237 23.0164L33.9727 32.9748"
                                                        stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            <div className="cu-service-card-body">
                                                <h3 className="cu-service-card-title">Skilled Nursing Rehabilitation Facilities</h3>
                                                <p className="cu-service-card-description">
                                                    When someone you care about is getting better from sickness, injury, stroke, or surgery, Skilled Nursing Rehab Facility can assist them in getting stronger and moving better. These centers aim to help people get back to their regular activities and go home. Contigou helps you find and choose from different available centers.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-6 col-lg-4">
                                        <div className="cu-service-card">
                                            <div className="cu-service-card-icon cu-assisted-icon">
                                                <svg width="55" height="48" viewBox="0 0 55 48" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M34.4323 25.1671H32.0573L29.5573 20.3921L24.8073 29.9421L20.1323 17.9421L15.3573 25.1671H5.80728L4.98228 24.3421C3.72323 23.088 2.72353 21.5981 2.04025 19.9576C1.35698 18.3172 1.00351 16.5582 1.00003 14.7811C0.992993 11.1921 2.41196 7.74734 4.94478 5.20458C7.4776 2.66181 10.9168 1.22935 14.5058 1.22232C18.0947 1.21529 21.5395 2.63426 24.0823 5.16708L27.2823 8.41708L30.4823 5.16708C31.7247 3.86854 33.2139 2.83115 34.8626 2.11574C36.5112 1.40033 38.2861 1.02129 40.0832 1.00087C41.8802 0.980449 43.6633 1.31905 45.3278 1.99681C46.9923 2.67457 48.5046 3.67784 49.7763 4.94781C51.0479 6.21777 52.0532 7.72886 52.7331 9.39245C53.413 11.056 53.754 12.8386 53.7359 14.6357C53.7178 16.4328 53.3411 18.2082 52.6279 19.8578C51.9146 21.5074 50.8792 22.9979 49.5823 24.2421L48.7573 25.0671L27.2823 46.5921L12.4573 31.7671"
                                                        stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                                                    <path d="M36.832 25.1171H41.607" stroke="white" stroke-width="1.5"
                                                        stroke-miterlimit="10" />
                                                </svg>
                                            </div>
                                            <div className="cu-service-card-body">
                                                <h3 className="cu-service-card-title">Assisted Living Facilities</h3>
                                                <p className="cu-service-card-description">When your loved ones require support and a more comfortable living environment due to age or health, Assisted Living Facilities offer a helping hand. These facilities provide assistance with daily tasks, ensuring that residents enjoy a higher quality of life. ContigoU is your guide to finding the right Assisted Living Facility that suits your loved one's needs.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col col-12 col-md-6 col-lg-4">
                                        <div className="cu-service-card">
                                            <div className="cu-service-card-icon cu-board-icon">
                                                <svg width="65" height="65" viewBox="0 0 65 65" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M31.1502 20.1808C32.8181 19.6249 34.6212 19.6249 36.289 20.1808L37.0837 20.4457C39.7189 21.3241 41.7228 23.488 42.3966 26.1829L43.0893 28.9532C43.1329 29.1277 43.2897 29.2502 43.4696 29.2502H45.9071C47.702 29.2502 49.1571 30.7052 49.1571 32.5002C49.1571 34.2951 47.702 35.7502 45.9071 35.7502H43.4696C40.8846 35.7502 38.5709 34.3122 37.3931 32.1115L36.2819 34.7911L37.5089 35.2336C41.4841 36.6671 43.6967 40.9086 42.598 44.9892L40.9203 51.2201C40.4537 52.9533 38.6703 53.9801 36.9371 53.5134C35.204 53.0467 34.1772 51.2633 34.6439 49.5302L36.3216 43.2992C36.5413 42.4831 36.0988 41.6348 35.3037 41.3481L29.6851 39.3218C26.8368 38.2946 25.4748 35.0524 26.735 32.2991L28.785 27.8208L24.9973 29.0834C23.2945 29.651 21.454 28.7306 20.8864 27.0279C20.3188 25.3251 21.239 23.4845 22.9419 22.9169L31.1502 20.1808Z"
                                                        stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path
                                                        d="M26.3899 33.3188L23.6216 39.511C23.5396 39.6945 23.3573 39.8127 23.1562 39.8127H18.2812C16.4863 39.8127 15.0312 41.2678 15.0312 43.0627C15.0312 44.8576 16.4863 46.3127 18.2812 46.3127H23.1562C25.9209 46.3127 28.4272 44.6877 29.5556 42.1639L30.6676 39.6763L29.6851 39.322C27.1826 38.4195 25.8273 35.8071 26.3899 33.3188Z"
                                                        stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path
                                                        d="M37.5012 20.2073C38.7055 20.7872 40.1084 20.7872 41.3127 20.2073C42.5169 19.6273 43.3916 18.5305 43.6891 17.2274C43.9864 15.9243 43.6743 14.5566 42.8409 13.5116C42.0075 12.4665 40.7436 11.8578 39.4069 11.8578C38.0703 11.8578 36.8064 12.4665 35.973 13.5116C35.1395 14.5566 34.8274 15.9243 35.1248 17.2274C35.4223 18.5305 36.2969 19.6273 37.5012 20.2073Z"
                                                        stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            <div className="cu-service-card-body">
                                                <h3 className="cu-service-card-title">Board and Care Facilities</h3>
                                                <p className="cu-service-card-description">Board and Care Facilities offer a cozy and home-like atmosphere for individuals who need specialized care and attention. These centers provide a more personalized approach, ensuring residents receive the care they require in a comfortable setting. ContigoU is here to assist you in locating the ideal Board and Care Facility for your loved one's unique needs.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- Our Services Section End --> */}

                    {/* <!-- About US Section Start --> */}
                    <section className="cu-about-section" ref={aboutRef}>
                        <div className="container">
                            <div className="row">
                                <div className="col col-12 col-md-12 col-lg-6">
                                    <div className="cu-about-img">
                                        <div className="row">
                                            <div className="col col-12 col-md-6 col-lg-6">
                                                <div className="cu-about-img__inner">
                                                    <img src="assets/images/about1.png" alt="Aboput Image" />
                                                </div>
                                                <div className="cu-about-img__inner">
                                                    <img src="assets/images/about2.png" alt="Aboput Image" className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col col-12 col-md-6 col-lg-6">
                                                <div className="cu-about-img__inner">
                                                    <img src="assets/images/about3.png" alt="Aboput Image" className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-12 col-lg-6">
                                    <div className="cu-about-details">
                                        <h3 className="cu-section-subtitle text-start">About US</h3>
                                        <h2 className="cu-section-title text-start">ContigoU (WithU)</h2>
                                        <p className="cu-section-description">Learn more about ContigoU. We're here to help you find the care you need, and we're pretty good at it.</p>
                                        {/* <p className="cu-section-description">We care about you and our communities and help by connecting people to healthcare and resources. </p> */}
                                        {/* <p className="cu-section-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim
                                            nostrud Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua Ut enim ad minim nostrud</p>
                                        <p className="cu-section-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim
                                            nostrud Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua Ut enim ad minim nostrud</p> */}
                                        {/* <a href="#" className="btn btn-outline-info">Read More</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- About US Section End --> */}

                    {/* <!-- Hear Section Start --> */}
                    <section className="cu-hear-section" ref={contactRef}>
                        <div className="cu-hear-section__inner">
                            <div className="container">
                                <h2 className="cu-section-title">We would love to hear from you!</h2>
                                <p className="cu-section-description">Please fill in the details below to send feedback, comments or
                                    questions. We will get back to you quickly.</p>
                            </div>
                        </div>
                        <div className="container">
                            <div className="cu-hear-content">
                                <div className="cu-hear-img">
                                    <img src="assets/images/contact-img.png" alt="contact-img" className="img-fluid" />
                                </div>
                                <div className="cu-hear-contact">
                                    <div className="cu-hear-contact__inner">
                                        <form className="row">
                                            <div className="col col-12 col-md-12 mb-4">
                                                <label htmlFor="inputName" className="form-label">Name</label>
                                                <input type="text" className="form-control" id="inputName" value={contact.name} name='name' onChange={handleChangeWithoutNumber} />
                                                <span className='error'>{error.name_err}</span>
                                            </div>
                                            <div className="col col-12 col-md-12 mb-4">
                                                <label htmlFor="inputEmail" className="form-label">Email</label>
                                                <input type="email" className="form-control" id="inputEmail" onChange={handleChange} name='email' value={contact.email} />
                                                <span className='error'>{error.email_err}</span>
                                            </div>
                                            <div className="col col-12 col-md-12 mb-4">
                                                <label htmlFor="inputMesage" className="form-label">Message</label>
                                                <textarea id="inputMesage" className="form-control" rows="5" value={contact.message} name='message' onChange={handleChange}></textarea>
                                                <span className='error'>{error.message_err}</span>
                                            </div>
                                            <div className="col col-12 col-md-12">
                                                <button type="button" onClick={handleContact} className="btn btn-info"
                                                    style={(Object.values(contact).every(val => !val)) ? { pointerEvents: "none" } : { cursor: 'pointer' }}
                                                >Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- Hear Section End --> */}

                    {/* <!-- Testimonials Section Start --> */}
                    {/* <section className="cu-testimonials-section">
                        <div className="container">
                            <h3 className="cu-section-subtitle">Testimonials</h3>
                            <h3 className="cu-section-title"> Read stories from people who found the help they needed with ContigoU. Real people, real stories.</h3>

                            <div className="cu-testimonials-slider">
                                <div className="cu-testimonials-slide">
                                    <div className="cu-testimonials-content">
                                        <div className="cu-testimonials-img">
                                            <img src="assets/images/testimonial-img.png" alt="Testiimonial" className="img-fluid" />
                                        </div>
                                        <div className="cu-testimonials-details">
                                            <p className="cu-testimonials-date">18 April 2021</p>
                                            <h3 className="cu-testimonials-title">Great Services</h3>
                                            <p className="cu-testimonials-description">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                                                Ut enim ad minim nostrud Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim
                                                nostrud</p>
                                            <label className="cu-testimonials-name">Monalisa,21</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="cu-testimonials-slide">
                                    <div className="cu-testimonials-content">
                                        <div className="cu-testimonials-img">
                                            <img src="assets/images/testimonial-img.png" alt="Testiimonial" className="img-fluid" />
                                        </div>
                                        <div className="cu-testimonials-details">
                                            <p className="cu-testimonials-date">18 April 2021</p>
                                            <h3 className="cu-testimonials-title">Great Services</h3>
                                            <p className="cu-testimonials-description">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                                                Ut enim ad minim nostrud Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim
                                                nostrud</p>
                                            <label className="cu-testimonials-name">Monalisa,21</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="cu-testimonials-slide">
                                    <div className="cu-testimonials-content">
                                        <div className="cu-testimonials-img">
                                            <img src="assets/images/testimonial-img.png" alt="Testiimonial" className="img-fluid" />
                                        </div>
                                        <div className="cu-testimonials-details">
                                            <p className="cu-testimonials-date">18 April 2021</p>
                                            <h3 className="cu-testimonials-title">Great Services</h3>
                                            <p className="cu-testimonials-description">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                                                Ut enim ad minim nostrud Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim
                                                nostrud</p>
                                            <label className="cu-testimonials-name">Monalisa,21</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    {/* <!-- Testimonials Section End --> */}
                </main>
                <div style={{ marginBottom: "40px" }}></div>
                {/* <!-- Site Content End --> */}

                {/* <!-- Site Content Statrt --> */}
                <Footer
                    smoothScrollToRef={smoothScrollToRef}
                    scrollToTop={scrollToTop}
                    homeRef={homeRef}
                    aboutRef={aboutRef}
                    howRef={howRef}
                    contactRef={contactRef}
                    value={"Home"}
                />
                {/* <!-- Site Content End --> */}
                <Modal
                    backdrop="static"
                    className="cu-delete-modal"
                    show={show}
                    onHide={handleClose}
                    centered
                >
                    <Modal.Body>
                        <div className="cu-thank-you-content">
                            <div className="cu-delete-icon">
                                <img src="/assets/images/logo.png" />
                            </div>
                            <h3 className="cu-thank-you-title" style={{ fontSize: "21px" }}>
                                You need to sign up or log in. Once you sign up or log in, you can
                                view all details.
                            </h3>
                            <div className="cu-modal-btn">
                                <Button variant="primary mx-3" onClick={() => nav("/login")}>
                                    Login
                                </Button>
                                <Button
                                    variant="info"
                                    onClick={handleClose}
                                    style={{ backgroundColor: "#78797A", borderColor: "#78797A" }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </body>


        </>


    )
}

export default Index