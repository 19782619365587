import React, { useEffect, useState } from 'react'
import { CONTIGO_API_URL } from '../BaseURL';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import OTPInput from 'react-otp-input';
import axios from 'axios';

const Otp = () => {

    const [otp, setOtp] = useState("");
    const [minutes, setMinutes] = useState(10);
    const [seconds, setSeconds] = useState(0);
    const location = useLocation();
    const forgetEmail = location.state.email
    const user = location.state
    const [err, setErr] = useState('');
    const nav = useNavigate();
    const [disable, setDisable] = useState(false);


    console.log(user, "forget rmail");

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds, minutes]);


    const resendOTP = () => {
        if (user.type === 'forgot') {
            setDisable(true)
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('email', forgetEmail);
            axios({
                method: 'post',
                url: `${CONTIGO_API_URL}api/patient-user/forgot-password`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded ' }
            }).then((response) => {
                console.log("@@", response.data);
                if (response.data.success === true) {
                    // window.location.reload()
                    setMinutes(10);
                    setSeconds(0);
                    setErr('');
                    toast.success(response.data.message);
                    setDisable(false)
                }
            }).catch((err) => {
                console.log(err, "error")
                setErr(err.response.data.message);
                toast.error(err.response.data.message);
                setDisable(false)
                if (err.response.success === 401) {
                    localStorage.removeItem('CONTIGO_TOKEN_PATIENT')
                    nav('/login')
                }
            })

        } else if (user.type === 'signup') {
            setDisable(true);
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('email', user.register.email);
            bodyFormData.append('country_code', parseInt(location.state.register.number.split('-')[0]));
            bodyFormData.append('phone_number', parseInt(location.state.register.number.split('-')[1]));
            axios({
                method: 'post',
                url: `${CONTIGO_API_URL}api/patient-user/send-otp`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded ' }
            }).then((response) => {
                if (response.data.success === true) {
                    setMinutes(1);
                    setSeconds(0);
                    setErr('');
                    toast.success(response.data.message);
                    setDisable(false);
                }
            }).catch((err) => {
                setErr(err.response.data.message)
                console.log(err);
                setDisable(disable);
            })
        } else {
            setErr("please enter otp")
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (otp) {

            if (location.state.type !== 'forgot') {
                setDisable(true);
                const bodyFormData = new URLSearchParams();
                bodyFormData.append('first_name', user.register.fname);
                bodyFormData.append('last_name', user.register.lname);
                bodyFormData.append('email', user.register.email);
                bodyFormData.append('phone_number', parseInt(user.register.number.split('-')[1]));
                bodyFormData.append('country_code', parseInt(user.register.number.split('-')[0]));
                bodyFormData.append('about', user.register.about);
                bodyFormData.append('password', user.register.password);
                bodyFormData.append('otp', otp);
                axios({
                    method: 'post',
                    url: `${CONTIGO_API_URL}api/patient-user/signup`,
                    data: bodyFormData,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded ' }
                }).then((response) => {
                    console.log(response.data, "@@");
                    if (response.data.success === true) {
                        setMinutes(1);
                        setSeconds(0);
                        setTimeout(() => {
                            nav('/search-result');
                        }, 1500)
                        // console.log(response.data.data.token, "token")
                        toast.success("Patient Registrated Successfully...")
                        localStorage.setItem('CONTIGO_TOKEN_PATIENT', response.data.data.token);
                        setDisable(false);
                    }
                }).catch((err) => {
                    setErr(err.response.data.message)
                    toast.error(err.response.data.message)
                    console.log(err, "error");
                    setDisable(false);
                })
            } else {
                setDisable(true);
                const bodyFormData = new URLSearchParams();
                bodyFormData.append('email', forgetEmail);
                bodyFormData.append('otp', otp);
                axios({
                    method: 'post',
                    url: `${CONTIGO_API_URL}api/patient-user/verify-otp`,
                    data: bodyFormData,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded ' }
                }).then((response) => {
                    console.log(response.data);
                    if (response.data.success === true) {
                        toast.success(response.data.message);
                        setTimeout(() => {
                            nav('/reset-password', { state: forgetEmail });
                        }, 2000)
                        setDisable(false);
                    }
                }).catch((err) => {
                    setErr(err.response.data.message)
                    setDisable(false)
                })
            }
        } else {
            setErr('Please enter otp')
        }
    }


    return (
        <div className='cu-site'>
            <section className="cu-login-section">
                <div className="cu-login-section-content">
                    <div className="row g-0">
                        <div className="col col-12 col-md-12 col-lg-12 col-xl-6">
                            <div className="cu-login-content">
                                <Link className="cu-login-top-logo" to="/">
                                    <img src="assets/images/white-logo.png" alt="Logo" className="img-fluid" />
                                </Link>
                                <div className="cu-login-content-area">
                                    <h2 className="cu-login-content-title">Patients are Waiting for you
                                        Register Now</h2>
                                    <div className="cu-login-logo">
                                        <Link to='/'>
                                            <img src="assets/images/login-logo.png" alt="login-logo" className="img-fluid" />
                                        </Link>
                                    </div>
                                    <div className="cu-login-left-img">
                                        <img src="assets/images/login-old-img.png" alt="login-old-img" className="img-fluid" />
                                    </div>
                                    <div className="cu-login-right-img">
                                        <img src="assets/images/login-boy-img.png" alt="login-boy-img" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-12 col-lg-12 col-xl-6">
                            <div className="cu-login-form-area">
                                <div className="cu-login-form-area__inner">
                                    <div className="cu-login-form-heading">
                                        <h1 className="cu-login-title">Validate One Time Passcode</h1>
                                        <p className="cu-login-description">Enter 4 digit secure code, received by register email</p>
                                    </div>
                                    <div className="cu-login-form cu-otp-form">
                                        <form className="row" action="">
                                            <div className="col-12">
                                                {/* <label for="inputOTP" className="form-label">OTP</label> */}

                                                <div className='otp-input-area'>

                                                    <OTPInput
                                                        value={otp}
                                                        onChange={(val) => setOtp(val)}
                                                        numInputs={4}
                                                        className='form-control'
                                                        // inputStyle={{ width: '4rem', height: '4rem', marginRight: '15px' }}
                                                        renderInput={(props) => <input {...props} />}
                                                    // separator={<span>       </span>}
                                                    />
                                                </div>
                                                <span className='error'>{err}</span>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                                    {seconds > 0 || minutes > 0 ? (
                                                        <p style={{ paddingTop: '10px' }}>
                                                            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                                            {seconds < 10 ? `0${seconds}` : seconds}
                                                        </p>
                                                    ) : (
                                                        <p style={{ paddingTop: '10px' }}>Didn't recieve code?</p>
                                                    )}
                                                    <button className="otp-button"
                                                        type='button'
                                                        style={{ border: 'none', background: 'none', display: 'flex', alignItems: "center", justifyContent: 'flex-end', flex: 1, cursor: seconds > 0 || minutes > 0 ? 'no-drop' : 'pointer', color: seconds > 0 || minutes > 0 ? "grey" : "#00657B" }}
                                                        onClick={resendOTP} disabled={seconds > 0 || minutes > 0}>Resend One Time Passcode</button>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <button type="button" onClick={handleSubmit} className="btn btn-info w-100" disabled={disable}>{disable ? "Processing..." : "Submit"}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Otp