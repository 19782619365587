import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import axios from 'axios';
import { CONTIGO_API_URL } from '../BaseURL'
import { toast, Toaster } from 'react-hot-toast';

const Register = () => {

    const [register, setRegister] = useState({
        fname: "", lname: "", email: "", number: "", password: "", cpassword: "", about: "",
        pwdShow: true, cpwdShow: true
    })
    const [error, setError] = useState({})
    const nav = useNavigate();
    const [disable, setDisable] = useState(false);


    const handleRegister = () => {
        if (validation()) {
            setDisable(true);
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('email', register.email);
            bodyFormData.append('business_email', register.businessEmail);
            bodyFormData.append('phone_number', parseInt(register.number.split('-')[1]));
            bodyFormData.append('country_code', parseInt(register.number.split('-')[0]));


            axios({
                method: 'post',
                url: `${CONTIGO_API_URL}api/patient-user/send-otp`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then((response) => {
                console.log("@@", response?.data)
                if (response?.data?.success === true) {
                    setTimeout(() => {
                        nav('/otp-verification', { state: { register: { ...register }, type: 'signup' } })
                    }, 1500)
                    toast.success(response?.data?.message);
                    setDisable(false)
                }
            }).catch((err) => {
                console.log(err);
                toast.error(err?.response?.data?.message);
                if (err.response.data.type === 1) {
                    setError({ email_err: err.response.data.message })
                    setDisable(false);
                } else if (err.response.data.type === 2) {
                    setError({ number_err: err?.response?.data?.message })
                    setDisable(false);
                } else {
                    toast.error(err?.response?.data?.message);
                    setDisable(false);
                }
                setDisable(false);
            })

        }
    }



    const handleChange = (e) => {
        setRegister({ ...register, [e.target.name]: e.target.value.trimStart() })
    }

    const handleChangeWithoutNumber = (e) => {
        const sanitizedValue = e.target.value.replace(/[^a-zA-Z\s]/g, '');
        setRegister({ ...register, [e.target.name]: sanitizedValue.trimStart() })
    }


    const validation = () => {
        var isValid = true;
        let err = {};

        if (!register.fname) {
            isValid = false;
            err['fname_err'] = "Please enter first name"
        }


        if (!register.lname) {
            isValid = false;
            err['lname_err'] = "Please enter last name"
        }

        if (!register.email) {
            isValid = false;
            err['email_err'] = "Please enter email"
        } else if (typeof register.email !== "undefined") {
            let lastAtPos = register.email.lastIndexOf('@');
            let lastDotPos = register.email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && register.email.indexOf('@@') === -1 && lastDotPos > 2 && (register.email.length - lastDotPos) > 2)) {
                isValid = false;
                err["email_err"] = "Email is not valid";
            }
        }

        if (!register.number) {
            isValid = false;
            err['number_err'] = "Please enter mobile number"
        } else {
            const phoneRegex = /^\d{1,2}-\d{10}$/; // Updated regular expression for phone number validation
            if (!phoneRegex.test(register.number)) {
                isValid = false;
                err['number_err'] = "Please enter a valid mobile number";
                console.log("number")
            }
        }

        if (!register.password) {
            isValid = false;
            err['password_err'] = "Please enter password"
        } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}/.test(register.password)) {
            isValid = false;
            err['password_err'] = "Password must be at least 8 characters long, contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character";
        }

        if (!register.cpassword) {
            isValid = false;
            err['cpassword_err'] = "Please enter confirm password"
        }
        if (register.password && register.cpassword) {
            if (register.password !== register.cpassword) {
                isValid = false;
                err['cpassword_err'] = "Password doesn't match";
            }
        }

        if (!register.about) {
            isValid = false;
            err['about_err'] = "Please enter the about us field"
        }
        setError(err);
        return isValid;
    }


    return (
        <div className='cu-site'>
            {/* <!-- Log In Section Start --> */}
            <section className="cu-login-section">
                <div className="cu-login-section-content">
                    <div className="row g-0">
                        <div className="col col-12 col-md-12 col-lg-12 col-xl-6">
                            <div className="cu-login-content">
                                <a className="cu-login-top-logo" href="/">
                                    <img src="assets/images/white-logo.png" alt="Logo" className="img-fluid" />
                                </a>
                                <div className="cu-login-content-area">
                                    <h2 className="cu-login-content-title">Patients are Waiting for you Register Now</h2>
                                    <div className="cu-login-logo">
                                        <Link to='/'>
                                            <img src="assets/images/login-logo.png" alt="login-logo" className="img-fluid" />
                                        </Link>
                                    </div>
                                    <div className="cu-login-left-img">
                                        <img src="assets/images/login-old-img.png" alt="login-old-img" className="img-fluid" />
                                    </div>
                                    <div className="cu-login-right-img">
                                        <img src="assets/images/login-boy-img.png" alt="login-boy-img" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-12 col-lg-12 col-xl-6">
                            <div className="cu-login-form-area">
                                <div className="cu-login-form-area__inner">
                                    <div className="cu-login-form-heading">
                                        <h1 className="cu-login-title">Sign Up</h1>
                                        <p className="cu-login-description">Register Your Nursing home with us</p>
                                    </div>
                                    <div className="cu-login-form">
                                        <form className="row">
                                            <div className="col-12 mb-4">
                                                <label htmlFor="inputFirstName" className="form-label">First Name</label>
                                                <input type="text" className="form-control" id="inputFirstName" value={register.fname} onChange={handleChangeWithoutNumber} name='fname' />
                                                <div className='error'>{error.fname_err}</div>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <label htmlFor="inputLastName" className="form-label">Last Name</label>
                                                <input type="text" className="form-control" id="inputLastName" name='lname' onChange={handleChangeWithoutNumber} value={register.lname} />
                                                <div className='error'>{error.lname_err}</div>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <label htmlFor="inputEmailAddress" className="form-label">Email Address</label>
                                                <input type="text" className="form-control" id="inputEmailAddress" name='email' value={register.email} onChange={handleChange} />
                                                <div className='error'>{error.email_err}</div>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <label htmlFor="inputEmailAddress" className="form-label">Phone Number</label>
                                                <div className="select-code-group">
                                                    <PhoneInput
                                                        country='us'
                                                        inputClass='form-control'
                                                        inputExtraProps={{
                                                            name: "number",
                                                            required: true,
                                                            autoFocus: true
                                                        }}
                                                        placeholder=""
                                                        name="number"
                                                        defaultCountry={"us"}
                                                        value={register.number}
                                                        onChange={(val, countryData) => {
                                                            const numericValue = val.replace(/[^0-9]/g, ""); // Extract numeric value only
                                                            const countryCodePrefix = "+" + countryData.dialCode; // Get country code prefix from countryData

                                                            let formattedValue = numericValue;
                                                            if (numericValue.startsWith(countryData.dialCode)) {
                                                                formattedValue = `${countryData.dialCode}-${numericValue.substring(countryData.dialCode.length)}`;
                                                            }
                                                            console.log(countryCodePrefix)
                                                            setRegister({ ...register, number: formattedValue });
                                                        }}
                                                    />
                                                    {/* <input type="text" className="form-control" id="inputEmailAddress" name='number' value={register.number} onChange={handleChange} /> */}
                                                    {/* <select className="form-control" id="countries">
                                                        <option value="GB" data-capital="London">+1</option>
                                                        <option value="FR" data-capital="Paris">+2</option>
                                                        <option value="IT" data-capital="Rome">+03</option>
                                                        <option value="CN" data-capital="Beijing">+98</option>
                                                        <option value="CN" data-capital="Beijing">+99</option>
                                                        <option value="DE" data-capital="Berlin">+45</option>
                                                        <option value="PL" data-capital="Warsaw">+88</option>
                                                    </select> */}
                                                    <div className='error'>{error.number_err}</div>
                                                </div>
                                            </div>
                                            <div className="col col-12 col-md-6 mb-4">
                                                <label htmlFor="inputPassword" className="form-label">Password</label>
                                                <div className="cu-input-icon-group">
                                                    <input type={register.pwdShow ? "password" : "text"} className="form-control" id="inputPassword" name='password' value={register.password} onChange={handleChange} />
                                                    {register.pwdShow ? <i className='fa fa-eye cu-input-icon' onClick={() => setRegister({ ...register, pwdShow: false })} /> : <i className='fa fa-eye-slash cu-input-icon' onClick={() => setRegister({ ...register, pwdShow: true })} />}

                                                    {/* <span className="cu-input-icon">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path
                                                                d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </svg>
                                                    </span> */}
                                                    <div className='error'>{error.password_err}</div>
                                                </div>
                                            </div>
                                            <div className="col col-12 col-md-6 mb-4">
                                                <label htmlFor="inputConfirmPassword" className="form-label">Confirm Password</label>
                                                <div className="cu-input-icon-group">
                                                    <input type={register.cpwdShow ? "password" : "text"} className="form-control" id="inputConfirmPassword" onChange={handleChange} name='cpassword' value={register.cpassword} />
                                                    {register.cpwdShow ? <i className='fa fa-eye cu-input-icon' onClick={() => setRegister({ ...register, cpwdShow: false })} /> : <i className='fa fa-eye-slash cu-input-icon' onClick={() => setRegister({ ...register, cpwdShow: true })} />}

                                                    {/* <span className="cu-input-icon">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path
                                                                d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </svg>
                                                    </span> */}
                                                    <div className='error'>{error.cpassword_err}</div>

                                                </div>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <label htmlFor="inputAbout" className="form-label">How did you hear about us</label>
                                                <input type="text" className="form-control" id="inputAbout" name='about' value={register.about} onChange={handleChange} />
                                                <div className='error'>{error.about_err}</div>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <button type="button" onClick={handleRegister} className="btn btn-info w-100" disabled={disable}> {disable ? "Processing..." : "Register"} </button>
                                                {/* <!-- <p className="cu-form-notes">Don’t Have an Account ? <a href="#">Create One</a></p> --> */}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Log In Section End --> */}
        </div>
    )
}

export default Register